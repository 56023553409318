function Blogs() {
    return (
        <section className="bg-secondary">
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div className="flex flex-col items-center">
                        <h2 className="text-center text-3xl font-bold md:text-5xl">The latest and greatest news</h2>
                        <p className="mb-8 mt-4 text-center text-sm text-secondary-text sm:text-base md:mb-12 lg:mb-16">News around the industry</p>
                        <div className="mb-6 grid gap-4 sm:grid-cols-2 sm:justify-items-stretch md:mb-10 md:grid-cols-3 lg:mb-12 lg:gap-6">
                            <a href="#" className="flex flex-col gap-4 rounded-md border border-solid border-[#cdcdcd] px-4 py-8 md:p-0">
                                <img src="https://images.moneycontrol.com/static-mcnews/2023/03/Recruitment-of-PSUs-candidate.jpg?impolicy=website&width=320&height=180" alt="" className="h-60 object-cover" />
                                <div className="px-6 py-4">
                                    <p className="mb-4 text-sm font-semibold uppercase text-secondary-text">lifestyle</p>
                                    <p className="mb-4 text-xl font-semibold">The latest news with Flowspark</p>
                                    <p className="mb-6 text-sm text-secondary-text sm:text-base lg:mb-8">Lorem ipsum dolor sit amet, &nbsp;elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet luctus venenatis</p>
                                    <div className="flex">
                                        <img src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a945e53e6cf8f_Ellipse%2011%20(1).svg" alt="" className="mr-4 h-16 w-16 rounded-full" />
                                        <div className="flex flex-col">
                                            <h6 className="text-base font-bold">Laila Bahar</h6>
                                            <div className="flex flex-col lg:flex-row">
                                                <p className="text-sm text-secondary-text">Sept 28, 2022</p>
                                                <p className="mx-2 hidden text-sm text-secondary-text lg:flex">-</p>
                                                <p className="text-sm text-secondary-text">6 mins read</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="flex flex-col gap-4 rounded-md border border-solid border-[#cdcdcd] px-4 py-8 md:p-0">
                                <img src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a94016de6cf90_Rectangle%2035.svg" alt="" className="h-60 object-cover" />
                                <div className="px-6 py-4">
                                    <p className="mb-4 text-sm font-semibold uppercase text-secondary-text">lifestyle</p>
                                    <p className="mb-4 text-xl font-semibold">The latest news with Flowspark</p>
                                    <p className="mb-6 text-sm text-secondary-text sm:text-base lg:mb-8">Lorem ipsum dolor sit amet, &nbsp;elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet luctus venenatis</p>
                                    <div className="flex">
                                        <img src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a945e53e6cf8f_Ellipse%2011%20(1).svg" alt="" className="mr-4 h-16 w-16 rounded-full" />
                                        <div className="flex flex-col">
                                            <h6 className="text-base font-bold">Laila Bahar</h6>
                                            <div className="flex flex-col lg:flex-row">
                                                <p className="text-sm text-secondary-text">Sept 28, 2022</p>
                                                <p className="mx-2 hidden text-sm text-secondary-text lg:flex">-</p>
                                                <p className="text-sm text-secondary-text">6 mins read</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href="#" className="flex flex-col gap-4 rounded-md border border-solid border-[#cdcdcd] px-4 py-8 md:p-0">
                                <img src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a94016de6cf90_Rectangle%2035.svg" alt="" className="h-60 object-cover" />
                                <div className="px-6 py-4">
                                    <p className="mb-4 text-sm font-semibold uppercase text-secondary-text">lifestyle</p>
                                    <p className="mb-4 text-xl font-semibold">The latest news with Flowspark</p>
                                    <p className="mb-6 text-sm text-secondary-text sm:text-base lg:mb-8">Lorem ipsum dolor sit amet, &nbsp;elit ut aliquam, purus sit amet luctus venenatis elit ut aliquam, purus sit amet luctus venenatis</p>
                                    <div className="flex">
                                        <img src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a945e53e6cf8f_Ellipse%2011%20(1).svg" alt="" className="mr-4 h-16 w-16 rounded-full" />
                                        <div className="flex flex-col">
                                            <h6 className="text-base font-bold">Laila Bahar</h6>
                                            <div className="flex flex-col lg:flex-row">
                                                <p className="text-sm text-secondary-text">Sept 28, 2022</p>
                                                <p className="mx-2 hidden text-sm text-secondary-text lg:flex">-</p>
                                                <p className="text-sm text-secondary-text">6 mins read</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        {/* <a href="#" className="rounded-md bg-black px-6 py-3 text-center font-semibold text-white">View More</a> */}
                    </div>
                </div>
            </section>
    );
}

export default Blogs;
