import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HomeHeader from "../components/HomeHeader";
import Solution5PageImage from "../components/assets/Solution5PageImage";
import EasySteps from "../components/EasySteps";

function SolutionPage5() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader title="Zero To Peak" description="Start with nothing, scale to financial peaks effortlessly.">
                <Solution5PageImage />
            </HomeHeader>
            <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Zero To Peak</h2>
            <p class="text-base sm:text-lg text-primary-text">Are you looking for an investment opportunity but don't have the money to get started? We have just the solution for you our super plan!<br /><br />
 
 With our super plan, you can enter with zero investment. That's right, even if you don't have the money to invest, we'll introduce you to a chit fund that we're partnered with. The amount you would have invested will be distributed to us, and you'll only need to pay the initial two payments to the chit fund.<br /><br />
  
 Once you've made those initial payments, you'll start receiving a return every quarter for the next four years. It's a simple and hassle-free way to get started in the world of investing, without having to worry about upfront costs or complicated financial jargon.<br /><br />
  
 So why wait? Take advantage of our super plan today and start building your financial future. With our help, you'll be on your way to achieving your financial goals in no time!</p>

            </div>
            </section>
            <EasySteps />
            <Footer />
        </div>
    );
}
export default SolutionPage5;
