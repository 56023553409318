import Logo from '../assets/image/logo.png'
import React from "react";
import ContactUs from "../components/ContactUs";

function Footer() {
    const [showModel, setShowmodal] = React.useState(false)
    const [openCount, setOpenCount] = React.useState(0)
    function toggleModel(){
        if(openCount !== 1){
            setShowmodal(true)
            setOpenCount(1)
        }
    }
    return (
        <>
        <footer className="block font-jakarta">
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                <div className="grid grid-cols-[auto] justify-between gap-6 max-[991px]:grid-flow-col max-[991px]:[grid-template:'.'_auto_'.'_auto_/_0.75fr_0.75fr_0.75fr] max-[767px]:gap-y-8 max-[479px]:auto-cols-auto max-[479px]:grid-flow-dense sm:grid-cols-2 sm:gap-4 md:grid-cols-[max-content_auto_auto_auto_auto] lg:gap-10">
                    <div className="flex max-w-[360px] grid-cols-1 flex-col items-center lg:items-start justify-start gap-8 max-[991px]:[grid-area:span_1/span_4/span_1/span_4] max-[767px]:flex-col max-[767px]:[grid-area:span_1/span_2/span_1/span_2] lg:mr-10">
                        <div className="flex flex-col items-start gap-6">
                            <a href="#" className="inline-block max-w-full text-black">
                                <img src={Logo} alt="" className="inline-block max-h-10" />
                            </a>
                            <div className="text-secondary-text">7/117, Therku Thottam keeranatham
                                <p>Coimbatore - 641035</p>
                                <p><a href='#steponesecurities.com'>steponesecurities.com</a></p>
                            </div>
                        </div>
                        <div className="mt-12 grid w-full max-w-[208px] grid-flow-col grid-cols-4 gap-3 max-[991px]:mb-8">
                            <a href="https://www.instagram.com/stepone_securities/" className="mx-auto flex max-w-[24px] flex-col items-center justify-center text-black">
                                <i class="bi bi-instagram text-2xl bg-white text-primary"></i>
                            </a>
                            <a href="https://t.me/steponesecurities" className="mx-auto flex max-w-[24px] flex-col items-center justify-center text-black">
                                <i class="bi bi-telegram text-2xl bg-white text-primary"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/step-one-securities" className="mx-auto flex max-w-[24px] flex-col items-center justify-center text-black">
                                <i class="bi bi-linkedin text-2xl bg-white text-primary"></i>
                            </a>
                            <a href="#" className="mx-auto flex max-w-[24px] flex-col items-center justify-center text-black">
                                <i class="bi bi-facebook text-2xl bg-white text-primary"></i>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col items-start font-semibold">
                        <a href="" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">&nbsp;</a>
                    </div>
                    <div className="hidden flex-col items-start font-semibold lg:flex">
                        <a href="/solution1" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Quick Grow</a>
                        <a href="/solution2" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Triple Play</a>
                        <a href="/solution3" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Easy Earn</a>
                    </div>
                    <div className="hidden flex-col items-start font-semibold lg:flex">
                        <a href="/solution4" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Silver Gains</a>
                        <a href="/solution5" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Zero To Peak</a>
                        <a href="/solution6" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Mega Multiplier</a>
                    </div>
                    <div className="flex flex-col items-start font-semibold lg:hidden min-w-[10rem]">
                        <a href="/solution1" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Quick Grow</a>
                        <a href="/solution2" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Triple Play</a>
                        <a href="/solution3" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Easy Earn</a>
                        <a href="/solution4" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Silver Gains</a>
                        <a href="/solution5" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Zero To Peak</a>
                        <a href="/solution6" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Mega Multiplier</a>
                    </div>
                    <div className="flex flex-col items-start font-semibold">
                        <a href="/recommendations" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Recommendations</a>
                        <a href="#" className="py-2 text-sm font-normal text-secondary-text transition hover:text-primary hover:underline decoration-2 underline-offset-8">Pricing</a>
                    </div>
                </div>
                <div className="mt-16 flex flex-col items-center justify-center max-[767px]:flex-col max-[479px]:flex-col-reverse md:items-center">
                    <div className="max-[991px]:flex-none max-[768px]:flex-1">
                        <p className='text-xs text-justify mb-3 text-secondary-text'>An Authorised franchisee of <a href='https://onboarding.nuvamawealth.com/Partner?utm_source=EMPLOYEE&utm_campaign=40231&utm_content=ELITE' target='_blank' className='text-primary hover:underline decoration-2 underline-offset-4'>Nuvama Wealth</a></p>
                    </div>
                </div>
                <div className="mb-14 mt-2 w-full [border-bottom:1.7px_solid_rgb(0,_0,_0)]"></div>
                <div className="flex flex-row items-start justify-center max-[767px]:flex-col max-[479px]:flex-col-reverse md:items-center">
                    <div className="max-[991px]:flex-none max-[768px]:flex-1">
                        <p className='text-xs text-justify mb-3 text-secondary-text'>Stepone securities LLP (Brand Name - Stepone securities) makes no warranties or representations, express or implied, on products and services offered through the platform. It disclaims all responsibility for any losses or damages resulting from using or depending on its advice or related services. Past performance is not indicative of future returns.
                            When selecting a fund or creating a portfolio that meets your needs, please take into account your unique investing requirements, risk tolerance, objective, time frame, risk and reward balance, and the cost associated with the investment. Performance and returns of any investment portfolio can neither be predicted nor guaranteed.

                            Investments made on advisory are subject to market risks, read all scheme related documents carefully. Marketing and distribution of various financial products such as Mutual Funds,Loans,Insurance,Bonds, Domestic Equity and International Equity are powered by Stepone securities
                        </p>
                        <p className='text-xs text-justify mb-3 text-secondary-text'>STEPONE BUSINESS AND MANAGEMENT SOLUTION LLP [LLPIN: ACE-4101] [Designation: Designated Partner] [Appointment: 15th December, 2023] [Address: Stepone securities 7/117, Therku Thottam Keeranatham, Coimbatore - 641035]</p>
                        <p className="text-xs text-center mt-4 text-tertiary-text">Copyright © 2023 Stepone-securities. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
        {
            showModel && <div class="h-screen"><div id="modal" class="flex items-center justify-center h-screen w-screen fixed top-0 bg-black bg-opacity-60 z-10">
                <div class="bg-white max-w-xl w-full rounded-md">
                    <div class="p-3 flex items-end justify-end">
                        <span class="modal-close cursor-pointer text-3xl" onClick={() =>setShowmodal(false)}>×</span>
                    </div>
                    <ContactUs/>
                </div>
            </div>
            </div>
        }
        <div onMouseLeave={toggleModel} style={{ width: '100%', height: '100vh', position: 'absolute',top: 0,left: 0, 'z-index':'-1'}}></div>
        </>
    );
}

export default Footer;
