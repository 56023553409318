import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HomeHeader from "../components/HomeHeader";
import Solution6PageImage from "../components/assets/Solution6PageImage";
import EasySteps from "../components/EasySteps";


function SolutionPage6() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader title="Mega Multiplier" description="10 Lakh to 10 Crore: experience exponential wealth growth.">
                <Solution6PageImage />
            </HomeHeader>
            <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            {/* <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Mega Multiplier</h2> */}
            <p class="text-base sm:text-lg text-primary-text">The Mega Multiplier plan is not for the faint-hearted. By investing ₹10 lakh, it puts you on the path to potentially unlock a staggering ₹10 crore return. It's a high-stakes, high-reward strategy designed for the investor who is prepared to take on the market with a bold and assertive approach.</p>

            </div>
            </section>
            <EasySteps />
            <Footer />
        </div>
    );
}
export default SolutionPage6;
