import { useState } from "react"

function FAQ({ FAQs }) {
    const [state, setState] = useState([0])
    const handleClick = (index) => {
        if (state.includes(index)) {
            const newState = state.filter((row) => row !== index)
            return setState(newState)
        }
        return setState(newState => ([...newState, index]))
    }
    return (
        <>
            <div className="mt-10 flex w-full flex-col">
                {FAQs.map((row, index) => {
                    const isFAQOpen = state.includes(index)
                    return (
                        <div key={index} className="relative my-3 w-full rounded-md px-12 py-8" onClick={() => handleClick(index)}>
                            <div className="max-w-[700px]">
                                <h2 className="font-bold text-black text-xl">{row.question}</h2>
                                <p className={"font-inter mt-4 text-base font-light text-gray-500 " + (isFAQOpen ? "show" : "hidden")}>{row.answer}</p>
                            </div>
                            <span className="absolute right-5 top-9">
                                <svg className={isFAQOpen ? 'show' : 'hidden'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="white"></circle>
                                    <path d="M7.04688 11.9999H16.9469" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                                <svg className={isFAQOpen ? 'hidden' : 'show'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="white"></circle>
                                    <path d="M7.05078 12H16.9508" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M12 7.05005V16.95" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                        </div>
                    )
                })}
            </div>
        </>
    );
}

export default FAQ;