import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";
import Recommendation from "./pages/Recommendation";
import PageNotFound from "./pages/PageNotFound";
import Pricing from "./pages/Pricing";
import Solution1 from './pages/Solution1'
import Solution2 from './pages/Solution2'
import Solution3 from './pages/Solution3'
import Solution4 from './pages/Solution4'
import Solution5 from './pages/Solution5'
import Solution6 from './pages/Solution6'

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route exact path="/recommendations" element={<Recommendation />} />
				<Route exact path="/pricing" element={<Pricing />} />
				<Route exact path="/Solution1" element={<Solution1 />} />
				<Route exact path="/Solution2" element={<Solution2 />} />
				<Route exact path="/Solution3" element={<Solution3 />} />
				<Route exact path="/Solution4" element={<Solution4 />} />
				<Route exact path="/Solution5" element={<Solution5 />} />
				<Route exact path="/Solution6" element={<Solution6 />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
