import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HomeHeader from "../components/HomeHeader";
import Solution2PageImage from "../components/assets/Solution2PageImage";
import EasySteps from "../components/EasySteps";


function SolutionPage2() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader title="Triple Play" description="Multiply your 5 Lakh into thrice its value, a smart choice.">
                    <Solution2PageImage />
                </HomeHeader>
            <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            {/* <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Triple Play</h2> */}
            <p class="text-base sm:text-lg text-primary-text">Imagine this: If you have a minimum of 5 lakhs to invest, you could triple your investment in just five years.<br /><br />
 
 That's right, invest 5 lakhs today and in five years' time, you could receive a whopping 15 lakhs in return! It's a simple and straightforward investment plan that requires no payments from you during the five-year period. But don't worry, you can rest assured that your investment is secure and being put to work in a responsible and profitable manner.<br /><br />
  
 So, why wait? Make the smart move and invest in our program today. In just five years, you'll be rewarded with a handsome return on your investment that's sure to make your financial</p>

            </div>
            </section>
            <EasySteps />
            <Footer />
        </div>
    );
}
export default SolutionPage2;
