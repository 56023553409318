import React from "react";

function HomePageImage() {
    return (
        <div class="min-w-[50%]">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" preserveAspectRatio="none">
            <g>
                <path fill="#e0e0e0" d="M363.66 444.75H475.99V473.07H363.66z"></path>
                <path
                    fill="#e0e0e0"
                    d="M408.25 444.75L410.11 473.07 425.96 473.07 426.79 444.75 408.25 444.75z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M336.4 444.75H448.72999999999996V473.07H336.4z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M448.38 449.19c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65zM448.34 455.67c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65zM448.34 462.15c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65zM448.34 468.63c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M381 444.75L382.85 473.07 398.71 473.07 399.53 444.75 381 444.75z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M354.59 417.02H466.91999999999996V445.34H354.59z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M399.19 417.02L401.04 445.34 416.9 445.34 417.72 417.02 399.19 417.02z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M327.34 417.02H439.66999999999996V445.34H327.34z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M439.31 421.46c0 .36-25 .65-55.81.65s-55.81-.29-55.81-.65 25-.65 55.81-.65 55.81.29 55.81.65zM439.28 427.94c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65zM439.28 434.42c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65zM439.28 440.9c0 .36-25 .65-55.81.65s-55.82-.29-55.82-.65 25-.65 55.82-.65 55.81.29 55.81.65z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M371.93 417.02L373.79 445.34 389.64 445.34 390.47 417.02 371.93 417.02z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M58.07 44.96H75.47V114.44999999999999H58.07z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M83.66 60.12H101.06V114.44999999999999H83.66z"
                ></path>
                <path fill="#f5f5f5" d="M109.63 72.53H127.03V114.45H109.63z"></path>
                <path fill="#f5f5f5" d="M133.68 89.56H151.08V114.45H133.68z"></path>
                <path fill="#e0e0e0" d="M69.1 425.57H181.43V449.87H69.1z"></path>
                <path
                    fill="#e0e0e0"
                    d="M113.7 425.57L115.55 449.87 131.41 449.87 132.23 425.57 113.7 425.57z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M41.84 425.57H154.17000000000002V449.87H41.84z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M153.82 429.38c0 .31-25 .55-55.81.55s-55.82-.24-55.82-.55 25-.56 55.82-.56 55.81.25 55.81.56zM153.78 434.94c0 .31-25 .55-55.81.55s-55.81-.24-55.81-.55 25-.56 55.81-.56 55.81.25 55.81.56zM153.78 440.5c0 .31-25 .55-55.81.55s-55.81-.24-55.81-.55 25-.56 55.81-.56 55.81.25 55.81.56zM153.78 446.06c0 .31-25 .56-55.81.56s-55.81-.25-55.81-.56 25-.56 55.81-.56 55.81.25 55.81.56z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M86.44 425.57L88.29 449.87 104.15 449.87 104.97 425.57 86.44 425.57z"
                ></path>
                <path fill="#e0e0e0" d="M54.01 449.87H159.46V472.68H54.01z"></path>
                <path
                    fill="#e0e0e0"
                    d="M95.87 449.87L97.61 472.69 112.5 472.69 113.27 449.87 95.87 449.87z"
                ></path>
                <path fill="#ebebeb" d="M28.42 449.87H133.87V472.68H28.42z"></path>
                <path
                    fill="#e0e0e0"
                    d="M133.54 453.45c0 .29-23.46.52-52.39.52s-52.4-.23-52.4-.52 23.46-.52 52.4-.52 52.39.23 52.39.52zM133.5 458.67c0 .29-23.45.52-52.39.52s-52.39-.23-52.39-.52 23.45-.52 52.39-.52 52.39.23 52.39.52zM133.5 463.89c0 .29-23.45.52-52.39.52s-52.39-.23-52.39-.52 23.45-.52 52.39-.52 52.39.23 52.39.52zM133.5 469.11c0 .29-23.45.52-52.39.52s-52.39-.23-52.39-.52 23.45-.52 52.39-.52 52.39.23 52.39.52z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M70.29 449.87L72.03 472.69 86.91 472.69 87.69 449.87 70.29 449.87z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M117.61 197.24a36.11 36.11 0 11-43.33-26.91 36.07 36.07 0 0143.33 26.91z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M109.24 199.19a27.52 27.52 0 11-33-20.51 27.49 27.49 0 0133 20.51z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M109 198.35c-.16.05-.55-1.11-1.46-3.06a29.11 29.11 0 00-5-7.49 27.25 27.25 0 00-11-7.25 25.19 25.19 0 00-3.68-1 26.82 26.82 0 00-4-.5 27.28 27.28 0 00-4.13.11 29 29 0 00-4.17.76 30.63 30.63 0 00-4 1.41 27.4 27.4 0 00-3.63 2 26.42 26.42 0 00-5.88 5.11 27.63 27.63 0 00-3.88 5.9 28.6 28.6 0 00-2 5.84 29.07 29.07 0 00-.62 9c.18 2.15.42 3.34.25 3.38s-.62-1.09-1.06-3.27a25.19 25.19 0 010-9.4 27.87 27.87 0 011.92-6.27 28.27 28.27 0 014-6.42 26.38 26.38 0 012.84-3 27 27 0 013.39-2.62 28.85 28.85 0 013.91-2.14 30.67 30.67 0 014.32-1.53 29.55 29.55 0 014.5-.81 27.66 27.66 0 018.7.5 26.9 26.9 0 013.94 1.17 28.27 28.27 0 016.65 3.6 27.71 27.71 0 014.79 4.48 25.71 25.71 0 014.64 8.18c.66 2.07.82 3.29.66 3.32z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M89.18 220.7l1.16 4.66-4.16 1-1.12-4.53c-4.18.77-8.44.46-11.17-.76l1-5.64a17.23 17.23 0 008.79 1L82 209.48c-4.89 0-10.42-.24-11.89-6.18-1.09-4.41 1.17-9 7.55-11.35l-1.14-4.62 4.15-1 1.12 4.53a19.35 19.35 0 019.06.45L90 196.9a17.4 17.4 0 00-6.89-.58l1.75 7.06c4.88-.06 10.33.22 11.78 6.08 1.1 4.32-1.13 8.84-7.46 11.24zm-8.7-17.33l-1.48-5.9c-2.08 1.05-2.65 2.47-2.32 3.84s1.83 1.93 3.8 2.06zm9.63 8.24c-.36-1.45-1.76-1.94-3.71-2.12l1.42 5.73c1.97-.97 2.62-2.28 2.29-3.61z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M98.43 291.54a22.27 22.27 0 11-31.43 1.4 22.24 22.24 0 0131.43-1.4z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M94.85 295.44a17 17 0 11-23.95 1.07 17 17 0 0123.95-1.07z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M94.46 295.09a9.49 9.49 0 01-1.81-1 17.93 17.93 0 00-5.16-2.09 17.09 17.09 0 00-3.79-.43 16.62 16.62 0 00-4.32.57 15.06 15.06 0 00-2.23.76A16.76 16.76 0 0075 294a17.87 17.87 0 00-2.06 1.49 19.09 19.09 0 00-1.94 1.84 17.22 17.22 0 00-1.54 2.11 16.15 16.15 0 00-1.17 2.26 17.29 17.29 0 00-.79 2.33 16 16 0 00-.42 2.32 17 17 0 00.08 4.36 17.32 17.32 0 001 3.68 18.07 18.07 0 002.84 4.79c.84 1 1.37 1.55 1.3 1.63s-.69-.34-1.68-1.29a15.74 15.74 0 01-3.29-4.77 17.67 17.67 0 01-1.2-3.86 17 17 0 01-.21-4.66 17.46 17.46 0 011.22-5 17.57 17.57 0 011.25-2.44 18.25 18.25 0 011.66-2.29 18.76 18.76 0 012-2 17 17 0 012.24-1.6 18 18 0 012.37-1.18 15.67 15.67 0 012.41-.77 17.15 17.15 0 014.63-.49 16.69 16.69 0 014 .62 15.52 15.52 0 015.21 2.54c1.09.83 1.6 1.38 1.55 1.47z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M92.14 313.38l2.21 2-1.76 2-2.15-1.91a12.36 12.36 0 01-5.94 3.5l-1.44-3.22a10.69 10.69 0 004.8-2.58l-3.29-2.93c-2.48 1.71-5.39 3.51-8.2 1-2.09-1.86-2.54-5-.12-8.41l-2.18-1.95 1.75-2 2.18 1.88a11.85 11.85 0 014.76-2.93l1.52 3.17a10.86 10.86 0 00-3.71 2.1l3.35 3c2.46-1.73 5.33-3.49 8.11-1 2.05 1.77 2.49 4.9.11 8.28zm-10.47-5.79l-2.8-2.49c-.69 1.25-.49 2.18.16 2.75s1.6.36 2.64-.26zm7.77.84c-.69-.61-1.57-.38-2.62.21l2.72 2.42c.66-1.18.53-2.06-.1-2.63z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M444.56 166.84a25.28 25.28 0 11-29.9-19.51 25.24 25.24 0 0129.9 19.51z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M438.68 168.08a19.26 19.26 0 11-22.79-14.87 19.24 19.24 0 0122.79 14.87z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M438.55 167.49a11.13 11.13 0 01-1-2.17 20.31 20.31 0 00-3.39-5.32 19.47 19.47 0 00-3.21-2.91 19.21 19.21 0 00-4.36-2.33 17.66 17.66 0 00-2.56-.77 18.91 18.91 0 00-2.76-.42 19.93 19.93 0 00-2.89 0 21.5 21.5 0 00-2.93.47 21.8 21.8 0 00-2.83.93 18.78 18.78 0 00-4.84 2.94 17.75 17.75 0 00-1.92 1.85 19.73 19.73 0 00-2.81 4.08 19.34 19.34 0 00-1.53 4.05 20.47 20.47 0 00-.57 6.28 10.88 10.88 0 01.12 2.37 5.7 5.7 0 01-.69-2.31 17.62 17.62 0 01.12-6.57 19.29 19.29 0 014.34-8.79 18.6 18.6 0 012-2 21 21 0 012.42-1.78 20 20 0 012.76-1.43 21 21 0 013.05-1 22 22 0 013.17-.5 20.15 20.15 0 013.11 0 17.87 17.87 0 013 .48 18.25 18.25 0 012.74.87 19.93 19.93 0 014.59 2.62 19.31 19.31 0 013.28 3.22 17.85 17.85 0 013.12 5.79 5.75 5.75 0 01.47 2.35z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M424.31 182.82l.73 3.27-2.92.66-.72-3.19a14.19 14.19 0 01-7.8-.71l.81-3.92a12.09 12.09 0 006.13.8l-1.09-4.88c-3.42-.06-7.29-.33-8.23-4.51-.69-3.1 1-6.27 5.46-7.83l-.72-3.24 2.92-.66.71 3.19a13.56 13.56 0 016.34.45l-.66 3.93a12.17 12.17 0 00-4.81-.52l1.11 5c3.42 0 7.23.31 8.15 4.43.68 3.01-.95 6.14-5.41 7.73zm-5.82-12.27l-.94-4.15c-1.47.7-1.89 1.68-1.67 2.65s1.23 1.38 2.61 1.5zm6.6 5.92c-.23-1-1.2-1.39-2.55-1.55l.9 4c1.39-.61 1.86-1.51 1.65-2.45z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M299.67 100a17.76 17.76 0 11-24.74-4.13 17.73 17.73 0 0124.74 4.13z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M296.23 102.44a13.53 13.53 0 11-18.85-3.14 13.52 13.52 0 0118.85 3.14z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M296 102.1c-.06.05-.45-.4-1.23-1.12a14.23 14.23 0 00-3.68-2.47 13.79 13.79 0 00-2.89-1 13.39 13.39 0 00-3.46-.27 12.24 12.24 0 00-1.87.23A12 12 0 00281 98a13.73 13.73 0 00-1.85.82 15.88 15.88 0 00-1.76 1.13 14.86 14.86 0 00-1.56 1.39 13.51 13.51 0 00-1.28 1.57 12.6 12.6 0 00-1 1.68 14.23 14.23 0 00-.72 1.74 13.76 13.76 0 00-.5 6.45 14.41 14.41 0 001.37 4.22 8.85 8.85 0 01.75 1.49c-.05 0-.49-.38-1.1-1.29a12.45 12.45 0 01-1.78-4.27 14 14 0 01-.29-3.21 13.67 13.67 0 01.61-3.67 13.24 13.24 0 01.73-1.88 13.47 13.47 0 011.05-1.83 13.69 13.69 0 011.38-1.7 14.48 14.48 0 013.57-2.72 14.7 14.7 0 012-.87 14.26 14.26 0 012-.53 14.1 14.1 0 012-.2 13.31 13.31 0 013.7.39 13.56 13.56 0 013 1.14 12.52 12.52 0 013.68 2.85c.75.82 1 1.36 1 1.4z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M291.14 116l1.4 1.9-1.7 1.24-1.36-1.85a9.93 9.93 0 01-5.22 1.75l-.58-2.75a8.53 8.53 0 004.17-1.22l-2.08-2.83c-2.22.93-4.78 1.84-6.56-.58-1.32-1.8-1.15-4.3 1.3-6.58l-1.38-1.88 1.7-1.25 1.36 1.85a9.48 9.48 0 014.2-1.49l.68 2.71a8.57 8.57 0 00-3.24 1l2.17 2.88c2.2-.94 4.73-1.83 6.49.56 1.25 1.77 1.08 4.24-1.35 6.54zm-7.2-6.25l-1.77-2.41a1.65 1.65 0 00-.33 2.18c.43.57 1.16.48 2.1.21zm5.92 1.94c-.44-.59-1.16-.55-2.08-.27l1.72 2.34c.71-.83.76-1.55.36-2.09z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M433.43 56.79a33 33 0 11-39.06-25.49 33 33 0 0139.06 25.49z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M425.74 58.39A25.16 25.16 0 11396 39a25.14 25.14 0 0129.74 19.39z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M425.57 57.62c-.15 0-.48-1-1.27-2.82a26.79 26.79 0 00-4.42-7A25.08 25.08 0 00410 41a22.45 22.45 0 00-3.35-1 23.3 23.3 0 00-3.6-.54 23.75 23.75 0 00-3.77 0 26.75 26.75 0 00-3.83.61 26.12 26.12 0 00-3.69 1.21 23.54 23.54 0 00-3.37 1.72 22.26 22.26 0 00-3 2.12 23.26 23.26 0 00-2.52 2.43 25.49 25.49 0 00-3.67 5.32 25.83 25.83 0 00-2 5.29 26.73 26.73 0 00-.75 8.21c.12 2 .31 3 .16 3.09s-.55-1-.91-3a23.43 23.43 0 01.17-8.59 25.12 25.12 0 011.89-5.69 25.58 25.58 0 013.77-5.79 24.95 24.95 0 015.81-5 26.23 26.23 0 013.66-1.87 28.09 28.09 0 014-1.31 27.17 27.17 0 014.14-.65 26.32 26.32 0 014.07 0 24.23 24.23 0 013.87.63 23.77 23.77 0 013.58 1.15A25.21 25.21 0 01420.93 47a23.2 23.2 0 014.07 7.53c.6 1.94.68 3.07.57 3.09z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M407 77.65l1 4.27-3.82.86-.94-4.16c-3.83.62-7.72.25-10.19-.92l.95-5.13a15.83 15.83 0 008 1.05l-1.43-6.38c-4.47-.08-9.52-.43-10.74-5.89-.91-4.05 1.25-8.18 7.13-10.22l-.95-4.24 3.82-.86.93 4.17a17.52 17.52 0 018.27.59l-.85 5.12a16.22 16.22 0 00-6.29-.68l1.46 6.5c4.46 0 9.43.41 10.64 5.79.94 3.97-1.19 8.06-6.99 10.13zm-7.6-16l-1.22-5.42c-1.92.91-2.47 2.19-2.19 3.45s1.58 1.79 3.38 1.95zm8.6 7.71c-.3-1.34-1.57-1.82-3.34-2l1.18 5.27c1.81-.87 2.43-2.05 2.16-3.27z"
                ></path>
            </g>
            <g>
                <path fill="#e0e0e0" d="M275.93 444.18H406.72V472.48H275.93z"></path>
                <path
                    fill="#e0e0e0"
                    d="M327.85 444.18L330.01 472.48 348.47 472.48 349.43 444.18 327.85 444.18z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M243.82 444.1H374.61V472.40000000000003H243.82z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M374.57 448.62c0 .35-29.1.64-65 .64s-65-.29-65-.64 29.09-.65 65-.65 65 .29 65 .65zM374.53 455.09c0 .36-29.1.65-65 .65s-65-.29-65-.65 29.09-.65 65-.65 65 .29 65 .65zM374.53 461.56c0 .36-29.1.65-65 .65s-65-.29-65-.65 29.09-.64 65-.64 65 .29 65 .64zM374.53 468c0 .36-29.1.65-65 .65s-65-.29-65-.65 29.09-.65 65-.65 65 .33 65 .65z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M296.12 444.18L298.27 472.48 316.74 472.48 317.7 444.18 296.12 444.18z"
                ></path>
                <path
                    fill="#263238"
                    d="M268.33 472.48h2.76l7.93-.05 29.36-.08 98.34-.08-.26.26v-28.3l.26.26-98.31-.14-29.34-.1-7.93-.05h-2.76 10.69l29.34-.06H407v28.82h-.26l-98.34-.08-29.4-.31h-9.99z"
                ></path>
                <path
                    fill="#263238"
                    d="M375.14 472.3c-.14 0-.26-6.17-.26-13.78s.12-13.77.26-13.77.26 6.16.26 13.77-.11 13.78-.26 13.78z"
                ></path>
                <path fill="#e0e0e0" d="M281.82 391.1H405.28V417.81H281.82z"></path>
                <path
                    fill="#e0e0e0"
                    d="M330.83 391.1L332.87 417.81 350.3 417.81 351.2 391.1 330.83 391.1z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M251.86 391.09H375.32V417.79999999999995H251.86z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M374.93 395.28c0 .34-27.46.62-61.34.62s-61.35-.28-61.35-.62 27.47-.61 61.35-.61 61.34.33 61.34.61zM374.89 401.4c0 .33-27.46.61-61.34.61s-61.35-.28-61.35-.61 27.47-.61 61.35-.61 61.34.27 61.34.61zM374.89 407.51c0 .33-27.46.61-61.34.61s-61.35-.28-61.35-.61 27.47-.61 61.35-.61 61.34.27 61.34.61zM374.89 413.62c0 .34-27.46.61-61.34.61s-61.35-.27-61.35-.61 27.47-.61 61.35-.61 61.34.27 61.34.61z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M300.88 391.1L302.91 417.81 320.34 417.81 321.25 391.1 300.88 391.1z"
                ></path>
                <path
                    fill="#263238"
                    d="M274.64 417.73h10.09l27.71-.08 92.84-.09-.26.26V391l.26.26-93.47.32h-38.13 2.67l7.55-.08 27.91-.2 93.47-.49h.26V418h-.26l-92.84-.08-27.71-.08-7.48-.05h-1.95z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M295.03 417.81H418.48999999999995V444.52H295.03z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M344.04 417.81L346.08 444.52 363.51 444.52 364.42 417.81 344.04 417.81z"
                ></path>
                <path fill="#ebebeb" d="M264.72 417.73H388.18V444.44H264.72z"></path>
                <path
                    fill="#e0e0e0"
                    d="M388.14 422c0 .33-27.46.61-61.34.61s-61.34-.28-61.34-.61 27.46-.61 61.34-.61 61.34.27 61.34.61zM388.1 428.11c0 .33-27.46.61-61.34.61s-61.35-.28-61.35-.61 27.47-.61 61.35-.61 61.34.27 61.34.61zM388.1 434.22c0 .34-27.46.61-61.34.61s-61.35-.27-61.35-.61 27.47-.61 61.35-.61 61.34.27 61.34.61zM388.1 440.33c0 .34-27.46.61-61.34.61s-61.35-.27-61.35-.61 27.47-.61 61.35-.61 61.34.28 61.34.61z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M314.09 417.81L316.12 444.52 333.55 444.52 334.46 417.81 314.09 417.81z"
                ></path>
                <path
                    fill="#263238"
                    d="M287.85 444.52h2.61l7.49-.05 27.71-.08 92.83-.08-.26.26v-26.76l.26.26-92.8-.14-27.7-.1-7.48-.05h-2.6 10.08l27.7-.06h93.06v27.23h-.26l-92.83-.09-27.66-.25h-9.43c-.49-.07-.72-.09-.72-.09z"
                ></path>
                <path
                    fill="#263238"
                    d="M375.44 418c-.14 0-.26-6-.26-13.32s.12-13.32.26-13.32.26 6 .26 13.32-.11 13.32-.26 13.32zM388.69 444.35c-.15 0-.26-5.82-.26-13s.11-13 .26-13 .26 5.82.26 13-.12 13-.26 13z"
                ></path>
            </g>
            <g>
                <path
                    fill="#0096FF"
                    d="M224 103.05a6.83 6.83 0 116.31 7.3 6.82 6.82 0 01-6.31-7.3z"
                ></path>
                <path
                    d="M230.42 100.41l.05-.9h.81l-.05.88a3.81 3.81 0 012 .76l-.5 1a3.32 3.32 0 00-1.54-.66l-.08 1.34c.89.28 1.87.64 1.8 1.79 0 .86-.72 1.55-2 1.62v.9l-.81-.05v-.88a3.62 3.62 0 01-1.61-.59l.46-1a3.46 3.46 0 001.21.5l.09-1.38c-.89-.26-1.86-.62-1.79-1.76.02-.8.68-1.49 1.96-1.57zM231 104l-.07 1.15c.44-.07.62-.3.64-.56s-.21-.42-.57-.59zm-1.27-2c0 .28.21.45.55.59l.06-1.11c-.4.06-.59.26-.6.52z"
                    opacity="0.3"
                ></path>
                <path
                    d="M225.59 103.75a2.9 2.9 0 01.09.64 5.61 5.61 0 00.48 1.63 5.79 5.79 0 00.62 1 5.32 5.32 0 001 .93 5.16 5.16 0 00.6.39 4.28 4.28 0 00.69.31 4.63 4.63 0 00.75.22 5.15 5.15 0 00.8.09 5.3 5.3 0 00.8 0 4.64 4.64 0 00.76-.14 5 5 0 00.71-.26 3.55 3.55 0 00.64-.34 5.16 5.16 0 001-.84 6.35 6.35 0 00.7-.94 5.51 5.51 0 00.61-1.59 3 3 0 01.15-.62 1.64 1.64 0 010 .65 5 5 0 01-.53 1.69 4.82 4.82 0 01-.7 1 5.22 5.22 0 01-1.08.93 5 5 0 01-.68.38 4.57 4.57 0 01-.76.28 4.52 4.52 0 01-.83.17 6.13 6.13 0 01-.86 0 4.94 4.94 0 01-.86-.11 5.39 5.39 0 01-.81-.23 5.47 5.47 0 01-.73-.35 6.1 6.1 0 01-.65-.43 5.65 5.65 0 01-1-1 6 6 0 01-.61-1.08 4.93 4.93 0 01-.38-1.74 1.72 1.72 0 01.08-.64z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M225.58 103.26a9.3 9.3 0 00.1 1.29 5.29 5.29 0 001.67 3 5.07 5.07 0 004.8 1.1 5.22 5.22 0 00.73-9.72 5.07 5.07 0 00-4.92.38 5.28 5.28 0 00-2.09 2.71 9.71 9.71 0 00-.29 1.25 4 4 0 01.21-1.27 5.33 5.33 0 012.09-2.82 5.24 5.24 0 015.08-.43 5.41 5.41 0 01-.75 10.08 5.18 5.18 0 01-5-1.17 5.3 5.3 0 01-1.65-3.09 4.08 4.08 0 01.02-1.31z"
                ></path>
                <path
                    fill="#fff"
                    d="M233 97.45a1.83 1.83 0 01.75.23 5.48 5.48 0 011.57 1.06 5.59 5.59 0 011.15 1.51 2.29 2.29 0 01.28.73 10.08 10.08 0 00-1.57-2.09 9.65 9.65 0 00-2.18-1.44zM231.9 97.08s.16-.06.32 0 .27.12.26.18-.16.06-.32 0-.28-.12-.26-.18z"
                ></path>
                <path
                    fill="#fafafa"
                    d="M302 281v-11.08h2.83a3.3 3.3 0 100-6.6H302v-3.81h2.83a3.31 3.31 0 000-6.61H302v-5.53H141.22v5.53h-2.83a3.31 3.31 0 000 6.61h2.83v3.81h-2.83a3.3 3.3 0 100 6.6h2.83v9.52a20.93 20.93 0 00-20.93 20.93V444A28.73 28.73 0 00149 472.68h145.19A28.73 28.73 0 00322.93 444V302A20.93 20.93 0 00302 281z"
                ></path>
                <path
                    fill="#263238"
                    d="M302 281s.76 0 2.2.09a22.12 22.12 0 016.22 1.63 20.68 20.68 0 014.11 2.39 20 20 0 014.09 4 21.11 21.11 0 014.38 13.38c0 21.54.05 52.69.08 91.18v50.4a32.41 32.41 0 01-.34 4.17 29.73 29.73 0 01-.9 4.12c-.2.67-.47 1.33-.71 2a14 14 0 01-.84 1.95 32 32 0 01-2.09 3.75 32.42 32.42 0 01-2.69 3.38 27.65 27.65 0 01-6.79 5.5 28.83 28.83 0 01-8.25 3.26 38.46 38.46 0 01-9 .71H153.55c-3.5 0-7.1.13-10.61-.65a28.42 28.42 0 01-9.88-4.15 29.07 29.07 0 01-12.21-17.44c-.9-3.56-.83-7.28-.82-10.91v-11-128.13c0-.81.08-1.62.11-2.43s.22-1.62.4-2.4a21.56 21.56 0 014.14-8.65 21.33 21.33 0 0116.53-7.95l-.24.24v-9.52l.23.24h-2a5 5 0 01-2.08-.25 3.53 3.53 0 01.11-6.62 5.77 5.77 0 012-.21h2l-.23.24v-3.81l.23.23h-2a5 5 0 01-2.09-.26 3.53 3.53 0 01.13-6.61 5.76 5.76 0 012-.2h2l-.22.23v-5.75h.22l160.78.1h.12v5.65l-.12-.12h2.38a3.72 3.72 0 012.22.5 3.41 3.41 0 01-1.11 6.25 10.74 10.74 0 01-1.78.06H302l.1-.09v3.81l-.09-.09h2.57a3.5 3.5 0 012.29.69 3.37 3.37 0 01-1.7 6.05h-3.16l.06-.06c0 7.41-.06 10.68-.06 11.07 0-.39 0-3.66-.06-11.07v-.05H305.15a3.23 3.23 0 001.61-5.79 3.29 3.29 0 00-2.19-.65h-2.66v-4h1.8a11.16 11.16 0 001.74-.07 3.19 3.19 0 001-5.86 3.54 3.54 0 00-2.1-.47h-2.49v-.11-5.53l.12.12-160.78.11.23-.23v5.76h-2.19a5.48 5.48 0 00-1.88.17 3.11 3.11 0 00-2.06 2.82 3.06 3.06 0 001.94 2.93 4.62 4.62 0 001.92.22h2.28v4.28h-2.21a5.24 5.24 0 00-1.88.18 3.06 3.06 0 00-.1 5.74 4.77 4.77 0 001.91.22h2.28v10.01h-.25A20.71 20.71 0 00121 295.9c-.18.77-.26 1.56-.39 2.35s-.1 1.58-.11 2.38v139.16a43.55 43.55 0 00.81 10.79 28.53 28.53 0 0012 17.12 27.72 27.72 0 009.71 4.08c3.44.77 7 .63 10.5.65h138.03a37.89 37.89 0 008.87-.71 28.18 28.18 0 008.13-3.19 27.4 27.4 0 006.68-5.41 31.3 31.3 0 002.66-3.33 31.8 31.8 0 002.06-3.69 13.69 13.69 0 00.83-1.93c.23-.66.5-1.3.7-2a28.58 28.58 0 00.89-4.06 51.24 51.24 0 00.35-8.21v-16.07-30.22c0-38.49.06-69.64.08-91.18a21 21 0 00-4.33-13.29 20.1 20.1 0 00-4.05-4 21 21 0 00-4.07-2.4 22.53 22.53 0 00-6.19-1.67z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M282.64 398.11a27.14 27.14 0 1115-35.28 27.11 27.11 0 01-15 35.28z"
                ></path>
                <path
                    d="M261.93 379.72l-3.32 1.39-1.24-3 3.23-1.36a15.2 15.2 0 01-.73-8.38l4.3.11a13 13 0 00.31 6.64l5-2.08c-.59-3.63-1-7.76 3.21-9.54 3.14-1.32 6.8-.17 9.3 4.29l3.29-1.38 1.24 3-3.23 1.36a14.54 14.54 0 01.72 6.78l-4.27.05a13.36 13.36 0 00-.36-5.19l-5 2.12c.61 3.61 1 7.69-3.15 9.45-3.17 1.29-6.78.16-9.3-4.26zm11.86-8.47l4.21-1.77c-1-1.42-2.13-1.68-3.11-1.27s-1.23 1.56-1.1 3.04zm-5 8.1c1-.43 1.24-1.53 1.15-3l-4.09 1.72c.94 1.35 1.99 1.68 2.94 1.28z"
                    opacity="0.3"
                ></path>
                <path
                    d="M282.3 391.08c-.05-.12.76-.54 2.11-1.44a21.65 21.65 0 005-4.58 21.06 21.06 0 002.47-3.94 20.78 20.78 0 001.64-5.05 20.26 20.26 0 00.24-5.85 20.23 20.23 0 00-.55-3 21.2 21.2 0 00-1.05-3 22.9 22.9 0 00-1.51-2.81 20 20 0 00-1.87-2.47 20.3 20.3 0 00-4.47-3.77 20.77 20.77 0 00-4.84-2.2 21.26 21.26 0 00-11.32-.27 12 12 0 01-2.47.58 6.07 6.07 0 012.3-1.17 19 19 0 017-1.11 20.83 20.83 0 0110.02 2.86 19 19 0 012.53 1.77 19.42 19.42 0 012.34 2.21 20.79 20.79 0 012 2.66 23.11 23.11 0 011.64 3 22.7 22.7 0 011.12 3.25 21.22 21.22 0 01.58 3.29 20.57 20.57 0 010 3.23 19.11 19.11 0 01-.41 3.06 20.61 20.61 0 01-4.68 9.42 18.94 18.94 0 01-5.53 4.39 6.24 6.24 0 01-2.29.94z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M280.58 392l.3-.16.89-.44a9.83 9.83 0 001.41-.77 15.3 15.3 0 001.83-1.2 20.25 20.25 0 004.28-4.18 20.66 20.66 0 003.54-7 20.11 20.11 0 00-4.51-19.25 21.46 21.46 0 00-2.11-2 22.49 22.49 0 00-2.47-1.7 19.63 19.63 0 00-5.72-2.34 20.5 20.5 0 00-6.37-.54 22.71 22.71 0 00-6.42 1.46 22.47 22.47 0 00-5.64 3.4 20.48 20.48 0 00-4.22 4.81 19.55 19.55 0 00-2.5 5.64 21.9 21.9 0 00-.6 2.94 24.26 24.26 0 00-.12 2.94 20.13 20.13 0 0010 16.94 21.06 21.06 0 0013.41 2.67 18.53 18.53 0 002.15-.4 11 11 0 001.55-.43l.95-.3.32-.09a1.08 1.08 0 01-.31.14l-.93.35a9.83 9.83 0 01-1.54.48 15.93 15.93 0 01-2.16.45 20.14 20.14 0 01-6.05 0 20.73 20.73 0 01-7.57-2.59 20.45 20.45 0 01-7-6.65 20.68 20.68 0 01-3.3-10.6 21.83 21.83 0 01.12-3 22.89 22.89 0 01.6-3 20 20 0 012.55-5.79 21.2 21.2 0 014.31-4.93 21.39 21.39 0 0112.37-5 20.86 20.86 0 016.52.57 20.14 20.14 0 015.85 2.4 23.74 23.74 0 012.53 1.76 22.4 22.4 0 012.16 2.08 20.69 20.69 0 01.84 26.72 20.09 20.09 0 01-4.39 4.16 15.93 15.93 0 01-1.87 1.18 9.64 9.64 0 01-1.45.72l-.91.4a1.29 1.29 0 01-.32.15z"
                ></path>
                <path
                    fill="#fff"
                    d="M246.83 375.72s-.1-.28-.19-.81a17.75 17.75 0 01-.19-2.27 23.71 23.71 0 014.37-14.16 17.41 17.41 0 011.43-1.77 3 3 0 01.61-.57c.07.06-.7 1-1.74 2.54a26.78 26.78 0 00-4.3 14c-.04 1.85.1 3.03.01 3.04zM247.46 380.27c-.15 0-.36-.47-.49-1.12s-.11-1.19 0-1.21.36.47.48 1.11.15 1.19.01 1.22z"
                ></path>
                <path
                    fill="#455a64"
                    d="M265.29 379.55c-12.16 5.53-23.85 12.89-37.09 14.76-18.38 2.6-36.41-5-53.22-13.47-24.84-12.44-47.85-13.75-54.67-8.84v71.74A28.73 28.73 0 00149 472.48h145.2a28.74 28.74 0 0028.74-28.74v-76.65c-18.56.97-41.72 5.21-57.65 12.46z"
                ></path>
                <path
                    fill="#263238"
                    d="M301.56 279.37c0 .14-36.64.26-81.82.26s-81.83-.12-81.83-.26 36.63-.26 81.83-.26 81.82.11 81.82.26zM321.35 367.09h-.54l-1.59.07c-1.39.05-3.44.17-6.08.38a144 144 0 00-53.21 15c-5.62 2.75-11.45 5.83-17.8 8.35a67.24 67.24 0 01-9.94 3.12c-.87.19-1.73.38-2.62.51l-1.31.22-1.34.15c-.89.07-1.78.22-2.69.24l-2.71.1a65.07 65.07 0 01-10.69-.95 90.65 90.65 0 01-10.12-2.5 168.37 168.37 0 01-18.32-7.14c-5.73-2.55-11.1-5.1-16.24-7.23a132.46 132.46 0 00-14.55-5.22 64 64 0 00-22.1-2.75 33.7 33.7 0 00-3.49.36c-.5.07-1 .11-1.39.19l-1.15.24-1.55.31c-.35.07-.54.09-.54.09s.18-.06.53-.14l1.54-.36 1.14-.27c.42-.08.89-.13 1.4-.21a30.21 30.21 0 013.5-.41 62.89 62.89 0 0122.21 2.6 127 127 0 0114.61 5.16c5.16 2.11 10.55 4.66 16.27 7.19a170 170 0 0018.28 7.09 89.57 89.57 0 0010.06 2.48 65.07 65.07 0 0010.6.94l2.68-.1c.9 0 1.78-.16 2.66-.24l1.32-.14 1.31-.22c.87-.13 1.73-.31 2.58-.51a65.48 65.48 0 009.87-3.09c6.32-2.49 12.14-5.56 17.77-8.3A143.3 143.3 0 01291 370.77a138.29 138.29 0 0122.17-3.39c2.65-.18 4.7-.27 6.1-.28h1.59a4.51 4.51 0 01.49-.01z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M166.72 405.63c3.55.82 6.91 2.3 10.4 3.32a18.7 18.7 0 001.43-2.56 17.95 17.95 0 10-31.77 1.88 29.6 29.6 0 0119.94-2.64z"
                ></path>
                <path
                    fill="#fff"
                    d="M145.17 400.87a5.53 5.53 0 01-.31-2 15 15 0 01.67-4.95 15.2 15.2 0 012.23-4.47 5.9 5.9 0 011.41-1.52 19.93 19.93 0 00-4 13zM145.58 403.89c-.14 0-.32-.3-.4-.73s0-.79.1-.82.32.3.41.72.04.8-.11.83z"
                ></path>
                <path
                    fill="#263238"
                    d="M150.7 406.6l.29-.09a13.78 13.78 0 01-1.88-4.35 13.49 13.49 0 010-6.26 13.31 13.31 0 013.5-6.25 12.38 12.38 0 013.12-2.34 12.83 12.83 0 013.82-1.38 13.79 13.79 0 0114.67 6.6 13.53 13.53 0 011.78 7.67 13.34 13.34 0 01-2.36 6.75c-.16.25-.36.46-.54.69l.43.14c.15-.19.32-.37.47-.58a13.78 13.78 0 002.48-7 13.13 13.13 0 00-.31-4 13.31 13.31 0 00-1.55-3.93 14.28 14.28 0 00-15.21-6.84 13.15 13.15 0 00-4 1.45 12.81 12.81 0 00-3.22 2.43 13.68 13.68 0 00-3.51 12.94 13.83 13.83 0 002.02 4.35z"
                ></path>
                <path
                    d="M169.89 387.62a14.71 14.71 0 013.37 3 14 14 0 011.69 2.58 13.28 13.28 0 011.17 3.32 11.63 11.63 0 01.26 1.89 11.92 11.92 0 010 2 13.16 13.16 0 01-.32 2 13.47 13.47 0 01-1.61 3.9 12.69 12.69 0 01-1 1.45l1 .36a12.65 12.65 0 00.9-1.27 15.5 15.5 0 001-2 14.37 14.37 0 00.69-2.17 14 14 0 00.34-2.19 13.85 13.85 0 000-2.14 12 12 0 00-.32-2 13.77 13.77 0 00-1.34-3.52 14.27 14.27 0 00-1.9-2.66 12.71 12.71 0 00-3.73-2.82 4.32 4.32 0 00-1.62-.57c-.03 0 .53.27 1.42.84zM156.29 403.45c2.75 1.19 4.51-.88 6-2.83l3.33 1.44a8.79 8.79 0 01-2.32 2.55l.52.53a24.18 24.18 0 012.66.44 11.46 11.46 0 001.72-2.4l2.13.92.85-2-2.18-.9c1-3.24-.15-5.52-2.22-6.42-2.8-1.21-4.57.91-6 2.85l-3.27-1.41a8.75 8.75 0 013-3.2l-1.92-2.11a10.16 10.16 0 00-3.67 4.2l-2.13-.93-.85 2 2.19.95c-1 3.19.12 5.44 2.16 6.32zm9.25-5.45c.65.28 1 .94.82 2.08l-2.77-1.21c.64-.74 1.27-1.16 1.95-.87zm-8.87.21l2.7 1.16c-.65.72-1.27 1.12-2 .83s-.9-.92-.7-1.99z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M188.14 411.29a2.53 2.53 0 01-.48 0h-1.39a32.62 32.62 0 01-5.09-.53 58.81 58.81 0 01-7.34-1.94c-2.72-.86-5.66-2-8.83-2.74a19.92 19.92 0 00-9.07.08 43.4 43.4 0 00-7.18 2.25c-2 .79-3.58 1.52-4.67 2a14.82 14.82 0 01-1.72.76 2 2 0 01.41-.27c.28-.16.68-.39 1.22-.67a64.73 64.73 0 014.62-2.19 42.16 42.16 0 017.22-2.37 20 20 0 019.29-.12c3.22.79 6.15 1.92 8.86 2.8a63.43 63.43 0 007.26 2 37.92 37.92 0 005 .7 13.43 13.43 0 011.89.24zM212 88.1l-.71.43c-.46.28-1.15.7-2 1.3-1.76 1.18-4.28 3-7.4 5.58-1.55 1.28-3.31 2.7-5.1 4.34a14.81 14.81 0 00-3.25 7.1c-1.13 5.61 0 12.23 2.29 19a102.3 102.3 0 004.17 10.47c1.65 3.56 3.6 7.17 5.32 11.05.9 1.92 1.7 3.93 2.56 5.94s1.63 4.11 2.37 6.25c1.53 4.26 2.91 8.71 4.09 13.33a149.12 149.12 0 014.6 29.55c.3 5.19.24 10.48.13 15.83s-.32 10.79-.59 16.29c-.53 11-1.23 21.71-1.63 32.07s-.6 20.37-.61 29.9c0 19.06.76 36.21 1.76 50.59s2.2 26 3.11 34c.45 4 .84 7.12 1.1 9.22.12 1.05.22 1.84.28 2.4s.08.82.08.82 0-.27-.13-.81-.19-1.35-.33-2.39c-.29-2.11-.72-5.21-1.2-9.21-1-8-2.23-19.61-3.28-34s-1.82-31.55-1.87-50.62c0-9.54.17-19.55.58-29.92s1.09-21.09 1.62-32.08c.27-5.49.48-10.92.6-16.27s.17-10.62-.13-15.79a149.08 149.08 0 00-4.52-29.47c-1.17-4.61-2.55-9-4.06-13.29-.73-2.14-1.57-4.2-2.35-6.24s-1.65-4-2.55-5.93c-1.7-3.87-3.63-7.48-5.28-11.06a100.63 100.63 0 01-4.25-10.48c-2.28-6.82-3.4-13.5-2.23-19.19a15.1 15.1 0 013.36-7.21c1.83-1.66 3.58-3 5.15-4.33 3.15-2.52 5.69-4.35 7.47-5.51A26.34 26.34 0 01212 88.1zM127.89 326.93c.14 0 .26 23.23.26 51.87s-.12 51.87-.26 51.87-.26-23.22-.26-51.87.11-51.87.26-51.87z"
                ></path>
                <path
                    fill="#fff"
                    d="M313.61 436.14c-.15 0-.26-10.17-.26-22.71s.11-22.71.26-22.71.26 10.17.26 22.71-.12 22.71-.26 22.71zM313.61 373.82a10.53 10.53 0 010 4.65 10.53 10.53 0 010-4.65z"
                ></path>
                <path
                    fill="#455a64"
                    d="M246.2 301.09c11.32-5.47 24-7.27 36.48-8.91a46.09 46.09 0 01-61.07 37.42c2.46-12.34 13.26-23.05 24.59-28.51z"
                ></path>
                <path
                    fill="#263238"
                    d="M216.44 333s.13-.14.41-.37a12.19 12.19 0 011.26-.93c1.11-.79 2.79-1.81 4.88-3.06 4.18-2.48 10-5.74 16.53-9.31l16.61-9.18 5-2.82 1.37-.76a2.12 2.12 0 01.49-.23 2 2 0 01-.44.32l-1.32.84c-1.15.73-2.84 1.74-4.93 3-4.19 2.46-10.05 5.74-16.54 9.31s-12.38 6.78-16.6 9.18c-2.11 1.2-3.81 2.18-4.95 2.91s-1.74 1.1-1.77 1.1z"
                ></path>
                <path
                    fill="#455a64"
                    d="M245.87 172.63c10.5-5.06 22.26-6.73 33.82-8.25a42.73 42.73 0 01-56.61 34.68c2.28-11.43 12.29-21.36 22.79-26.43z"
                ></path>
                <path
                    fill="#263238"
                    d="M218.92 201.85a1.8 1.8 0 01.37-.34 12.88 12.88 0 011.14-.85c1-.72 2.55-1.66 4.46-2.81 3.81-2.26 9.17-5.24 15.1-8.51l15.17-8.34 4.58-2.57 1.25-.69a2.26 2.26 0 01.46-.21 1.85 1.85 0 01-.41.3l-1.2.78c-1 .66-2.59 1.58-4.5 2.71-3.82 2.25-9.17 5.25-15.1 8.51s-11.31 6.19-15.17 8.37c-1.92 1.1-3.48 2-4.52 2.66s-1.55 1.03-1.63.99z"
                ></path>
                <path
                    fill="#455a64"
                    d="M196.08 225.36c-8.41-4.31-17.91-5.9-27.25-7.36A34.64 34.64 0 00214 247.23c-1.59-9.31-9.51-17.56-17.92-21.87z"
                ></path>
                <path
                    fill="#263238"
                    d="M217.35 249.57a15.53 15.53 0 01-1.31-.85c-.83-.55-2.08-1.3-3.62-2.22l-12.14-7.07c-4.74-2.77-9-5.31-12.07-7.21-1.52-1-2.75-1.74-3.58-2.3a8.38 8.38 0 01-1.27-.92 9.32 9.32 0 011.37.74l3.67 2.16 12.15 7.1c4.74 2.76 9 5.28 12.06 7.2 1.52 1 2.74 1.76 3.55 2.37a9.73 9.73 0 01.91.73c.2.16.3.26.28.27z"
                ></path>
                <path
                    fill="#455a64"
                    d="M185.9 139.8c-11.19-5.73-23.83-7.85-36.25-9.78a46.08 46.08 0 0060.14 38.88c-2.16-12.39-12.7-23.36-23.89-29.1z"
                ></path>
                <path
                    fill="#263238"
                    d="M214.21 172s-.6-.44-1.73-1.16-2.76-1.75-4.8-3c-4.11-2.46-9.83-5.76-16.14-9.43s-12-7-16.07-9.57c-2-1.26-3.67-2.3-4.79-3l-1.28-.84a2 2 0 01-.42-.33 2 2 0 01.48.24l1.33.78 4.87 2.89L191.8 158c6.31 3.67 12 7 16.07 9.56 2 1.28 3.65 2.33 4.73 3.13a14.21 14.21 0 011.22 1c.27.19.4.31.39.31z"
                ></path>
                <path
                    fill="#455a64"
                    d="M188.88 327.74c-11.32-5.46-24-7.27-36.48-8.91a46.09 46.09 0 0061.07 37.42c-2.47-12.33-13.26-23.05-24.59-28.51z"
                ></path>
                <path
                    fill="#263238"
                    d="M218.84 359.76c0 .05-.62-.42-1.79-1.13s-2.86-1.71-5-2.92c-4.24-2.41-10.16-5.63-16.67-9.22s-12.4-6.88-16.61-9.35c-2.1-1.24-3.8-2.25-5-3l-1.33-.84c-.3-.2-.45-.31-.44-.33a2.8 2.8 0 01.5.24l1.5.79 5 2.83 16.66 9.17c6.51 3.59 12.4 6.86 16.6 9.35 2.1 1.25 3.78 2.28 4.9 3.07a15.65 15.65 0 011.27.94 2.87 2.87 0 01.41.4zM303.44 252.92c0 .14-36.63.26-81.81.26s-81.83-.12-81.83-.26 36.63-.26 81.83-.26 81.81.11 81.81.26zM303.1 259.62c0 .14-36.63.26-81.82.26s-81.82-.12-81.82-.26 36.63-.27 81.82-.27 81.82.12 81.82.27zM303.1 263.22c0 .15-36.63.26-81.82.26s-81.82-.11-81.82-.26 36.63-.26 81.82-.26 81.82.12 81.82.26zM302.93 269.92c0 .14-36.64.26-81.82.26s-81.82-.12-81.82-.26 36.63-.26 81.82-.26 81.82.12 81.82.26z"
                ></path>
                <path
                    fill="#455a64"
                    d="M213.32 292a4.46 4.46 0 00-1.11-2 29.1 29.1 0 00-23.29-8.42 1.33 1.33 0 00-1 .39c-.35.47 0 1.13.44 1.57a27.35 27.35 0 0024.96 8.46z"
                ></path>
                <path
                    fill="#263238"
                    d="M216 293.84c0 .06-1-.62-2.68-1.62a46.16 46.16 0 00-6.7-3.34 49.88 49.88 0 00-7.16-2.2 25.29 25.29 0 01-3-.71 2.57 2.57 0 01.84 0c.54 0 1.32.15 2.27.32a38.88 38.88 0 017.28 2.08 36.19 36.19 0 016.71 3.51c.81.54 1.44 1 1.86 1.35s.64.58.58.61z"
                ></path>
                <path
                    fill="#455a64"
                    d="M213.43 159a4.55 4.55 0 01.69-2.18 29.1 29.1 0 0121.12-12.93 1.32 1.32 0 011 .19c.44.39.19 1.11-.11 1.62a27.37 27.37 0 01-22.7 13.3z"
                ></path>
                <path
                    fill="#263238"
                    d="M210.71 161.87a3.38 3.38 0 01.51-.7c.35-.44.9-1 1.6-1.75a39.85 39.85 0 016-5 39.4 39.4 0 016.87-3.69c.92-.38 1.68-.66 2.21-.82a2.68 2.68 0 01.85-.2c0 .08-1.12.52-2.91 1.36a50 50 0 00-6.73 3.78 50.81 50.81 0 00-6 4.8 29.56 29.56 0 01-2.4 2.22z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M256.42 393.74L234.89 399.56 234.93 399.7 246.24 454.85 292.22 442.43 283.26 409.24 256.42 393.74z"
                ></path>
                <path
                    d="M256.42 393.74L234.89 399.56 234.93 399.7 246.24 454.85 292.22 442.43 283.26 409.24 256.42 393.74z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#0096FF"
                    d="M234.89 399.56L218.8 425.51 228.05 459.76 271.11 448.13 262.06 414.61 234.89 399.56z"
                ></path>
                <path
                    fill="#263238"
                    d="M242.35 455.9a7.2 7.2 0 01-.43-1.25c-.26-.9-.6-2.07-1-3.5-.85-3.08-2-7.26-3.34-12l-.07-.25.24-.06 5.33-1.45 5.34-1.43.25-.07.06.24 3.17 12.09c.36 1.45.66 2.64.88 3.54A7 7 0 01253 453a7.2 7.2 0 01-.43-1.25c-.26-.9-.6-2.07-1-3.5-.86-3.08-2-7.26-3.35-12l.31.17-5.33 1.45-5.33 1.44.13-.31 3.17 12.08c.36 1.45.66 2.64.88 3.54a7.15 7.15 0 01.3 1.28z"
                ></path>
                <path
                    fill="#fff"
                    d="M248.41 420.47H257.90999999999997V429.97H248.41z"
                    opacity="0.3"
                    transform="rotate(-15 254.33 426.225)"
                ></path>
                <path
                    fill="#fff"
                    d="M226.02 426.51H235.52V436.01H226.02z"
                    opacity="0.3"
                    transform="rotate(-15.11 230.815 431.345)"
                ></path>
                <path
                    fill="#0096FF"
                    d="M221.23 406.79H225.98V420.89000000000004H221.23z"
                    transform="rotate(-15.11 223.686 413.942)"
                ></path>
                <path
                    fill="#263238"
                    d="M234.89 399.56a1.72 1.72 0 01.38-.13l1.11-.33 4.28-1.21 15.71-4.33H256.52l26.9 15.44.54.31-.61.16-14.63 3.75-6.6 1.68h-.1l-.09-.05-27.14-15.09.29-.08c-4.88 7.79-8.92 14.26-11.77 18.8l-3.3 5.22-.89 1.37a2.49 2.49 0 01-.32.45 3.75 3.75 0 01.27-.49l.83-1.39c.75-1.25 1.83-3 3.2-5.29l11.61-18.9.11-.17.18.1 27.18 15h-.19l6.6-1.68 14.64-3.74-.06.46-26.84-15.49h.14l-15.76 4.16-4.3 1.11-1.13.28z"
                ></path>
                <path
                    d="M253.02 453.02L248.47 436.16 237.8 439.04 242.35 455.9"
                    opacity="0.3"
                ></path>
                <path
                    fill="#455a64"
                    d="M304.44 448.81a31.25 31.25 0 00-32-7.21c-7.61 2.74-14.58 8.57-22.64 7.89-4.43-.38-8.42-2.72-12.66-4a28.24 28.24 0 00-27 6.16l-3.77 12.12s102.84 3.85 102.84 0-4.77-14.96-4.77-14.96z"
                ></path>
                <path
                    fill="#263238"
                    d="M299.81 444.56a6.21 6.21 0 01-.78-.39c-.5-.27-1.24-.67-2.22-1.11a33.84 33.84 0 00-8.7-2.64 29 29 0 00-13.34.87c-4.89 1.42-9.88 4.06-15.28 6.45-2.68 1.2-5.46 2.32-8.33 2.36s-5.45-1.15-7.76-2.17a34.18 34.18 0 00-13-3.21 34.74 34.74 0 00-9.05.79c-1 .25-1.86.46-2.41.63a4.17 4.17 0 01-.84.21 7.64 7.64 0 01.81-.31c.54-.2 1.34-.45 2.39-.72a33.06 33.06 0 019.12-1 34.1 34.1 0 0113.2 3.14c2.33 1 4.82 2.16 7.57 2.1s5.45-1.13 8.12-2.33c5.38-2.37 10.39-5 15.36-6.43a28.84 28.84 0 0113.53-.77 32.53 32.53 0 018.72 2.82 22.26 22.26 0 012.19 1.2 5.54 5.54 0 01.7.51z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M156.11 432.43H193.83V448.09000000000003H156.11z"
                    transform="rotate(9.47 174.91 440.137)"
                ></path>
                <path
                    fill="#0096FF"
                    d="M148.64 431.56H206.31V434.66H148.64z"
                    transform="rotate(9.47 177.414 432.94)"
                ></path>
                <path
                    d="M188.91 434.89H206.03V437.96999999999997H188.91z"
                    opacity="0.3"
                    transform="rotate(9.47 197.443 436.265)"
                ></path>
                <path
                    d="M175.97 435.97H193.54V449.73H175.97z"
                    opacity="0.3"
                    transform="matrix(.99 .16 -.16 .99 75.36 -24.36)"
                ></path>
                <path
                    fill="#263238"
                    d="M205.72 439.38c0 .14-12.73-1.86-28.38-4.47s-28.32-4.84-28.3-5 12.73 1.86 28.38 4.47 28.33 4.85 28.3 5z"
                ></path>
                <path
                    fill="#455a64"
                    d="M149.89 443.46c5.58-.83 11.45-1.64 16.74.34 3.22 1.21 6.1 3.43 9.5 4 4 .67 8.14-1.1 12.24-.79a12.25 12.25 0 019 5.2 4.87 4.87 0 01-3.44 2.66 14.37 14.37 0 01-4.49.12c-9.09-.7-18.19-1.4-27.17-2.92a24.69 24.69 0 01-7.8-2.32 9.24 9.24 0 01-4.88-6.21"
                ></path>
                <path
                    fill="#263238"
                    d="M146.75 442s.32-.33.8-1 1.13-1.72 1.9-3c1.53-2.57 3.5-6.36 6.91-9.59a11.79 11.79 0 015.8-3 7.32 7.32 0 015.26.9 5.93 5.93 0 012.42 2.68 3.76 3.76 0 01.3.94c0 .22.05.34 0 .34s-.13-.46-.51-1.2a6.18 6.18 0 00-2.42-2.45 7.13 7.13 0 00-5-.74 11.64 11.64 0 00-5.54 3 30.24 30.24 0 00-4.12 4.94c-1.12 1.62-2 3.2-2.83 4.47a30.11 30.11 0 01-2 2.95 2.88 2.88 0 01-.97.76z"
                ></path>
                <path
                    fill="#263238"
                    d="M203.62 451.76a7.29 7.29 0 01-2.48-.05 24.07 24.07 0 01-6.33-2.35 26.87 26.87 0 00-4.28-1.71 11.88 11.88 0 00-2.5-.43 13.09 13.09 0 00-2.69.2c-1.83.29-3.72.9-5.77 1.22a11.24 11.24 0 01-3.18.09 8.81 8.81 0 01-3.11-1.05c-1.95-1.05-3.57-2.48-5.35-3.39a15.54 15.54 0 00-5.58-1.48c-3.72-.32-7.11.14-10 0a24.41 24.41 0 01-6.69-1 16.39 16.39 0 01-1.72-.64 3 3 0 01-.57-.3c0-.05.82.33 2.35.75a26.19 26.19 0 006.64.78c2.82.07 6.2-.44 10-.13a15.53 15.53 0 015.77 1.5c1.87 1 3.48 2.37 5.36 3.38a8.62 8.62 0 002.94 1 11 11 0 003-.09c2-.3 3.9-.9 5.78-1.19a14.59 14.59 0 012.78-.18 12.6 12.6 0 012.6.47A26.37 26.37 0 01195 449a25.94 25.94 0 006.21 2.48 19.16 19.16 0 002.41.28zM224.46 106.4a9.59 9.59 0 01-1.35 1c-.45.31-1 .68-1.6 1.15s-1.34 1-2.06 1.61-1.57 1.28-2.39 2.07-1.69 1.62-2.58 2.54a49.87 49.87 0 00-5.13 6.51 48.68 48.68 0 00-3.87 7.32c-.47 1.19-.92 2.32-1.24 3.41s-.63 2.09-.87 3-.4 1.8-.56 2.55-.26 1.4-.34 1.94a10.16 10.16 0 01-.3 1.66 11.15 11.15 0 01.1-1.69c0-.54.11-1.2.25-2s.24-1.64.49-2.58.45-2 .81-3.08.76-2.25 1.22-3.46a45.71 45.71 0 013.87-7.43 47.94 47.94 0 015.22-6.55c.91-.92 1.77-1.79 2.64-2.53s1.68-1.44 2.45-2 1.49-1.1 2.12-1.55 1.19-.81 1.65-1.09a11 11 0 011.47-.8z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M187 201.23a12.78 12.78 0 117-16.61 12.77 12.77 0 01-7 16.61z"
                ></path>
                <path
                    d="M177.26 192.57l-1.56.66-.59-1.4 1.52-.64a7.2 7.2 0 01-.34-3.94l2 .05a6.19 6.19 0 00.15 3.12l2.33-1c-.27-1.7-.48-3.65 1.51-4.49 1.48-.62 3.2-.08 4.38 2l1.55-.65.59 1.4-1.53.64a6.73 6.73 0 01.34 3.19h-2a6.1 6.1 0 00-.17-2.44l-2.37 1c.29 1.7.48 3.62-1.48 4.44-1.44.67-3.15.14-4.33-1.94zm5.58-4l2-.83c-.47-.67-1-.79-1.46-.6s-.6.75-.54 1.44zm-2.35 3.82c.49-.21.58-.72.54-1.41l-1.93.81c.45.64.9.79 1.39.61z"
                    opacity="0.3"
                ></path>
                <path
                    d="M186.85 197.92c0-.06.36-.26 1-.68a10.42 10.42 0 002.35-2.16 9.91 9.91 0 001.15-1.85 9.5 9.5 0 00.78-2.38 9 9 0 00.11-2.75 9.08 9.08 0 00-.26-1.44 10.71 10.71 0 00-.49-1.41 11.21 11.21 0 00-.71-1.33 10.38 10.38 0 00-.88-1.16 9.29 9.29 0 00-1-1 8.24 8.24 0 00-1.1-.79 9.56 9.56 0 00-2.27-1 10 10 0 00-2.16-.4 10.41 10.41 0 00-3.17.27 6.5 6.5 0 01-1.17.28 2.83 2.83 0 011.09-.55 9 9 0 013.28-.53 10 10 0 014.76 1.38 9.12 9.12 0 011.19.83 9.76 9.76 0 011.1 1 10.88 10.88 0 011 1.25 10.32 10.32 0 01.77 1.43 10.65 10.65 0 01.53 1.53A9.32 9.32 0 01193 188a11.05 11.05 0 010 1.52 9.79 9.79 0 01-.2 1.44 10.14 10.14 0 01-.89 2.52 9.7 9.7 0 01-1.31 1.91 9.1 9.1 0 01-2.6 2.07 2.88 2.88 0 01-1.15.46z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M186 198.34a23.81 23.81 0 002.05-1.26 9.89 9.89 0 003.62-5.26 9.38 9.38 0 00-2.12-8.91 9.06 9.06 0 00-2.13-1.74 9.41 9.41 0 00-2.66-1.08 9.72 9.72 0 00-10.51 4.24 9.16 9.16 0 00-1.17 2.62 8.79 8.79 0 00-.34 2.72 9.43 9.43 0 004.65 7.9 10 10 0 006.26 1.29 23.82 23.82 0 002.35-.52 7.92 7.92 0 01-2.32.72 9.88 9.88 0 01-6.47-1.17 9.72 9.72 0 01-3.36-3.15 9.83 9.83 0 01-1.59-5.05 9.4 9.4 0 01.34-2.87 9.57 9.57 0 011.21-2.77 10.31 10.31 0 014.82-4 10.2 10.2 0 016.25-.44 9.28 9.28 0 012.79 1.15 9.06 9.06 0 012.23 1.83 9.74 9.74 0 012.12 9.34 9.87 9.87 0 01-3.85 5.33 7.65 7.65 0 01-2.17 1.08z"
                ></path>
                <path
                    fill="#fff"
                    d="M170.15 190.69a9.62 9.62 0 012.84-9.22 23.39 23.39 0 00-2.16 4.38 22.57 22.57 0 00-.68 4.84zM170.45 192.83c-.15 0-.31-.2-.37-.5s0-.57.15-.6.3.2.36.5 0 .57-.14.6z"
                ></path>
                <path
                    fill="#455a64"
                    d="M173.48 200.88c8 3.21 18 2.78 25-2.16-3.67-2.95-8.58-5.59-13.25-4.95a17.56 17.56 0 00-11.75 7.11z"
                ></path>
                <path
                    fill="#455a64"
                    d="M187 176.5a26.32 26.32 0 0111.62 21.77l-.2.74A17.34 17.34 0 01187 176.5z"
                ></path>
                <path
                    fill="#263238"
                    d="M218.71 216.61a7.29 7.29 0 01-.75-1.25 35.93 35.93 0 00-2.17-3.27 33.32 33.32 0 00-3.91-4.3c-1.6-1.5-3.49-3-5.48-4.59a50.81 50.81 0 00-10.88-7c-1.51-.68-2.77-1.16-3.65-1.46a10 10 0 01-1.36-.5 7 7 0 011.42.3 30 30 0 013.73 1.33 45.9 45.9 0 0111.06 6.92c2 1.59 3.89 3.12 5.49 4.65a31.86 31.86 0 013.88 4.43 28.09 28.09 0 012 3.4 7.7 7.7 0 01.62 1.34z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M238.65 248.08a6.83 6.83 0 116.31 7.3 6.82 6.82 0 01-6.31-7.3z"
                ></path>
                <path
                    d="M245.1 245.45l.06-.91.8.05-.05.88a3.86 3.86 0 012 .77l-.51 1a3.15 3.15 0 00-1.53-.67l-.08 1.35c.88.27 1.87.63 1.8 1.78 0 .86-.72 1.56-2 1.63l-.06.89h-.81l.06-.88a3.66 3.66 0 01-1.61-.6l.46-1a3.28 3.28 0 001.21.5l.08-1.37c-.88-.27-1.85-.63-1.78-1.77.03-.89.69-1.58 1.96-1.65zm.6 3.62l-.07 1.14c.43-.07.62-.29.63-.56s-.21-.45-.56-.58zm-1.28-2c0 .28.21.45.55.59l.07-1.12c-.41.04-.6.24-.62.46z"
                    opacity="0.3"
                ></path>
                <path
                    d="M240.27 248.79s0 .23.1.63a5.44 5.44 0 00.48 1.64 5.11 5.11 0 00.61 1 5.31 5.31 0 001 .93 4.22 4.22 0 00.61.39 5.29 5.29 0 00.68.32 5.9 5.9 0 00.75.21 7 7 0 00.8.1 5.3 5.3 0 00.8 0 4.77 4.77 0 00.77-.15 5.41 5.41 0 00.71-.25 5.12 5.12 0 001.67-1.19 5.2 5.2 0 00.7-.94 5.3 5.3 0 00.62-1.59 3.87 3.87 0 01.14-.62s.06.23 0 .65a4.78 4.78 0 01-.52 1.7 6 6 0 01-.7 1 5.53 5.53 0 01-1.08.94 7.37 7.37 0 01-.68.37 4.93 4.93 0 01-.76.28 4.7 4.7 0 01-.83.17 6.22 6.22 0 01-.87 0 6.12 6.12 0 01-.86-.1 5.29 5.29 0 01-.8-.24 3.86 3.86 0 01-.74-.35 4 4 0 01-.64-.43 5.34 5.34 0 01-1-1 5.65 5.65 0 01-.61-1.08 4.89 4.89 0 01-.38-1.74 1.5 1.5 0 01.03-.65z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M240.26 248.3a10.73 10.73 0 00.11 1.28 5.28 5.28 0 001.67 3 5.06 5.06 0 004.8 1.09 5.22 5.22 0 00.72-9.72 5.05 5.05 0 00-4.91.38 5.23 5.23 0 00-2.09 2.71 10.62 10.62 0 00-.3 1.26 4.36 4.36 0 01.22-1.28 5.26 5.26 0 012.09-2.81 5.17 5.17 0 015.07-.43 5.4 5.4 0 01-.75 10.08 5.25 5.25 0 01-6.6-4.27 4.25 4.25 0 01-.03-1.29z"
                ></path>
                <path
                    fill="#fff"
                    d="M247.68 242.48a2.21 2.21 0 01.75.23 5.8 5.8 0 012.73 2.57 2.29 2.29 0 01.28.74 9.7 9.7 0 00-1.57-2.1 9.82 9.82 0 00-2.19-1.44zM246.59 242.12c0-.06.15-.07.31 0s.28.13.26.18-.15.07-.31 0-.28-.13-.26-.18z"
                ></path>
                <path
                    fill="#455a64"
                    d="M243.66 240.09a16.24 16.24 0 00-7.66 13.16l.11.46a10.72 10.72 0 007.57-13.62z"
                ></path>
                <path
                    fill="#455a64"
                    d="M251.41 254.57a16.26 16.26 0 01-15.22-.8l-.33-.35a10.72 10.72 0 0115.55 1.15z"
                ></path>
                <path
                    fill="#263238"
                    d="M239.15 251.44a11.77 11.77 0 01-1.4 1c-.47.32-1 .71-1.65 1.21s-1.36 1-2.11 1.68-1.59 1.35-2.43 2.18-1.71 1.72-2.62 2.67a50.32 50.32 0 00-9.19 14.41c-.47 1.23-1 2.39-1.3 3.51-.18.56-.37 1.1-.52 1.62l-.4 1.52c-.28 1-.42 1.85-.59 2.63s-.28 1.45-.36 2a10 10 0 01-.31 1.72 13.15 13.15 0 01.11-1.74 19.81 19.81 0 01.27-2c.14-.79.26-1.7.52-2.67.12-.49.25-1 .38-1.54s.32-1.07.49-1.64c.32-1.14.8-2.31 1.27-3.56a52 52 0 014-7.7 52.54 52.54 0 015.3-6.85c.92-1 1.78-1.89 2.68-2.67s1.7-1.52 2.49-2.15 1.52-1.16 2.17-1.63a18.68 18.68 0 011.71-1.14 9.88 9.88 0 011.49-.86z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M202 101.82a27.13 27.13 0 1120.5-32.38 27.1 27.1 0 01-20.5 32.38z"
                ></path>
                <path
                    d="M184.55 80.31l-3.5.83-.75-3.14 3.41-.81a15.18 15.18 0 01.65-8.38l4.22.81a13 13 0 00-.77 6.59L193 75c0-3.68.25-7.83 4.73-8.9 3.31-.79 6.73.94 8.47 5.75l3.48-.83.74 3.13-3.41.81a14.65 14.65 0 01-.39 6.81l-4.22-.65a13.16 13.16 0 00.48-5.17l-5.28 1.25c0 3.66-.24 7.75-4.65 8.81-3.25.77-6.63-.93-8.4-5.7zm13.08-6.43l4.44-1.06c-.77-1.57-1.83-2-2.86-1.76s-1.47 1.34-1.58 2.82zm-6.25 7.18c1.09-.26 1.47-1.31 1.62-2.77l-4.32 1c.72 1.51 1.7 2.01 2.7 1.77z"
                    opacity="0.3"
                ></path>
                <path
                    d="M202.81 94.83c0-.13.83-.41 2.3-1.08a21.64 21.64 0 005.67-3.75 21.31 21.31 0 003.07-3.49 20.51 20.51 0 002.44-4.71 18.86 18.86 0 00.8-2.77 21.75 21.75 0 00.4-3 20.13 20.13 0 00-.06-3.1 21.49 21.49 0 00-.54-3.14 22.37 22.37 0 00-1-3 20.32 20.32 0 00-1.44-2.74 19 19 0 00-1.78-2.41 19.48 19.48 0 00-2-2 20.7 20.7 0 00-4.42-3 21.28 21.28 0 00-4.37-1.58 21.94 21.94 0 00-6.75-.52 12.62 12.62 0 01-2.54.17A6 6 0 01195 54a18.83 18.83 0 017.06 0 20.5 20.5 0 019.5 4.52 21.35 21.35 0 012.22 2.15 21.22 21.22 0 013.52 5.52 20.82 20.82 0 011.7 6.68 20.73 20.73 0 010 3.34 21.07 21.07 0 01-1.37 6.14 20.81 20.81 0 01-6.15 8.54 19.28 19.28 0 01-6.17 3.44 6.07 6.07 0 01-2.5.5z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M201 95.43a1.41 1.41 0 01.32-.11l.95-.29a10.79 10.79 0 001.52-.53 15.54 15.54 0 002-.89 20.2 20.2 0 004.9-3.43 20.62 20.62 0 006.58-15.67A20.34 20.34 0 00214 64.16a21.79 21.79 0 00-1.76-2.35 22.4 22.4 0 00-2.24-2.08 19.46 19.46 0 00-5.26-3.24 20.5 20.5 0 00-6.19-1.57 20.85 20.85 0 00-12.7 2.83 20.53 20.53 0 00-4.85 4.06 19.65 19.65 0 00-3.44 5.19 18.24 18.24 0 00-1.66 5.68A20.14 20.14 0 00183.06 91a20.69 20.69 0 006.94 3.8 20.12 20.12 0 005.89 1 17.1 17.1 0 002.19 0 12.09 12.09 0 001.6-.17l1-.15a1.38 1.38 0 01.33 0 1.13 1.13 0 01-.32.09l-1 .19a9.81 9.81 0 01-1.6.22 15 15 0 01-2.21.09 20 20 0 01-6-.94 20.58 20.58 0 01-14.45-22.49 23.54 23.54 0 01.59-2.94 23.93 23.93 0 011.09-2.88 20.07 20.07 0 013.46-5.3 20.87 20.87 0 015.06-4.16 22.7 22.7 0 016.27-2.49 23.2 23.2 0 016.73-.42A20.84 20.84 0 01205 56a19.81 19.81 0 015.38 3.32 22.37 22.37 0 012.21 2.14A20.54 20.54 0 01215.62 84a20.85 20.85 0 01-4.77 6.43 19.93 19.93 0 01-5 3.39 15.85 15.85 0 01-2 .86 10.44 10.44 0 01-1.55.48l-1 .24a1.37 1.37 0 01-.3.03z"
                ></path>
                <path
                    fill="#fff"
                    d="M170.3 73.91a2.55 2.55 0 01-.05-.84 15.83 15.83 0 01.18-2.26A23.65 23.65 0 01177 57.54a17.69 17.69 0 011.7-1.51c.43-.32.67-.49.7-.46s-.86.83-2.14 2.23a27.2 27.2 0 00-4.17 6.08 27.57 27.57 0 00-2.34 7c-.3 1.85-.36 3.03-.45 3.03zM170.18 78.5c-.14 0-.28-.52-.3-1.18s.08-1.19.23-1.2.27.53.29 1.18-.08 1.19-.22 1.2z"
                ></path>
            </g>
            <g>
                <path
                    fill="#263238"
                    d="M386.27 152.45c-1-2.08-2.38-4-2.81-6.31-.5-2.57.24-5.44-1.08-7.7-1.55-2.69-5.14-3.2-7.78-4.82-4.32-2.65-2.9-16.4-13.35-20.91l-3.62 20.48q-.09 20.24-1 40.46a196.84 196.84 0 0020.18.74 14 14 0 005.27-.79c3.1-1.3 4.93-4.59 5.69-7.87a20.28 20.28 0 00-1.5-13.28z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M363.1 145.93l-12.26 26.69-3.1-17.47-2.14 3.54s-3.63 23.77-2.76 28c1.13 5.51 5.36 9.94 5.36 9.94l4.31 20.57c.32 2.19 2.31 3.74 3.22 3.45L386.05 206c1.7-1.27 1.67-2.87.74-5l-1.73-3.88-.55-24.76c0-1.51-.52-13.56-1.59-18.17a11.36 11.36 0 00-7-8.1c-2.44-.9-9.52-2.04-12.82-.16z"
                ></path>
                <path
                    fill="#263238"
                    d="M356.79 130.21l-28.5-17.91c-3.69 9.14-.59 19.91.61 22.88s2.68 6.08 2.06 9.22c-.43 2.23-1.86 4.12-2.7 6.22a12.31 12.31 0 00.28 9.62c.95 2 2.5 4 2.39 6.2s-2.05 4.27-2.41 6.6c-.39 2.53 1.17 5 3 6.79a7.36 7.36 0 004.61 2.61c2 .1 3.78-1.16 5.1-2.63 3-3.33 4.29-7.82 5-12.23s1-8.92 2.39-13.19c1.31-4.1 3.56-7.83 5.39-11.73s3.26-8.17 2.78-12.45z"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M435.4 286.33L455.79 263.65 446.45 254.28 422.33 274.86 435.4 286.33z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M453.18 266.72l.64-.62s14.7 6.5 16.84 4.64l-20.79-21.1-6.74 7.2a17 17 0 0110.05 9.88z"
                ></path>
                <path
                    fill="#fff"
                    d="M447.48 256.77a1.22 1.22 0 01.4-1.57 1.16 1.16 0 011.55.38 1.28 1.28 0 01-.43 1.66 1.21 1.21 0 01-1.57-.58M449.87 249.64l-1.62 1.73L468.76 271s1.34.54 1.9-.22z"
                    opacity="0.6"
                ></path>
                <path
                    fill="#263238"
                    d="M454 266.12c.1-.11-.36-.67-.53-1.5s0-1.53-.14-1.58-.58.68-.35 1.69.89 1.51 1.02 1.39zM457 267.43c.13-.07-.14-.69-.05-1.47s.45-1.34.35-1.43-.75.41-.85 1.38.49 1.62.55 1.52zM460.93 266.08c-.07-.12-.73.25-1 1.07s0 1.52.08 1.48.13-.64.39-1.31.6-1.13.53-1.24zM370.18 158.62c.07 0-.19-.82-.39-2.14a15.2 15.2 0 01.17-5.18 8.18 8.18 0 012.28-4.54 4.89 4.89 0 011.88-1 1.22 1.22 0 00-.6 0 3.66 3.66 0 00-1.47.71 8 8 0 00-2.49 4.68 13.36 13.36 0 00-.05 5.31 6.47 6.47 0 00.67 2.16z"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M375.07 354.74L378.32 385.67 391.79 385 392.69 352.68 375.07 354.74z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M377.78 381.6v.9s-15 6.69-15 9.58l30.22-1.32-.85-10a17.28 17.28 0 01-14.37.84z"
                ></path>
                <path
                    fill="#263238"
                    d="M373.19 387.4c.12 0 .27-.64-.1-1.33s-1-1-1-.85.34.48.63 1 .34 1.17.47 1.18z"
                ></path>
                <path
                    fill="#fff"
                    d="M389.21 384.08a1.23 1.23 0 01.91 1.37 1.19 1.19 0 01-1.35.92 1.31 1.31 0 01-1-1.46 1.23 1.23 0 011.51-.8M393 390.76l-.2-2.41-28.85 2.15s-1.32.64-1.13 1.58z"
                    opacity="0.6"
                ></path>
                <path
                    fill="#263238"
                    d="M377.71 382.59c0 .15.75.18 1.49.62s1.16 1.05 1.3 1-.13-.9-1-1.42-1.83-.34-1.79-.2zM374.6 384c0 .14.62.36 1.15 1s.7 1.25.85 1.24.2-.85-.47-1.59-1.53-.75-1.53-.65zM435.33 263.68l-22.24 16.62s-7.18-21.89-9.2-34.57c0 0 2.59-11.09-1.57-19.09-5.32-9.14-11.26-13-11.26-13l-5-7.68-30.32 14.72 7.43 24 1.33 70.45 12.2 59.7 16-.06S394.17 340 390 327s-2.91-19.33-2.91-19.33l2.89-30c9.89 38 25.32 28.74 30.55 25.06 8.3-5.83 26-28.39 26-28.39z"
                ></path>
                <path
                    fill="#455a64"
                    d="M361.24 218.58a3.75 3.75 0 00.68.66 9.81 9.81 0 00.87.69 12.26 12.26 0 001.22.82 14.2 14.2 0 001.57.86 17.39 17.39 0 001.88.82 20.46 20.46 0 009.26 1.19 19.08 19.08 0 002-.31 15.52 15.52 0 001.73-.44 13.71 13.71 0 001.39-.49 8.69 8.69 0 001-.45 4.13 4.13 0 00.83-.46c0-.08-1.24.55-3.31 1.12a17 17 0 01-1.72.39c-.62.13-1.29.19-2 .27a24 24 0 01-4.61-.1 23.72 23.72 0 01-4.48-1.07c-.67-.26-1.3-.48-1.87-.77a15 15 0 01-1.56-.81c-1.83-1.07-2.83-1.98-2.88-1.92z"
                ></path>
                <path
                    fill="#263238"
                    d="M363.16 115.6c-2.91-4.81-6.82-9.42-12.15-11.19-4.75-1.58-10.7-.84-15.34 1a17.77 17.77 0 00-6.13 4.81c-2.2 2.56-2.23 6.16-2.53 9.53-.6 6.74 2.56 14.45 5.15 16l28.76 4.08c3.47-3.77 7-8.48 6.49-13.57-.41-3.44-2.41-7.68-4.25-10.66z"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M350.77 109.4l-16.83 1c-5.15 1.05-6.53 8.24-5.27 13.35 1.41 5.67 3.35 12.54 5.32 16.48 3.95 7.93 10.69 7 10.69 7s.06.12.89 2.58l2 6.4 2.7 19.15 12.73-27.2-8.71-36.61a3.08 3.08 0 00-3.52-2.15z"
                ></path>
                <path
                    fill="#263238"
                    d="M332.08 129.6a1.13 1.13 0 001.38.75 1.07 1.07 0 00.79-1.31 1.12 1.12 0 00-1.37-.76 1.08 1.08 0 00-.8 1.32zM331.27 126.8c.17.1.79-.76 2-1.12s2.18-.05 2.26-.23-.18-.29-.63-.44a3 3 0 00-1.86 0 2.93 2.93 0 00-1.5 1.06c-.32.4-.36.69-.27.73zM343.27 126.15a1.13 1.13 0 001.38.75 1.08 1.08 0 00.8-1.31 1.14 1.14 0 00-1.38-.76 1.09 1.09 0 00-.8 1.32zM342.66 123.44c.17.1.8-.76 1.95-1.12s2.18 0 2.26-.23-.17-.29-.63-.44a3.08 3.08 0 00-1.86 0 2.94 2.94 0 00-1.5 1.07c-.27.39-.31.68-.22.72zM341.17 133.3a8.66 8.66 0 00-2 .26c-.3.06-.6.09-.71-.1a1.56 1.56 0 01-.08-.93c.05-.8.11-1.64.17-2.51a40.83 40.83 0 00.1-6.48A41.78 41.78 0 00338 130q-.06 1.32-.12 2.52a1.8 1.8 0 00.21 1.2.77.77 0 00.62.28 1.8 1.8 0 00.52-.09 7.72 7.72 0 001.94-.61z"
                ></path>
                <path
                    fill="#eb996e"
                    d="M344.68 147.19a22.28 22.28 0 0010.24-6.51s-.93 6.71-9.53 8.57zM345.66 134.25a2 2 0 00-1.81-.35 2.54 2.54 0 00-1.85 1.78 1.57 1.57 0 00.25 1.11c.13.16 0 .09.23.17a4.39 4.39 0 002 .52 1.89 1.89 0 001.69-1.3 2 2 0 00-.51-1.93z"
                ></path>
                <path
                    fill="#263238"
                    d="M344.81 133c-.19.05.2 1.3-.59 2.53s-2.16 1.5-2.11 1.68.37.15.94 0a3.34 3.34 0 001.79-1.34 2.83 2.83 0 00.41-2.09c-.12-.52-.35-.78-.44-.78zM341.3 121c.21.28 1.31-.23 2.72-.5s2.61-.24 2.7-.57c0-.16-.25-.4-.78-.56a4.77 4.77 0 00-4.13.82c-.44.3-.61.63-.51.81zM330.43 122.69c.28.19 1-.33 1.94-.64s1.82-.38 1.92-.71c0-.16-.17-.39-.6-.52a3 3 0 00-3.11 1.09c-.26.37-.28.68-.15.78z"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M355.37 122.41c.09-.09 4.15-3 5.61 1.68s-3.52 5.17-3.57 5-2.04-6.68-2.04-6.68z"
                ></path>
                <path
                    fill="#eb996e"
                    d="M358.28 126.91h.26a.83.83 0 00.61-.18 2.06 2.06 0 00.36-2.09 2.73 2.73 0 00-.59-1.09 1 1 0 00-.8-.43.42.42 0 00-.41.37v.25c0 .02-.12-.05-.15-.25a.5.5 0 01.1-.35.59.59 0 01.41-.26 1.17 1.17 0 011.07.46 2.83 2.83 0 01.69 1.21 2.19 2.19 0 01-.55 2.38.89.89 0 01-.78.11c-.17-.04-.23-.12-.22-.13z"
                ></path>
                <path
                    fill="#263238"
                    d="M330.51 110.45a11.92 11.92 0 00-3.35 7.22 18.59 18.59 0 001 8l.55-.49a7.69 7.69 0 012.15-4.08c1.06-1.16 2.28-2.14 3.33-3.31a14.69 14.69 0 003.72-9.83 8.8 8.8 0 00-7.4 2.49z"
                ></path>
                <path
                    fill="#263238"
                    d="M351.79 107.7c-4.49-2.16-11.57-1.86-15.56 1.12 1.93 3.69 6.31 7.14 9.87 9.3s6.88 7 12 3.32c.35-.25.92 0 1.2-.36a1.49 1.49 0 00.27-1 14 14 0 00-7.78-12.38z"
                ></path>
                <path
                    fill="#fff"
                    d="M346 179.87a57.75 57.75 0 01-7.35 1.46 58 58 0 01-7.43 1 58.89 58.89 0 017.35-1.45 59.27 59.27 0 017.43-1.01z"
                ></path>
                <path
                    fill="#263238"
                    d="M366.16 148.45c.11.1-6.29 7.46-14.29 16.44S337.3 181.08 337.19 181s6.29-7.45 14.29-16.44 14.57-16.2 14.68-16.11z"
                ></path>
                <path
                    fill="#455a64"
                    d="M404.71 236.42a1.49 1.49 0 01.07.46 10 10 0 01.08 1.32 22.37 22.37 0 01-.55 4.85 48.51 48.51 0 01-6.23 14.79c-.84 1.33-1.76 2.56-2.57 3.78a25.2 25.2 0 00-2.08 3.66 33.5 33.5 0 00-2.2 6.81c-.43 2-.69 3.63-.86 4.77a10.51 10.51 0 01-.32 1.75 13 13 0 01.12-1.78c.11-1.14.32-2.8.7-4.82a32.56 32.56 0 012.12-6.92 24.57 24.57 0 012.09-3.75c.82-1.24 1.73-2.47 2.56-3.78A51.48 51.48 0 00404 243a24.31 24.31 0 00.7-4.77c.01-1.17-.04-1.81.01-1.81z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M349.46 172.7L307.93 236.96 318.93 243.14 371.98 174.5 349.46 172.7z"
                ></path>
                <path
                    fill="#263238"
                    d="M345.07 160.6a2.09 2.09 0 00-.14.37c-.08.29-.19.64-.31 1.07-.27.93-.64 2.29-1.05 4a55.19 55.19 0 00-1.85 13.42 27 27 0 003.28 13.05 18.18 18.18 0 002.41 3.34c.31.37.61.61.79.8a1.35 1.35 0 00.3.25s-.35-.41-1-1.15a20.58 20.58 0 01-2.28-3.38 27.61 27.61 0 01-3.12-12.94 59.6 59.6 0 011.72-13.35c.38-1.64.69-3 .92-4 .1-.43.18-.79.24-1.09a1.78 1.78 0 00.09-.39zM351.42 184.76s.1.22.35.57a5.92 5.92 0 00.48.61 7.94 7.94 0 00.71.76 13.51 13.51 0 002.1 1.68 14.93 14.93 0 003 1.46 15.17 15.17 0 003.23.73 13.61 13.61 0 002.7 0 10.57 10.57 0 001.78-.32 2.39 2.39 0 00.63-.22c0-.08-.93.17-2.43.25a14.43 14.43 0 01-2.64-.1 17.19 17.19 0 01-3.13-.74 16.73 16.73 0 01-2.91-1.39 15.22 15.22 0 01-2.11-1.58c-1.18-.99-1.7-1.76-1.76-1.71z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M296.78 203.6c4.42-.79 45.69-6.67 45.69-6.67a14.74 14.74 0 003.67-2.09c9.27-7 35.14-27.58 36.71-32.76 1.82-6-.2-12.49-5-15.08-3.66-2-7.39-3.39-15.21 5.62S337.19 181 337.19 181l-40.78 10z"
                ></path>
                <path
                    fill="#263238"
                    d="M355.73 220.67s-.13.06-.38.1a2 2 0 01-1.09-.15c-1-.39-1.72-1.73-1.95-3.38-.48-2.11-1.05-4.77-1.66-7.65-.75-3.57-1.4-6.8-1.84-9.15-.22-1.17-.39-2.12-.5-2.78a3.48 3.48 0 01-.11-1 4.33 4.33 0 01.31 1c.17.65.39 1.58.66 2.75.54 2.32 1.24 5.54 2 9.11.61 2.88 1.15 5.55 1.56 7.68a4.11 4.11 0 001.64 3.21 3.84 3.84 0 001.36.26z"
                ></path>
                <path
                    fill="#455a64"
                    d="M413 293a3.88 3.88 0 01-.4-1.59 17 17 0 01.05-3.94c.34-3.08 1.38-5.41 1.54-5.34s-.69 2.38-1 5.4a26.79 26.79 0 00-.2 3.85A7.61 7.61 0 01413 293z"
                ></path>
                <path
                    fill="#263238"
                    d="M358.76 128.88c-1.05 1.3-.74 1.79-.26 3.39s1.21 3.19 1.09 4.86c-.19 2.62-2.45 4.79-2.45 7.42 0 1.94 1.24 3.74 1.13 5.67-.13 2.26-2 4-3.5 5.65s-4 6.63-2.83 8.55c4-4.17 9.43-9.5 12.87-13.65s6-9.45 5.25-14.78z"
                ></path>
                <path
                    fill="#455a64"
                    d="M382.32 150.71a9.38 9.38 0 01.11-1.21 9.47 9.47 0 00-.28-3.25 6.72 6.72 0 00-.92-2 7.12 7.12 0 00-1.8-1.79 18.88 18.88 0 00-5.52-2.17 16.82 16.82 0 01-5.64-2.34 7.62 7.62 0 01-2.83-4.09 11.9 11.9 0 01-.48-3.32 4.25 4.25 0 01.08-1.23 8.34 8.34 0 01.12 1.22 13.62 13.62 0 00.63 3.22 7.44 7.44 0 002.77 3.82 16.85 16.85 0 005.49 2.22 18.46 18.46 0 015.66 2.28 7.34 7.34 0 011.88 1.94 7.18 7.18 0 01.92 2.17 8.52 8.52 0 01.12 3.36 4.06 4.06 0 01-.31 1.17zM364.28 115.08a9 9 0 01-1-.5 17.94 17.94 0 00-3-1 35.83 35.83 0 00-4.61-.75 51.19 51.19 0 01-5.73-.64 27.1 27.1 0 01-9.7-4 23.51 23.51 0 01-2.53-1.93c-.56-.51-.85-.8-.82-.83s1.28 1.06 3.55 2.45a29.17 29.17 0 009.6 3.76c2 .43 3.91.45 5.69.67a31.8 31.8 0 014.64.88 13.5 13.5 0 013 1.14 5.42 5.42 0 01.73.48c.11.19.19.26.18.27z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M262.88 230.79a21.58 21.58 0 00-2.84-1.2 21.93 21.93 0 01-4.68-2.56v.59a12.24 12.24 0 004.86 7.65 5.45 5.45 0 002 1A2.32 2.32 0 00265 233a4.45 4.45 0 00-2.12-2.21z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M272.37 196.5s12.07-10.73 17.72.35c0 0 1.74 2-.76 8s-7.67 16.44-7.67 16.44l-1.59-3.6s6.83-12.81 7.59-15.41.63-4.77.07-5.92c-.68-1.38-2.51-3.56-6.21-2.37-7.4 2.4-7.68 6.35-7.68 6.35z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M287.16 233.18s-11.44 11.19-27.77 8.48l-7.8-38.66 20.69-7.38z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M260.24 236l-21.61-11.19a10.87 10.87 0 01-4.31 2.45l5.14-10.52a10.2 10.2 0 01.43 4.92l17.9 7.63z"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M296.23 193.46L288 190l-9-.69s-.75 2.22 2.5 2.4l3.9.53.75 1.86s4 5.62 8.88 5.06z"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M296.45 192.44l-6.7-.33-4.25.14c-1.58.45-2.87 1.41-4.84 2a6.81 6.81 0 00-1.72.77 1.73 1.73 0 00-.85 1.55 1.07 1.07 0 001.34.91 2.23 2.23 0 001.73 2.65 1.19 1.19 0 00.74 1.79c.24 0 .51 0 .71.15s.22.58.31.89c.23.8 1.27 1 2.09.86a7.94 7.94 0 012.43-.44c.87.12 1.58.78 2.43 1a4 4 0 003.35-1.3 10.5 10.5 0 013.48-2.15z"
                ></path>
                <path
                    fill="#263238"
                    d="M259.46 241.56a9.48 9.48 0 01-.41-1.5c-.23-1.06-.55-2.44-.92-4.11-.76-3.48-1.77-8.29-2.86-13.61s-2-10.14-2.71-13.64c-.3-1.68-.56-3.07-.76-4.14a9.43 9.43 0 01-.21-1.54 8.3 8.3 0 01.41 1.49c.24 1.07.55 2.45.93 4.12.75 3.47 1.77 8.29 2.85 13.61s2 10.14 2.71 13.64c.31 1.68.57 3.07.76 4.15a8.05 8.05 0 01.21 1.53zM287.16 233.18c-.13 0-3.57-8.31-7.68-18.67s-7.33-18.81-7.2-18.87 3.57 8.31 7.68 18.68 7.33 18.81 7.2 18.86z"
                ></path>
                <path
                    fill="#263238"
                    d="M272.51 195.93a3.27 3.27 0 01-.56.72 17.17 17.17 0 01-1.8 1.74 20.9 20.9 0 01-3 2.16 24.71 24.71 0 01-4.13 1.92 24.36 24.36 0 01-4.44 1.07 21.76 21.76 0 01-3.72.2 16.21 16.21 0 01-2.49-.25 3.19 3.19 0 01-.89-.21c0-.1 1.3.11 3.39.09a25.61 25.61 0 003.64-.3 28 28 0 004.29-1.07 27.48 27.48 0 004.07-1.84 25.16 25.16 0 003.05-2c1.64-1.34 2.52-2.3 2.59-2.23zM239.46 216.78a4.37 4.37 0 01.18 1.82 10.78 10.78 0 01-1.14 4.27 11.73 11.73 0 01-2.68 3.51 4.44 4.44 0 01-1.52 1 19.84 19.84 0 003.73-4.77 12.7 12.7 0 001.24-4.07 13.15 13.15 0 01.19-1.76z"
                ></path>
                <path
                    fill="#455a64"
                    d="M230.78 222.24a2.4 2.4 0 00-1.9.53 1.21 1.21 0 000 1.78c.55.39 1.31.08 1.89-.27a19.14 19.14 0 002.31-1.71 14.12 14.12 0 00-2.3-.33zM226.72 220.27a1.85 1.85 0 00-1.46.4.93.93 0 000 1.37c.42.3 1 .06 1.45-.21a15 15 0 001.77-1.31 10.32 10.32 0 00-1.76-.25zM234.21 217.4a3.41 3.41 0 00-2.28-1.58 1.71 1.71 0 00-1.92 1.62c.08.94 1.1 1.48 2 1.79a27.68 27.68 0 003.92 1 18.86 18.86 0 00-1.72-2.83z"
                ></path>
                <path
                    fill="#fff"
                    d="M368.06 176.48a28.94 28.94 0 01-1.6-2.42 36.24 36.24 0 00-9.62-9.92 28.56 28.56 0 01-2.36-1.67 11.75 11.75 0 012.57 1.37 30.17 30.17 0 019.73 10 11 11 0 011.28 2.64zM380.13 165.09c-.07 0-.66-1-1.76-2.58a37 37 0 00-4.88-5.68 34.15 34.15 0 00-6-4.5c-1.66-1-2.75-1.47-2.71-1.56a2.82 2.82 0 01.81.26 19 19 0 012.08 1 28.84 28.84 0 016.15 4.44 30.72 30.72 0 014.82 5.85 20.72 20.72 0 011.14 2 2.57 2.57 0 01.35.77zM353.17 189a67.58 67.58 0 00-5.12-7c-3.2-3.58-6.44-5.82-6.33-6a10.55 10.55 0 012.15 1.41 31.7 31.7 0 014.57 4.21 29.27 29.27 0 013.63 5 9.16 9.16 0 011.1 2.38zM329.36 198.54a10.8 10.8 0 01-.23-2.21c-.11-1.58-.23-3.37-.36-5.35s-.32-3.77-.45-5.34a10.62 10.62 0 01-.11-2.22 8.65 8.65 0 01.47 2.18c.22 1.36.45 3.25.61 5.34s.21 4 .21 5.37a9 9 0 01-.14 2.23zM308.14 202.41a7.4 7.4 0 01-.43-2.06 43.48 43.48 0 01-.4-5 41.86 41.86 0 01.17-5.07 8 8 0 01.33-2.07c.16 0-.08 3.2 0 7.12s.48 7.06.33 7.08zM327.23 232a43.85 43.85 0 01-6.09-3 43.32 43.32 0 01-5.85-3.46 44.61 44.61 0 016.09 3 45.94 45.94 0 015.85 3.46zM338.23 217.5a48.49 48.49 0 01-6-3.74 48.33 48.33 0 01-5.72-4.18 94.06 94.06 0 0111.72 7.92zM348.14 204.09c-.11.1-1.76-1.57-4-3.4s-4.16-3.14-4.08-3.27a23.08 23.08 0 018.06 6.67zM385.12 198a6.6 6.6 0 01-1.31.55l-3.62 1.34c-3.06 1.12-7.28 2.67-11.92 4.44s-8.83 3.44-11.86 4.64l-3.59 1.42a6.43 6.43 0 01-1.34.46 8.08 8.08 0 011.26-.64c.83-.39 2-.93 3.53-1.58 3-1.29 7.16-3 11.81-4.79s8.9-3.27 12-4.3c1.55-.51 2.8-.92 3.68-1.18a8.34 8.34 0 011.36-.36zM384.59 186.11a8.72 8.72 0 01-1.36.59c-.89.35-2.19.83-3.8 1.4-3.22 1.13-7.71 2.57-12.72 4s-9.57 2.61-12.88 3.42c-1.66.41-3 .72-3.94.92a9.65 9.65 0 01-1.46.27 7.84 7.84 0 011.41-.47l3.89-1.08c3.29-.89 7.84-2.13 12.84-3.56s9.5-2.81 12.75-3.85l3.84-1.24a8.61 8.61 0 011.43-.4zM383.89 175.6a13.69 13.69 0 01-2.73 1.16 51.94 51.94 0 01-6.88 1.94 53.05 53.05 0 01-7.08 1 13 13 0 01-3 0 25.05 25.05 0 012.93-.39c1.8-.21 4.29-.57 7-1.14s5.14-1.24 6.88-1.78a19.52 19.52 0 012.88-.79z"
                ></path>
                <path
                    fill="#263238"
                    d="M377.61 168.42a.86.86 0 01-.17.18l-.51.46-2 1.76-7.43 6.38-11.07 9.41c-2.09 1.76-4.27 3.62-6.54 5.54l-1.72 1.45a17.45 17.45 0 01-1.83 1.42 17 17 0 01-4.33 1.92 67.81 67.81 0 01-9.09 1.64l-8.49 1.22-14.38 2-9.7 1.33-2.64.34-.69.07a1.08 1.08 0 01-.24 0l.23-.06.68-.13 2.63-.43 9.68-1.5 14.36-2.15 8.49-1.26a67.53 67.53 0 009-1.63 17.24 17.24 0 004.2-1.85 19.29 19.29 0 001.78-1.38l1.72-1.45 6.56-5.52 11.14-9.32 7.54-6.26c.87-.7 1.56-1.26 2.06-1.68l.55-.42a.84.84 0 01.21-.08z"
                ></path>
                <path
                    d="M349.08 199.94c-.23-2-.84-4.34-2.71-5.14a69.14 69.14 0 01-13.78 3.65l-.12.15a7.31 7.31 0 007.22 2.8 6.5 6.5 0 012.74-.35 4 4 0 012.4 2.52 8.62 8.62 0 002.19 3.22l2.47-3.19c.32-1.23-.26-2.41-.41-3.66zM347.31 193.83a49.91 49.91 0 0029.07-24.53z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#ffbe9d"
                    d="M306.93 242l-10.29-2.29-7.35 1.72s-.54 1.2 1.25 1.58l3.9-.27-3.71 2s.25 1.4 2 1.08a8.91 8.91 0 003.11-1.25l6.1 1.62s-6 2.79-5.54 4.2 1.06 1.36 1.85 1.17 1.64-1.5 3.28-1.46 4.57-.52 7.65-.87c2.66-.3 7.62-7.24 7.62-7.24l-7.11-4.09z"
                ></path>
                <path
                    fill="#eb996e"
                    d="M301.17 242.73a13 13 0 01-3.58-.68 4.52 4.52 0 00-1.28-.27 2.51 2.51 0 00-1 .38c-.53.34-.83.62-.89.57s.15-.42.68-.87a2.45 2.45 0 011.12-.55 1.61 1.61 0 01.76.05l.71.18a13 13 0 013.48 1.19z"
                ></path>
            </g>
            <path
                fill="#263238"
                d="M478.55 472.48c0 .14-102.33.26-228.54.26s-228.56-.12-228.56-.26 102.31-.26 228.56-.26 228.54.11 228.54.26z"
            ></path>
        </svg>
        </div>
    );
}

export default HomePageImage;
