import { useState, useCallback } from "react";
import { Fragment } from "react";

function ContactUs() {
    const [formData, setFormData] = useState({
        "entry.971890835": "",
        "entry.236515241": "",
        "entry.1633782280": ""
    });
    const [isSubmitted, setIsSubmitted] = useState(false)
    const onSubmit = useCallback(
        async () => {
            const url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSc0V2ejHPRvgPjdDiM2osMBE_fhxuc8i4PBPaeuoGKzyLnfcw/formResponse?entry.971890835=${formData["entry.971890835"]}&entry.236515241=${formData["entry.236515241"]}&entry.1633782280=${formData["entry.1633782280"]}`
            try {
                await fetch(url,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        },
                        mode: "no-cors"
                    }
                ).then(()=>{
                    setIsSubmitted(true)
                })


            } catch (e) {
                console.log(e)
            }
        }, [formData]
    );

    return (
        <>
            <div class="mx-auto md:min-w-[75%] bg-tertiary p-8 text-center rounded-md">
{!isSubmitted && 
    <Fragment>
    <h3 class="text-2xl font-bold md:text-3xl text-primary py-2 mb-4">Get Advice from Experts</h3>
    <form class="mx-auto mb-4 max-w-[600px] text-left" onSubmit={(e) => { onSubmit(); e.preventDefault(); }} method="post">
        <div>
            <label htmlFor="entry.971890835" class="mb-1 text-sm text-primary">Your Name</label>
            <input type="text" onChange={(e) => setFormData({ ...formData, "entry.971890835": e.target.value })} value={formData['entry.971890835']} name="entry.971890835" required class="mb-4 block h-9 w-full rounded-md border border-solid border-black px-3 py-6 pl-4 text-sm text-[#333333]" />
        </div>
        <div class="mb-2">
            <label htmlFor="entry.236515241" class="mb-1 text-sm text-primary">Email Address</label>
            <input type="email" onChange={(e) => setFormData({ ...formData, "entry.236515241": e.target.value })} value={formData['entry.236515241']} name="entry.236515241" required class="mb-4 block h-9 w-full rounded-md border border-solid border-black px-3 py-6 pl-4 text-sm text-[#333333]" />
        </div>
        <div class="mb-2">
            <label htmlFor="entry.1633782280" class="mb-1 text-sm text-primary">Mobile Number</label>
            <input type="tel" onChange={(e) => setFormData({ ...formData, "entry.1633782280": e.target.value })} value={formData['entry.1633782280']} name="entry.1633782280" required minLength="9" maxLength="14" class="mb-4 block h-9 w-full rounded-md border border-solid border-black px-3 py-6 pl-4 text-sm text-[#333333]" />
        </div>
        <input type="submit" value="Request callback" class="inline-block w-full cursor-pointer rounded-md bg-primary px-6 py-3 text-center font-semibold text-tertiary" />
    </form>
    </Fragment>
}
{ isSubmitted &&
  <div>
  <h3 class="text-2xl font-bold md:text-3xl text-primary py-2 mb-4">Thank You for Reaching Out!</h3>
  <p class="text-sm md:text-base py-2 mb-4">Your inquiry has been successfully submitted. We value your interest and are eager to connect with you. One of our advisors will contact you shortly to address your needs and answer any questions you may have.</p>
  <span class="text-xs md:text-sm pt-8 mt-4">Expect a response within one business day.</span>
  </div>
}
</div>
        </>
    );
}

export default ContactUs


