// Docs {@link https://tailwindcss.com/docs/text-color}
import React from 'react';
import photo1 from '../assets/image/photo1.jpeg'
import photo2 from '../assets/image/photo2.jpeg'
import photo3 from '../assets/image/photo3.jpeg'
import photo4 from '../assets/image/photo4.jpeg'

export function Testimonials() {
    return (

        <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                <h2 class="mb-8 text-center text-3xl font-bold md:mb-14 md:text-5xl">What investors are saying about stepone</h2>
                <ul class="grid gap-8 sm:grid-cols-2">
                    <li>
                        <div class="mb-5 max-w-sm rounded-br-[99px] rounded-tl-[60px] rounded-tr-[99px] bg-[#f2f2f7] px-8 py-6 md:mb-8">
                            <p class="text-[#647084]"> “I am with stepone since last 6 months and since then I am getting all the possible help from them in the financial world. They helped me in creating financial plan for future on the basis of my earnings and also recommended some investments, all the things were also thoroughly discussed with me. They also helps in keeping you market aware with best stock recommendations. I want to thanks Mr.Prabhu for all till now and also looking forward for more interesting investments." </p>
                        </div>
                        <div class="mb-5 flex lg:mb-8">
                            <img src={photo1} alt="" class="mr-4 h-16 w-16 rounded-[50%]" />
                            <div class="flex flex-col justify-center">
                                <h6 class="font-bold">Selvamurugan R</h6>
                                {/* <p class="text-sm text-[#636262]">Designer</p> */}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="mb-5 max-w-sm rounded-br-[99px] rounded-tl-[60px] rounded-tr-[99px] bg-[#f2f2f7] px-8 py-6 md:mb-8">
                            <p class="text-[#647084]"> “I have been a client of stepone for last 10 months and I must say that am absolutely delighted with your service. It is really
                                refreshing to work with Mr.prabhu my financial advisor who is truly interested in their client's needs, circumstances
                                and preferences. What really impressed me was the way he took the time to get a feeling for where I was at, your depth of knowledge, lateral thinking and your common sense approach.
                                Your professional, ethical and caring demeanour elicits my trust and respect and I gladly recommend your services wheneverpossible." </p>
                        </div>
                        <div class="mb-5 flex lg:mb-8">
                            <img src={photo3} alt="" class="mr-4 h-16 w-16 rounded-[50%]" />
                            <div class="flex flex-col justify-center">
                                <h6 class="font-bold">Dhanushya Chinnasamy</h6>
                                {/* <p class="text-sm text-[#636262]">Webflow Designer</p> */}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="mb-5 max-w-sm rounded-br-[99px] rounded-tl-[60px] rounded-tr-[99px] bg-[#f2f2f7] px-8 py-6 md:mb-8">
                            <p class="text-[#647084]"> “Stepone is continuously helping me in my IT returns since last 6 years. Vasu is extremely good and constantly supporting me for IT returns. He is very approachable and have sound knowledge regarding IT returns. Many thanks to you vasu for all your nice gestures. Thanks to entire team." </p>
                        </div>
                        <div class="mb-5 flex lg:mb-8">
                            <img src={photo2} alt="" class="mr-4 h-16 w-16 rounded-[50%]" />
                            <div class="flex flex-col justify-center">
                                <h6 class="font-bold">Narayanan P</h6>
                                {/* <p class="text-sm text-[#636262]">Webflow Developer</p> */}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="mb-5 max-w-sm rounded-br-[99px] rounded-tl-[60px] rounded-tr-[99px] bg-[#f2f2f7] px-8 py-6 md:mb-8">
                            <p class="text-[#647084]"> “I am really happy to connect with stepone, especially Ayyappan. He guided us well and gave different perspective to save and grow our money. I would definitely recommend Stepone. One of the best Financial Planner. Good Job Ayyappan." </p>
                        </div>
                        <div class="mb-5 flex lg:mb-8">
                            <img src={photo4} alt="" class="mr-4 h-16 w-16 rounded-[50%]" />
                            <div class="flex flex-col justify-center">
                                <h6 class="font-bold">Pradeep kumar G</h6>
                                {/* <p class="text-sm text-[#636262]">Product Manager</p> */}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
}
