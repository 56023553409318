function EasySteps() {
    return (
        <section className="bg-secondary">
        <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Get Started In 3 Easy Steps</h2>
            <div class="mx-auto grid max-w-4xl gap-9">
                <a href="https://docs.google.com/spreadsheets/d/1GV35Ijb9S0O7AhAJ2jrECzMFFabeEqNHg3jRYZFlV04/edit?usp=sharing" target="_blank" class="flex items-center justify-center gap-6 rounded-[60px] shadow-lg bg-tertiary px-6 py-4 transition text-change">
                    <div class="flex h-20 w-20 flex-none items-center justify-center rounded-full bg-tertiary">
                        <i class="bi bi-currency-dollar text-4xl bg-white text-primary"></i>
                    </div>
                    <div class="flex flex-col items-start gap-2.5">
                        <h5 class="text-xl font-bold text-primary-text">Short-Term Call</h5>
                        <p class="text-sm opacity-50 sm:text-base text-primary-text">Optimize short-term investments (2 days to 3 months) with expert advice. Ideal for quick, informed market moves and rapid results.</p>
                    </div>
                </a>
                <a href="#" class="flex items-center justify-center gap-6 rounded-[60px] shadow-lg bg-tertiary px-6 py-4 transition text-change">
                    <div class="flex h-20 w-20 flex-none items-center justify-center rounded-full bg-tertiary">
                        <i class="bi bi-yin-yang text-4xl bg-white text-primary"></i>
                    </div>
                    <div class="flex flex-col items-start gap-2.5">
                        <h5 class="text-xl font-bold text-primary-text">Long-Term Call </h5>
                        <p class="text-sm opacity-50 sm:text-base text-primary-text">Long-term investment insights for periods extending over 3 months. Build a robust portfolio with strategic, forward-thinking guidance.</p>
                    </div>
                </a>
                <a href="#" class="flex items-center justify-center gap-6 rounded-[60px] shadow-lg bg-tertiary px-6 py-4 transition text-change">
                    <div class="flex h-20 w-20 flex-none items-center justify-center rounded-full bg-tertiary">
                        <i class="fa fa-balance-scale text-4xl bg-white text-primary"></i>
                    </div>
                    <div class="flex flex-col items-start gap-2.5">
                        <h5 class="text-xl font-bold text-primary-text">IPO Analysis</h5>
                        <p class="text-sm opacity-50 sm:text-base text-primary-text">Detailed analysis of upcoming IPOs to guide your investment decisions. Gain insights and suggestions for informed, confident choices.</p>
                    </div>
                </a>
            </div>
        </div>
    </section>
    );
}

export default EasySteps;
