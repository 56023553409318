import React from "react";

function RecommendationPageImage() {
  return (
    <div class="min-w-[50%]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" preserveAspectRatio="none">
      <g>
        <g fill="#ebebeb">
          <path d="M0 398.87H500V399.12H0z"></path>
          <path d="M416.78 414.96H435.66999999999996V415.21H416.78z"></path>
          <path d="M293.05 413.22H322.52V413.47H293.05z"></path>
          <path d="M368.67 405.68H415.78000000000003V405.93H368.67z"></path>
          <path d="M52.46 407.36H63.43V407.61H52.46z"></path>
          <path d="M72.33 407.36H110.89V407.61H72.33z"></path>
          <path d="M171 411.58H225.15V411.83H171z"></path>
          <path d="M237 335.69H43.91a5.71 5.71 0 01-5.7-5.71V58.55a5.71 5.71 0 015.7-5.66H237a5.71 5.71 0 015.71 5.71V330a5.71 5.71 0 01-5.71 5.69zM43.91 53.09a5.46 5.46 0 00-5.45 5.46V330a5.45 5.45 0 005.45 5.46H237a5.47 5.47 0 005.46-5.46V58.55a5.48 5.48 0 00-5.46-5.46zM453.31 335.69h-193.1a5.71 5.71 0 01-5.71-5.69V58.55a5.72 5.72 0 015.71-5.66h193.1a5.72 5.72 0 015.69 5.66V330a5.7 5.7 0 01-5.69 5.69zm-193.1-282.6a5.48 5.48 0 00-5.46 5.46V330a5.47 5.47 0 005.46 5.46h193.1a5.47 5.47 0 005.46-5.46V58.55a5.48 5.48 0 00-5.46-5.46z"></path>
          <path d="M416.78 414.96H435.66999999999996V415.21H416.78z"></path>
          <path d="M293.05 413.22H322.52V413.47H293.05z"></path>
          <path d="M368.67 405.68H415.78000000000003V405.93H368.67z"></path>
          <path d="M52.46 407.36H63.43V407.61H52.46z"></path>
          <path d="M72.33 407.36H110.89V407.61H72.33z"></path>
          <path d="M171 411.58H225.15V411.83H171z"></path>
        </g>
        <circle cx="420.01" cy="393.55" r="6.73" fill="#e0e0e0"></circle>
        <circle cx="413.28" cy="393.55" r="6.73" fill="#ebebeb"></circle>
        <circle cx="329.7" cy="393.55" r="6.73" fill="#e0e0e0"></circle>
        <path fill="#ebebeb" d="M305.88 238.81H419.36V388.18H305.88z"></path>
        <path fill="#f5f5f5" d="M327.88 238.81H426.74V388.18H327.88z"></path>
        <path
          fill="none"
          stroke="#e0e0e0"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M336.82 246.54H418.59V380.62H336.82z"
        ></path>
        <path
          fill="none"
          stroke="#e0e0e0"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M336.82 289.24L419.36 289.24"
        ></path>
        <path
          fill="none"
          stroke="#e0e0e0"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M336.82 335.88L419.36 335.88"
        ></path>
        <path fill="#fafafa" d="M364.84 252.15H395.21V255.77H364.84z"></path>
        <path fill="#fafafa" d="M364.84 295.09H395.21V298.71H364.84z"></path>
        <path
          fill="#fafafa"
          d="M364.84 343.22H395.21V346.84000000000003H364.84z"
        ></path>
        <circle cx="322.97" cy="393.55" r="6.73" fill="#ebebeb"></circle>
        <path fill="#ebebeb" d="M97.85 93.36H304.07V235.64H97.85z"></path>
        <path fill="#e0e0e0" d="M304.07 93.36H308.08V235.64H304.07z"></path>
        <path fill="#f5f5f5" d="M103.56 107.7H299.88V228.59H103.56z"></path>
        <path
          fill="#fafafa"
          d="M198.69 228.59L180.92 228.59 129.77 107.51 147.54 107.51 198.69 228.59z"
        ></path>
        <path
          fill="#fafafa"
          d="M238.41 228.59L206.21 228.59 155.06 107.51 187.28 107.51 238.41 228.59z"
        ></path>
        <path
          fill="#fafafa"
          d="M286.2 228.63L268.42 228.63 217.29 107.56 235.05 107.56 286.2 228.63z"
        ></path>
        <path
          fill="#e0e0e0"
          stroke="#f0f0f0"
          strokeMiterlimit="10"
          d="M97.85 86.08H308.09000000000003V145.51H97.85z"
          opacity="0.3"
        ></path>
        <path
          fill="#fafafa"
          d="M97.85 133.11H307.49V134.95000000000002H97.85z"
        ></path>
        <path fill="#fafafa" d="M97.85 124.6H307.49V126.44H97.85z"></path>
        <path fill="#fafafa" d="M97.85 116.08H307.49V117.92H97.85z"></path>
        <path fill="#fafafa" d="M97.85 107.57H307.49V109.41H97.85z"></path>
        <path fill="#fafafa" d="M97.85 99.05H307.49V100.89H97.85z"></path>
        <path fill="#e0e0e0" d="M95.36 83.53H311.97V86.03H95.36z"></path>
        <path
          fill="#e0e0e0"
          d="M96.78 84.78c0 2.23-1.31 4-2.91 4S91 87 91 84.78s1.31-4 2.92-4 2.86 1.76 2.86 4zM314.88 84.78c0 2.23-1.31 4-2.91 4s-2.92-1.81-2.92-4 1.31-4 2.92-4 2.91 1.76 2.91 4z"
        ></path>
      </g>
      <ellipse
        cx="245.35"
        cy="439.91"
        fill="#f5f5f5"
        rx="159.43"
        ry="12.46"
      ></ellipse>
      <g>
        <path
          fill="#0096FF"
          d="M396.91 161.59l-21.31 66.48a4.62 4.62 0 01-7.73 1.8l-11.08-11.47a4.64 4.64 0 00-6.44-.21l-92.53 84a4.63 4.63 0 01-6.07.13L193 253.47a4.63 4.63 0 00-6 .1L71.59 355.85l4.38-41a4.6 4.6 0 011.53-3l108.91-96.52a4.64 4.64 0 016-.09L250.92 264a4.63 4.63 0 006.07-.13l73-66.31a4.62 4.62 0 00.21-6.64l-10.9-11.34a4.63 4.63 0 012.28-7.73l69.88-16.18a4.62 4.62 0 015.45 5.92z"
        ></path>
        <path
          d="M396.88 161.7L375.64 228a4.7 4.7 0 01-7.85 1.82l-11-11.34a4.71 4.71 0 00-6.54-.22l-92.43 83.93a4.68 4.68 0 01-3.35 1.22 4.61 4.61 0 01-2.82-1.08l-58.62-48.79a4.74 4.74 0 00-6.12.09L71.59 355.85l4.57-42.73 110.21-97.67a4.72 4.72 0 016.12-.09l24.91 20.73L250.87 264a4.72 4.72 0 006.16-.13L330 197.6a4.68 4.68 0 00.22-6.74l-10.81-11.19a4.7 4.7 0 012.32-7.84l69.64-16.14a4.7 4.7 0 015.51 6.01z"
          opacity="0.1"
        ></path>
        <path
          fill="#0096FF"
          d="M191 411.04H276.31V430.40000000000003H191z"
        ></path>
        <path
          d="M199.49 411.04H201.8V430.40000000000003H199.49z"
          opacity="0.2"
        ></path>
        <path
          d="M211.36 411.04H213.67000000000002V430.40000000000003H211.36z"
          opacity="0.2"
        ></path>
        <path
          d="M225.53 430.4L223.22 430.4 223.22 411.04 225.53 411.38 225.53 430.4z"
          opacity="0.2"
        ></path>
        <path
          d="M235.09 411.04H237.4V430.40000000000003H235.09z"
          opacity="0.2"
        ></path>
        <path
          d="M246.96 411.04H249.27V430.40000000000003H246.96z"
          opacity="0.2"
        ></path>
        <path
          d="M258.82 411.04H261.13V430.40000000000003H258.82z"
          opacity="0.2"
        ></path>
        <path
          d="M270.69 411.04H273V430.40000000000003H270.69z"
          opacity="0.2"
        ></path>
        <path
          d="M191 411.04H276.31V415.03000000000003H191z"
          opacity="0.09"
        ></path>
        <path
          fill="#0096FF"
          d="M204.3 391.67H289.61V411.03000000000003H204.3z"
        ></path>
        <path
          d="M212.79 391.67H215.1V411.03000000000003H212.79z"
          opacity="0.2"
        ></path>
        <path
          d="M224.66 391.67H226.97V411.03000000000003H224.66z"
          opacity="0.2"
        ></path>
        <path
          d="M236.53 391.67H238.84V411.03000000000003H236.53z"
          opacity="0.2"
        ></path>
        <path
          d="M248.39 391.67H250.7V411.03000000000003H248.39z"
          opacity="0.2"
        ></path>
        <path
          d="M260.26 391.67H262.57V411.03000000000003H260.26z"
          opacity="0.2"
        ></path>
        <path
          d="M272.12 391.67H274.43V411.03000000000003H272.12z"
          opacity="0.2"
        ></path>
        <path
          d="M283.99 391.67H286.3V411.03000000000003H283.99z"
          opacity="0.2"
        ></path>
        <path d="M204.3 391.67H289.61V395.66H204.3z" opacity="0.09"></path>
        <path fill="#0096FF" d="M192.44 372.31H277.75V391.67H192.44z"></path>
        <path d="M200.93 372.31H203.24V391.67H200.93z" opacity="0.2"></path>
        <path
          d="M215.1 391.68L212.79 391.68 212.79 372.33 215.1 372.31 215.1 391.68z"
          opacity="0.2"
        ></path>
        <path d="M224.66 372.31H226.97V391.67H224.66z" opacity="0.2"></path>
        <path d="M236.53 372.31H238.84V391.67H236.53z" opacity="0.2"></path>
        <path
          d="M250.7 391.68L248.39 391.68 248.39 372.31 250.7 372.33 250.7 391.68z"
          opacity="0.2"
        ></path>
        <path d="M260.26 372.31H262.57V391.67H260.26z" opacity="0.2"></path>
        <path d="M272.12 372.31H274.43V391.67H272.12z" opacity="0.2"></path>
        <path d="M192.44 372.31H277.75V377.08H192.44z" opacity="0.09"></path>
        <path fill="#0096FF" d="M204.3 352.96H289.61V372.32H204.3z"></path>
        <path d="M212.79 352.96H215.1V372.32H212.79z" opacity="0.2"></path>
        <path d="M224.66 352.96H226.97V372.32H224.66z" opacity="0.2"></path>
        <path d="M236.53 352.96H238.84V372.32H236.53z" opacity="0.2"></path>
        <path d="M248.39 352.96H250.7V372.32H248.39z" opacity="0.2"></path>
        <path d="M260.26 352.96H262.57V372.32H260.26z" opacity="0.2"></path>
        <path
          d="M274.43 372.31L272.12 372.33 272.12 352.96 274.43 353.31 274.43 372.31z"
          opacity="0.2"
        ></path>
        <path d="M283.99 352.96H286.3V372.32H283.99z" opacity="0.2"></path>
        <path d="M204.3 352.96H289.61V356.95H204.3z" opacity="0.09"></path>
        <path
          fill="#0096FF"
          d="M208.05 333.6H293.36V352.96000000000004H208.05z"
        ></path>
        <path
          d="M216.54 333.6H218.85V352.96000000000004H216.54z"
          opacity="0.2"
        ></path>
        <path
          d="M228.4 333.6H230.71V352.96000000000004H228.4z"
          opacity="0.2"
        ></path>
        <path
          d="M240.27 333.6H242.58V352.96000000000004H240.27z"
          opacity="0.2"
        ></path>
        <path
          d="M252.14 333.6H254.45V352.96000000000004H252.14z"
          opacity="0.2"
        ></path>
        <path
          d="M264 333.6H266.31V352.96000000000004H264z"
          opacity="0.2"
        ></path>
        <path
          d="M275.87 333.6H278.18V352.96000000000004H275.87z"
          opacity="0.2"
        ></path>
        <path
          d="M287.73 333.6H290.04V352.96000000000004H287.73z"
          opacity="0.2"
        ></path>
        <path
          d="M208.05 333.6H293.36V337.59000000000003H208.05z"
          opacity="0.09"
        ></path>
        <path
          fill="#0096FF"
          d="M197.9 314.24H283.21000000000004V333.6H197.9z"
        ></path>
        <path d="M206.39 314.24H208.7V333.6H206.39z" opacity="0.2"></path>
        <path d="M218.26 314.24H220.57V333.6H218.26z" opacity="0.2"></path>
        <path d="M230.12 314.24H232.43V333.6H230.12z" opacity="0.2"></path>
        <path d="M241.99 314.24H244.3V333.6H241.99z" opacity="0.2"></path>
        <path
          d="M253.85 314.24H256.15999999999997V333.6H253.85z"
          opacity="0.2"
        ></path>
        <path
          d="M265.72 314.24H268.03000000000003V333.6H265.72z"
          opacity="0.2"
        ></path>
        <path d="M277.59 314.24H279.9V333.6H277.59z" opacity="0.2"></path>
        <path
          d="M197.9 314.24H283.21000000000004V318.23H197.9z"
          opacity="0.09"
        ></path>
        <path fill="#0096FF" d="M84.33 411.33H169.64V430.69H84.33z"></path>
        <path d="M92.82 411.33H95.13V430.69H92.82z" opacity="0.2"></path>
        <path d="M104.69 411.33H107V430.69H104.69z" opacity="0.2"></path>
        <path d="M116.56 411.33H118.87V430.69H116.56z" opacity="0.2"></path>
        <path d="M128.42 411.33H130.73V430.69H128.42z" opacity="0.2"></path>
        <path d="M140.29 411.33H142.6V430.69H140.29z" opacity="0.2"></path>
        <path d="M152.15 411.33H154.46V430.69H152.15z" opacity="0.2"></path>
        <path d="M164.02 411.33H166.33V430.69H164.02z" opacity="0.2"></path>
        <path d="M84.33 411.33H169.64V415.32H84.33z" opacity="0.09"></path>
        <path
          fill="#0096FF"
          d="M88.36 391.97H173.67000000000002V411.33000000000004H88.36z"
        ></path>
        <path
          d="M96.85 391.97H99.16V411.33000000000004H96.85z"
          opacity="0.2"
        ></path>
        <path
          d="M108.72 391.97H111.03V411.33000000000004H108.72z"
          opacity="0.2"
        ></path>
        <path
          d="M120.58 391.97H122.89V411.33000000000004H120.58z"
          opacity="0.2"
        ></path>
        <path
          d="M132.45 391.97H134.76V411.33000000000004H132.45z"
          opacity="0.2"
        ></path>
        <path
          d="M144.31 391.97H146.62V411.33000000000004H144.31z"
          opacity="0.2"
        ></path>
        <path
          d="M156.18 391.97H158.49V411.33000000000004H156.18z"
          opacity="0.2"
        ></path>
        <path
          d="M168.05 391.97H170.36V411.33000000000004H168.05z"
          opacity="0.2"
        ></path>
        <path
          d="M88.36 391.97H173.67000000000002V395.62H88.36z"
          opacity="0.09"
        ></path>
        <path
          fill="#0096FF"
          d="M83.13 372.6H168.44V391.96000000000004H83.13z"
        ></path>
        <path
          d="M91.62 372.6H93.93V391.96000000000004H91.62z"
          opacity="0.2"
        ></path>
        <path
          d="M103.49 372.6H105.8V391.96000000000004H103.49z"
          opacity="0.2"
        ></path>
        <path
          d="M115.35 372.6H117.66V391.96000000000004H115.35z"
          opacity="0.2"
        ></path>
        <path
          d="M127.22 372.6H129.53V391.96000000000004H127.22z"
          opacity="0.2"
        ></path>
        <path
          d="M139.09 372.6H141.4V391.96000000000004H139.09z"
          opacity="0.2"
        ></path>
        <path
          d="M150.95 372.6H153.26V391.96000000000004H150.95z"
          opacity="0.2"
        ></path>
        <path
          d="M162.82 372.6H165.13V391.96000000000004H162.82z"
          opacity="0.2"
        ></path>
        <path d="M83.13 372.6H168.44V376.25H83.13z" opacity="0.09"></path>
        <path fill="#0096FF" d="M337.15 362.89H355.84V431.21H337.15z"></path>
        <g fill="#fff" opacity="0.4">
          <path d="M355.84 381.07L355.84 394.96 337.15 413.65 337.15 398.71 355.84 381.07z"></path>
          <path d="M355.84 370.91L355.84 373.27 337.15 391.97 337.15 389.61 355.84 370.91z"></path>
        </g>
        <path fill="#0096FF" d="M360.16 384.9H378.85V431.21H360.16z"></path>
        <g fill="#fff" opacity="0.4">
          <path d="M378.85 398.55L378.85 412.3 360.16 430.98 360.16 416.2 378.85 398.55z"></path>
          <path d="M378.85 392.07L378.85 394.71 360.16 413.41 360.16 410.75 378.85 392.07z"></path>
        </g>
        <path
          fill="#0096FF"
          d="M314.13 330.06H332.82V431.21000000000004H314.13z"
        ></path>
        <g fill="#fff" opacity="0.4">
          <path d="M332.83 349.05L332.83 362.87 314.13 381.56 314.13 366.7 332.83 349.05z"></path>
          <path d="M332.83 341.45L332.83 344.03 314.13 362.74 314.13 360.14 332.83 341.45z"></path>
        </g>
        <path
          fill="#263238"
          d="M325.75 338.52a2.68 2.68 0 11-.85-3.69 2.69 2.69 0 01.85 3.69zM348.76 371a2.67 2.67 0 11-.85-3.69 2.67 2.67 0 01.85 3.69zM371.77 393a2.67 2.67 0 11-.85-3.68 2.66 2.66 0 01.85 3.68z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M369.51 391.56L345.79 413.65 346.49 369.58 323.48 383.81 323.48 337.1"
        ></path>
      </g>
      <g>
        <path
          fill="#b55b52"
          d="M155.42 214.28s-6.37 9.4-7.86 10.37-21.6-2.85-31.47-8l-5.09-6.38-7.68-2.7-4.41 8.76 6.09 4s3.9 4.49 8.27 3.24c0 0 15.72 10.26 27 12.88s13.89-.41 17.18-3.09 12-15.39 12-15.39zM215.87 174.74s25.16-7.68 33.68-14.05c0 0 12.93-15.82 15.08-32.71l-2.91-8.79 1.54-10 9.58 2.14s5 10.52-1.09 18.76c0 0-1.63 24.48-12 37.24s-42.81 21.29-42.81 21.29z"
        ></path>
        <path
          fill="#0096FF"
          d="M224.89 267.19l-2.06-74 16.17-7.73-10.39-18.54s-12.61 3.7-23.53 3.7c-14.51 0-29.11 11-37 14.12s-16.65 29.95-16.65 29.95l18.24 9.77 3.42-10.06s4.91 37.18 4.93 53.49z"
        ></path>
        <path
          fill="#fff"
          d="M224.89 267.19l-2.06-74 16.17-7.73-10.39-18.54s-12.61 3.7-23.53 3.7c-14.51 0-29.11 11-37 14.12s-16.65 29.95-16.65 29.95l18.24 9.77 3.42-10.06s4.91 37.18 4.93 53.49z"
          opacity="0.1"
        ></path>
        <path
          fill="#263238"
          d="M188 163.87s-12.63 1.32-14.92-8.73c0 0-7.54-3-2.18-10.44 0 0-6.94-10.33 1.92-13.4a19.84 19.84 0 0113.86-11.72c7.17-1.76 5.14 2.09 5.14 2.09s14.87-5.75 17 .66-9.45 9.25-9.45 9.25z"
        ></path>
        <path
          fill="#b55b52"
          d="M195.86 162a14.89 14.89 0 00.22 1.79c.51 3 2.57 7.78 7.57 9.36 0 0 .69 5.34-7.57 4.42s-12.19-2.39-12.19-2.39a47.84 47.84 0 00-.24-19.54s-4.66.78-7.29-1.88-1.68-9.39 6.55-5.58c0 0 1.78-9.81-4-12.76 0 0 11.32 1.64 20.37-3.87 0 0 7.81 10 6.72 19.55S195.86 162 195.86 162z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.722"
          d="M197.87 150.47s-2.94 1.91-6.29-.85"
        ></path>
        <path
          fill="#263238"
          d="M191 142.22c.18.73.69 1.23 1.14 1.12s.67-.79.49-1.52-.69-1.24-1.14-1.13-.72.8-.49 1.53zM198.85 139.29c.18.74.69 1.24 1.14 1.13s.67-.8.49-1.53-.69-1.23-1.14-1.12-.67.79-.49 1.52z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.963"
          d="M190.48 136.91a3.47 3.47 0 00-2.53 3M201.15 134.37s-1.78-1.33-3.35-.19"
        ></path>
        <path
          fill="#a02724"
          d="M199.08 146.75l-1.86-6.13 5.24 4.36a3.08 3.08 0 01-3.38 1.77z"
        ></path>
        <path
          d="M196.08 163.82a5.38 5.38 0 01-4.31-1.08c1.53-.16 4.09-.71 4.09-.71a14.89 14.89 0 00.22 1.79z"
          opacity="0.19"
        ></path>
        <path
          fill="#263238"
          d="M200.52 177.71l-.05 9.58-4.7.43s-3.59-3.52-3.82-5.36 5.17-6.67 8.57-4.65z"
        ></path>
        <path
          fill="#263238"
          d="M199.79 186.31s7.06 26 10.45 39l-3.86 7.69-6.5-6.49-3.51-39.77zM232.08 422l-14.32 2.25s-4.41-54.71-5.54-75.23c-.19-3.55-.55-7.33-1-11.17-2.22-18.42-6.76-38.11-6.76-38.11s-1.48 32.85-5.58 58-21.45 62-21.45 62l-13.62-6.27c10.64-30.44 13.08-68.35 14-82.53s-.12-72.5-.12-72.5l46.9-2.05c4.53 22.08 10.42 72.55 11.07 90S232.08 422 232.08 422z"
        ></path>
        <path
          fill="#b55b52"
          d="M229.54 426.16L230.3 437.66 221.83 439.57 220.94 426.71 229.54 426.16z"
        ></path>
        <path
          fill="#0096FF"
          d="M234.36 420.12L216.23 423.63 217.12 429.26 234.02 426.19 234.36 420.12z"
        ></path>
        <path
          fill="#b55b52"
          d="M174.5 421.31L169.5 431.97 161.45 428.86 166.82 417.75 174.5 421.31z"
        ></path>
        <path
          fill="#263238"
          d="M162 429l9.44 3.72a1.8 1.8 0 01.95.85c.72 1.39 2.69 4.8 5.05 6.1 3 1.66 4.59 2.82 3.51 4.54s-2.72 1.13-4.4.69c-1.37-.36-14.28-5.77-19-7.77a1.61 1.61 0 01-.73-2.35l3.27-5.14A1.62 1.62 0 01162 429zM221.65 439.13l10-2a1.82 1.82 0 011.25.21c1.36.77 4.85 2.59 7.54 2.42 3.41-.22 5.38-.1 5.4 1.93s-1.68 2.42-3.34 2.95c-1.34.43-15.13 2.83-20.22 3.71a1.6 1.6 0 01-1.88-1.58v-6.1a1.62 1.62 0 011.25-1.54z"
        ></path>
        <path
          fill="#0096FF"
          d="M182.51 417.41L163.08 413.24 161.37 418.87 179 424.05 182.51 417.41z"
        ></path>
        <path
          d="M211.21 338c-2.22-18.41-6.76-38.11-6.76-38.11l-1.92-24.51s10.84 42.34 8.68 62.62z"
          opacity="0.61"
        ></path>
        <path
          fill="#fff"
          d="M184.39 172.26a37.36 37.36 0 0011.69 5.34l-3.08 9.07s-7.53-3.46-11.35-6.59zM196.08 177.6l5.07 7.86a30.82 30.82 0 004.95-10.7l-5.7-4.14s-2.4 5.24-4.32 6.98z"
        ></path>
      </g>
    </svg>
    </div>
  );
}

export default RecommendationPageImage;
