import { useState, useEffect, useRef } from "react";
import Logo from '../assets/image/logo.png';

function NavBar() {

  const [isHamBurgerMenuOpen, setIsHamBurgerMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, [ref]);
    return ref;
  };

  const handleClickOutside = () => {
    setIsDropdownOpen(false)
  };

  const ref = useOutsideClick(handleClickOutside);

  return (
    <section>
      <div className="h-auto w-full font-jakarta select-none">
        <nav className="mx-auto h-auto w-full max-w-[1600px] lg:relative lg:top-0 mt-2">
          <div className="flex flex-col px-4 py-6 lg:flex-row lg:items-center lg:justify-between lg:px-10 lg:py-4 xl:px-20">
            <a href="/" className="rounded-lg lg:px-6 lg:py-2 text-primary-text">
              <img src={Logo} alt="" className="inline-block max-h-10" />
            </a>
            <div className={"mt-14 flex flex-col space-y-8 lg:mt-0 lg:flex lg:flex-row lg:space-x-1 lg:space-y-0 " + (isHamBurgerMenuOpen ? 'show' : 'hidden')} >
              <a href="/" className="rounded-lg lg:px-6 lg:py-2 text-primary-text lg:hover:underline decoration-2 underline-offset-8 font-bold">Home</a>
              <div className="relative flex flex-col">
                <a onClick={() => setIsDropdownOpen(!isDropdownOpen)} ref={ref} className={"cursor-pointer flex flex-row rounded-lg lg:px-6 lg:py-2 lg:hover:underline decoration-2 underline-offset-8 font-bold " + (isDropdownOpen ? 'lg:bg-secondary' : 'text-black ')}>
                  Our solutions
                </a>
                <div className={"lg:px-4 z-50 flex w-full flex-col rounded-lg bg-tertiary px-5 py-5 lg:absolute lg:top-20 lg:w-[600px] lg:flex-row lg:flex-wrap lg:py-7 lg:shadow-[0_16px_64px_-15px_rgba(45,96,173,0.15)] xl:w-[600px] " + (isDropdownOpen ? 'visible show' : 'invisible hidden')}>
                  <a className="flex grow flex-col rounded-lg px-5 py-5 hover:bg-secondary lg:basis-[248px] xl:px-8" href="/solution1">
                    <div className="relative">
                      <i class="fa-solid fa-seedling text-4xl bg-white text-primary"></i>
                    </div>
                    <h2 className="mb-1 mt-5 text-lg font-medium text-primary-text">Quick Grow</h2>
                    <p className="max-w-[250px] text-sm text-secondary-text lg:max-w-[400px]">Invest 1 Lakh, watch it grow fast with a 4% return.</p>
                  </a>
                  <a className="flex grow flex-col rounded-lg px-5 py-5 hover:bg-secondary lg:basis-[248px] xl:px-8" href="/solution2">
                    <div className="relative">
                      <i class="fa-solid fa-money-bill-wave text-4xl bg-white text-primary"></i>
                    </div>
                    <h2 className="mb-1 mt-5 text-lg font-medium text-primary-text">Triple Play</h2>
                    <p className="max-w-[250px] text-sm text-secondary-text lg:max-w-[400px]">Multiply your 5 Lakh into thrice its value, a smart choice.</p>
                  </a>
                  <a className="flex grow flex-col rounded-lg px-5 py-5 hover:bg-secondary lg:basis-[248px] xl:px-8" href="/solution3">
                    <div className="relative">
                      <i class="fa-solid fa-piggy-bank text-4xl bg-white text-primary"></i>
                    </div>
                    <h2 className="mb-1 mt-5 text-lg font-medium text-primary-text">Easy Earn</h2>
                    <p className="max-w-[250px] text-sm text-secondary-text lg:max-w-[400px]">Secure and easy 1 Lakh investment with a steady 3% gain</p>
                  </a>
                  <a className="flex grow flex-col rounded-lg px-5 py-5 hover:bg-secondary lg:basis-[248px] xl:px-8" href="/solution4">
                    <div className="relative">
                      <i class="fa-solid fa-coins text-4xl bg-white text-primary"></i>
                    </div>
                    <h2 className="mb-1 mt-5 text-lg font-medium text-primary-text">Silver Gains</h2>
                    <p className="max-w-[250px] text-sm text-secondary-text lg:max-w-[400px]">Invest 25 Lakh for stable, silver-lined returns at 1.5%</p>
                  </a>
                  <a className="flex grow flex-col rounded-lg px-5 py-5 hover:bg-secondary lg:basis-[248px] xl:px-8" href="/solution5">
                    <div className="relative">
                      <i class="fa fa-arrow-up text-4xl bg-white text-primary"></i>
                    </div>
                    <h2 className="mb-1 mt-5 text-lg font-medium text-primary-text">Zero To Peak</h2>
                    <p className="max-w-[250px] text-sm text-secondary-text lg:max-w-[400px]">Start with nothing, scale to financial peaks effortlessly.</p>
                  </a>
                  <a className="flex grow flex-col rounded-lg px-5 py-5 hover:bg-secondary lg:basis-[248px] xl:px-8" href="/solution6">
                    <div className="relative">
                      <i class="fa-solid fa-rocket text-4xl bg-white text-primary"></i>
                    </div>
                    <h2 className="mb-1 mt-5 text-lg font-medium text-primary-text">Mega Multiplier</h2>
                    <p className="max-w-[250px] text-sm text-secondary-text lg:max-w-[400px]">10 Lakh to 10 Crore: experience exponential wealth growth</p>
                  </a>
                </div>
              </div>
              <a href="/recommendations" className="rounded-lg lg:px-6 lg:py-2 text-primary-text lg:hover:underline decoration-2 underline-offset-8 font-bold">Recommendation</a>
              <a href="/pricing" className="rounded-lg lg:px-6 lg:py-2 text-primary-text lg:hover:underline decoration-2 underline-offset-8 font-bold">Pricing</a>
              <a href="/#contactUs" className="rounded-lg px-6 py-2 text-tertiary bg-primary font-bold text-center">Contact us</a>
            </div>
            <a href="#" className="absolute right-5 lg:invisible lg:hidden" onClick={() => setIsHamBurgerMenuOpen(!isHamBurgerMenuOpen)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75 12H20.25" stroke="#160042" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.75 6H20.25" stroke="#160042" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.75 18H20.25" stroke="#160042" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </a>
          </div>
        </nav>
      </div>
    </section>
  );
}

export default NavBar;
