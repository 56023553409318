function HomeHeader({ children, title = "", description = "Our platform is user-friendly, secure and designed to provide you with the tool and resource you need to make informed traders and maximise your profits." }) {
    return (
        <header>
            <div className="mx-auto w-full max-w-7xl px-5 -mt-16 py-16 md:px-10 md:py-24 lg:py-32">
                <div className="grid items-center justify-space-around gap-8 sm:gap-20 lg:grid-cols-2">
                    <div className="flex flex-col">
                        <h1 className="mb-4 text-[2.43rem] leading-[2.5rem] font-bold pb-4 md:text-6xl md:pb-0">
                            {
                                title === "" ?
                                    <>Elevate Your Wealth with <br></br> <span className="text-primary">Smart Investing 🚀</span></> :
                                    title
                            }
                        </h1>
                        <p className="mb-6 max-w-lg text-sm text-secondary-text sm:text-xl md:mb-10 lg:mb-12">
                            {description}
                        </p>
                    </div>
                    {children}
                </div>
            </div>
        </header>
    );
}

export default HomeHeader;