import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Solution1PageImage from "../components/assets/Solution1PageImage";
import HomeHeader from "../components/HomeHeader";

import EasySteps from "../components/EasySteps";


function SolutionPage1() {
    return (
        <>
            <div className="font-jakarta">
                <NavBar />
                <HomeHeader title="Quick Grow" description="Invest 1 Lakh, watch it grow fast with a 4% return.">
                    <Solution1PageImage />
                </HomeHeader>
                <section>
                    <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
                        {/* <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Quick Grow</h2> */}
                        <p class="text-base sm:text-lg text-primary-text">Imagine this: You have a sum of one lakh rupees to invest. You're looking for a reliable and profitable investment plan that can give you consistent returns. Well, we have just the plan for you! <br /><br />

                            Invest your one lakh rupees in our four-year investment program and sit back while your money works for you. You'll receive a Quarterly return of 12%, which means a steady income of Rs. 12,000 every Quarter for the next four years, so you can enjoy the fruits of your investment at regular intervals. <br /><br />

                            That's a total of 16 payments over the course of four years, all with the peace of mind that comes with investing in a trusted and established program. So why wait? Invest your money today and start enjoying the benefits of a smart investment choice.</p>

                    </div>
                </section>
                <EasySteps />
                <Footer />
                
            </div>
        </>
    );
}
export default SolutionPage1;
