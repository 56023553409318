import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import HomeHeader from "../components/HomeHeader";
import Solution4PageImage from "../components/assets/Solution4PageImage";
import EasySteps from "../components/EasySteps";


function SolutionPage4() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <HomeHeader title="Silver Gains" description="Invest 25 Lakh for stable, silver-lined returns at 1.5%.">
                <Solution4PageImage />
            </HomeHeader>
            <section>
            <div class="mx-auto w-full max-w-5xl px-5 py-12 md:px-10 md:py-16 lg:py-20">
            {/* <h2 class="mb-8 text-center text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16 text-primary-text">Silver Gains</h2> */}
            <p class="text-base sm:text-lg text-primary-text">Silver Gains is your gateway to prudent investment, inviting you to invest ₹25 lakh for a potential return of 1.5%. It's a strategic choice for seasoned investors who seek to blend long-term stability with gradual wealth enhancement, making it an ideal match for a well-balanced portfolio.</p>

            </div>
            </section>
            <EasySteps />
            <Footer />
        </div>
    );
}
export default SolutionPage4;
