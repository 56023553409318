import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import EasySteps from "../components/EasySteps";
import RecommendationPageImage from "../components/assets/RecommendationPageImage"
import google from "../components/assets/google.png"
// import AnalysisImage from "../components/assets/AnalysisImage"

function Recommendation() {
    return (
        <div className="font-jakarta">
            <NavBar />
            <header>
                <div class="mx-auto w-full max-w-7xl px-5 py-10 md:px-10">
                    <div class="grid items-center justify-space-around gap-8 sm:gap-20 lg:grid-cols-2">
                        <div class="flex flex-col">
                            <h1 class="mb-4 text-4xl font-bold md:text-6xl text-primary-text">Elevate Your Investments</h1>
                            <p class="mb-6 max-w-lg text-sm text-secondary-text sm:text-xl md:mb-10 lg:mb-12">uncover our expert-selected stocks and IPOs, each chosen to strategically boost your portfolio and drive your financial success.</p>
                            {/* <div class="flex items-center">
                                <a href="#" class="mr-5 items-center rounded-md bg-primary-text px-6 py-3 font-semibold text-tertiary md:mr-6 lg:mr-8">Let's Talk</a>
                                <a href="#" class="flex max-w-full items-center font-bold">
                                    <img src="https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a94bd85e6cf98_ArrowUpRight%20(4).svg" alt="" class="mr-2 inline-block max-h-4 w-5" />
                                    <p>View Showreel</p>
                                </a>
                            </div> */}
                        </div>
                        <RecommendationPageImage />
                    </div>
                </div>
            </header>
            <EasySteps />
            <section >
                <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div className="mx-auto max-w-3xl text-center">
                        <h2 className="mb-8 text-3xl font-bold md:mb-12 md:text-5xl lg:mb-16">Our Records</h2>
                    </div>
                    <div className="mb-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:mb-6 md:grid-cols-2 lg:mb-8">
                        <a className="grid grid-cols-1 gap-6 rounded-md shadow-lg shadow-grey-500/50 p-8 md:p-10" href="#">
                            <i class="fa fa-chart-line text-4xl bg-white text-primary"></i>
                            <h3 className="text-primary-text font-bold text-2xl -mb-4">Past History</h3>
                            <p className="text-secondary-text">Discover Top Stocks: Expertly curated for optimal returns and growth potential.</p>
                        </a>
                        <a className="grid grid-cols-1 gap-6 align-middle rounded-md shadow-lg shadow-grey-500/50 p-8 md:p-10" href="#">
                            <div className="flex justify-space-around items-center">
                                {/* <i class="bi bi-google text-4xl bg-white text-primary"></i> */}
                                <img src={google} className="max-w-[20%] max-h-[50%] "></img>
                                <div className="flex flex-col justify-center">
                                    <h3 className="text-primary-text font-bold text-2xl">Review us on Google</h3>
                                    {/* <p className="text-secondary-text">Explore Latest IPOs: Gain first access to promising, newly-listed companies.</p> */}
                                </div>
                            </div>

                        </a>
                    </div>
                </div>
            </section>
            <section>
                <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <div class="mx-auto flex max-w-3xl flex-col items-center text-center">
                        <h2 class="text-3xl font-bold md:text-5xl text-primary-text headtwo">Seamlessly Organize and Prioritize Tasks.</h2>
                        <p class="mx-auto mb-8 mt-4 text-secondary-text md:mb-12 lg:mb-16">Long-Term Strategy to Identify Wealth Compounders. We want to create Alpha and find prospective compounders. According to our theory, the upside will take care of itself if you can control the downside. invests up to 10% of total assets in a maximum of larger, liquid stocks.</p>
                    </div>
                    <ul class="grid gap-5 sm:grid-cols-2 md:gap-4 lg:gap-6">
                        <li class="rounded-md bg-secondary shadow-lg p-8 md:p-10">
                            <p class="mb-3 text-xl font-semibold text-primary-text">RESEARCH </p>
                            <div class="mb-4 mt-4 h-0.5 w-full bg-primary"></div>
                            <p class="text-sm text-secondary-text">The team does in-depth research to decide the criteria to be used for constituent screening. Stocks are first eliminated based on certain factors.</p>
                        </li>
                        <li class="rounded-md bg-secondary shadow-lg p-8 md:p-10">
                            <p class="mb-3 text-xl font-semibold text-primary-text">DEFINING THE UNIVERSE</p>
                            <div class="mb-4  mt-4 h-0.5 w-full bg-primary"></div>
                            <p class="text-sm text-secondary-text">Top 700 publicly traded companies by market cap listed on the national stock exchange of India</p>
                        </li>
                        <li class="rounded-md bg-secondary shadow-lg p-8 md:p-10">
                            <p class="mb-3 text-xl font-semibold text-primary-text">HISTORICAL BACKTESTING</p>
                            <div class="mb-4 mt-4 h-0.5 w-full bg-primary"></div>
                            <p class="text-sm text-secondary-text">Backtested since 2020 across multiple market cycles and time frames</p>
                        </li>
                        <li class="rounded-md bg-secondary shadow-lg p-8 md:p-10">
                            <p class="mb-3 text-xl font-semibold text-primary-text">CONSTITUENT SCREENING</p>
                            <div class="mb-4 mt-4 h-0.5 w-full bg-primary"></div>
                            <p class="text-sm text-secondary-text">Stocks are picked by quantitative and qualitative. model based on multiple factors.</p>
                        </li>
                    </ul>
                </div>
            </section>
            {/* <section className="bg-secondary">
                <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
                    <h2 class="mb-8 text-3xl text-center font-bold md:mb-12 md:text-4xl lg:mb-16 lg:text-5xl">Make every step user-centric</h2>
                    <div class="flex flex-col gap-8 lg:flex-row lg:gap-20">
                        <AnalysisImage className="inline-block object-cover lg:min-w-[30%]" />
                        <div class="flex flex-col gap-4">
                            <a class="flex max-w-l rounded-md p-4 text-[#222222] shadow-lg bg-tertiary" href="#w-tabs-1-data-w-pane-0">
                                <i class="fa fa-1 text-4xl bg-white text-primary p-4 pt-20 inline-block md:pt-4"></i>
                                <div class="ml-4 flex flex-col">
                                    <p class="font-bold">ONLINE & PAPERLESS PROCESS</p>
                                    <p class="text-sm text-[#636262]">Any IPO may be viewed, analyzed, shortlisted, and applied for with only money leaves few clicks on your phone or computer without the need for any paperwork.</p>
                                </div>
                            </a>
                            <a class="flex max-w-l rounded-md p-4 text-[#222222] shadow-lg bg-tertiary" href="#w-tabs-1-data-w-pane-1">
                                <i class="fa fa-2 text-4xl bg-white text-primary p-4 pt-20 inline-block md:pt-4"></i>
                                <div class="ml-4 flex flex-col">
                                    <p class="font-bold">ASBA APPLICATION PROCESS</p>
                                    <p class="text-sm text-[#636262]">Application Supported by Blocked Amount, or ASBA application. It makes sure that your money only leaves your bank account whenever you receive the IPO allocation.</p>
                                </div>
                            </a>
                            <a class="flex max-w-l rounded-md p-4 text-[#222222] shadow-lg bg-tertiary" href="#w-tabs-1-data-w-pane-2">
                                <i class="fa fa-3 text-4xl bg-white text-primary p-4 pt-20 inline-block md:pt-4"></i>
                                <div class="ml-4 flex flex-col">
                                    <p class="font-bold">QUICK & AUTOMATED REFUND</p>
                                    <p class="text-sm text-[#636262]">Your money will be instantly reimbursed to your bank account if you  It makes sure that your money only leaves your bank account whenever you receive are not selected for the IPO.</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />
        </div>
    );
}

export default Recommendation
