import React from "react";

function Solution2PageImage() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 500">
            <g>
                <path
                    fill="#e0e0e0"
                    d="M565.27 170.2a59.51 59.51 0 1166.15-92 59.5 59.5 0 01-66.15 92zm35.37-109.44a55.5 55.5 0 1027.57 19.8 55.52 55.52 0 00-27.57-19.8z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M673.45 221.33a7.38 7.38 0 01-10.4-1l-47-57.33a1.27 1.27 0 01.17-1.79l7.55-6.18a1.27 1.27 0 011.76.14l48.76 55.6a7.39 7.39 0 01-.84 10.56z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M566.29 167a59.51 59.51 0 1166.15-92 59.51 59.51 0 01-66.15 92zm35.37-109.4a55.48 55.48 0 1027.57 19.79 55.49 55.49 0 00-27.57-19.79z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M641.71 145.35H654v79.83a4.76 4.76 0 01-4.76 4.76h-2.73a4.76 4.76 0 01-4.76-4.76v-79.83h-.04z"
                    transform="rotate(-39.31 647.832 187.644)"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M538.67 102.32a12.42 12.42 0 01.09-1.87 16 16 0 01.26-2.18 28.13 28.13 0 01.55-2.85c.14-.52.27-1.07.41-1.64s.37-1.14.56-1.75c.36-1.22.91-2.46 1.45-3.78a46.19 46.19 0 014.68-8 45.19 45.19 0 016.33-6.79 38.73 38.73 0 013.17-2.46c.53-.36 1-.73 1.51-1l1.46-.87a26.11 26.11 0 012.57-1.35 16.54 16.54 0 012-.89 13.11 13.11 0 011.76-.62 12.12 12.12 0 01-1.68.83 18.07 18.07 0 00-1.95 1c-.74.41-1.62.82-2.51 1.42l-1.42.89c-.49.31-1 .69-1.47 1-1 .71-2.05 1.6-3.13 2.5a43.91 43.91 0 00-10.85 14.62c-.55 1.3-1.11 2.52-1.48 3.71-.2.6-.43 1.16-.58 1.72s-.3 1.1-.44 1.62c-.32 1-.46 2-.64 2.81a19.29 19.29 0 00-.35 2.15 11.58 11.58 0 01-.3 1.78z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M287.43 216.31L200.69 216.31 162.76 170.05 163.53 169.41 201.17 215.31 287.43 215.31 287.43 216.31z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M290.31 215.74a3.26 3.26 0 11-3.26-3.26 3.27 3.27 0 013.26 3.26z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M87.55 134.65a49.47 49.47 0 1150.26 48.89 49.66 49.66 0 01-50.26-48.89zm73.12-1.18a23.65 23.65 0 10-23.27 24.14 23.73 23.73 0 0023.27-24.14z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M87.55 134.65a49.66 49.66 0 0148.67-50.49l.42 25.93a23.76 23.76 0 00.76 47.52l.41 25.93a49.63 49.63 0 01-50.26-48.89z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M131.78 80.17c31.91-.51 57.37 24.25 57.88 56.31l-30.2.48c-.25-15.32-11.91-25-27.16-24.73z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M288 67.35a29.3 29.3 0 11-33.2-24.71A29.27 29.27 0 01288 67.35z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M281.05 68.35a22.33 22.33 0 11-25.3-18.83 22.31 22.31 0 0125.3 18.83z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M281 67.66a12.65 12.65 0 01-1-2.57 23.47 23.47 0 00-3.54-6.4 22.31 22.31 0 00-8.38-6.61 21.65 21.65 0 00-6.08-1.75 21.72 21.72 0 00-3.34-.19 22.24 22.24 0 00-3.43.33 23.05 23.05 0 00-3.34.87 21.55 21.55 0 00-3.07 1.33 20 20 0 00-2.73 1.72 21.1 21.1 0 00-2.37 2 22.47 22.47 0 00-3.54 4.51 23 23 0 00-2 4.58 23.64 23.64 0 00-1.18 7.24 12.82 12.82 0 010 2.74 6.52 6.52 0 01-.64-2.71 20.66 20.66 0 01.61-7.6 23.12 23.12 0 012-4.94 22.89 22.89 0 016.16-7.13A21.14 21.14 0 01248 51.2a22.82 22.82 0 013.3-1.47 25 25 0 013.6-.94 24.62 24.62 0 013.7-.35 21.42 21.42 0 013.6.24 21.84 21.84 0 013.4.76 22.82 22.82 0 018.25 4.56 22.66 22.66 0 013.57 4 20.62 20.62 0 013.19 6.92 6.65 6.65 0 01.39 2.74z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M263.37 84.38l.62 3.84-3.43.55L260 85a16.39 16.39 0 01-9-1.38l1.22-4.48a14.13 14.13 0 007 1.37l-.92-5.73c-3.95-.31-8.4-.9-9.19-5.81-.58-3.63 1.56-7.17 6.88-8.66l-.61-3.8 3.43-.55.6 3.73a15.75 15.75 0 017.29 1l-1 4.49a14.25 14.25 0 00-5.53-.94l.93 5.83c3.95.28 8.34.88 9.11 5.71.55 3.58-1.56 7.08-6.84 8.6zm-5.85-14.61l-.78-4.87c-1.75.7-2.31 1.81-2.13 2.95s1.32 1.68 2.91 1.92zm7.22 7.32c-.19-1.2-1.29-1.69-2.85-2l.76 4.73c1.65-.62 2.26-1.63 2.09-2.73z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M88.91 264.24a21.19 21.19 0 11-5.57-29.41 21.16 21.16 0 015.57 29.41z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M84.74 261.39A16.15 16.15 0 1180.5 239a16.12 16.12 0 014.24 22.39z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M85 261a9.2 9.2 0 01.74-1.84A17.3 17.3 0 0087 254a16.06 16.06 0 00-.11-3.63 15.87 15.87 0 00-1.12-4 14.51 14.51 0 00-1-2 15 15 0 00-1.35-1.9 15.29 15.29 0 00-1.68-1.74 17.56 17.56 0 00-2-1.5 16.62 16.62 0 00-2.2-1.17 15.19 15.19 0 00-2.29-.8 14.15 14.15 0 00-2.29-.42 15.43 15.43 0 00-2.24-.09 16 16 0 00-4.1.67 16.54 16.54 0 00-3.34 1.43 17 17 0 00-4.12 3.3c-.86.93-1.28 1.5-1.36 1.45a4.79 4.79 0 011-1.76 15 15 0 014.05-3.74 16.53 16.53 0 013.48-1.66 16.79 16.79 0 014.35-.83 16.25 16.25 0 012.41 0 15.33 15.33 0 012.48.43 16.38 16.38 0 014.85 2.1 17.41 17.41 0 012.14 1.62 16.92 16.92 0 011.81 1.89 16.44 16.44 0 012.49 4.24 16.2 16.2 0 011 8.15 14.71 14.71 0 01-1.69 5.25c-.6 1.18-1.08 1.71-1.17 1.71z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M67.49 261.26l-1.56 2.35-2.09-1.39 1.51-2.28a11.82 11.82 0 01-4.1-5.13l2.84-1.81a10.25 10.25 0 003.08 4.18l2.32-3.5c-1.95-2.1-4-4.6-2-7.59 1.47-2.22 4.34-3.06 7.9-1.24l1.54-2.33 2.07 1.41-1.51 2.28a11.43 11.43 0 013.4 4.09l-2.75 1.89a10.33 10.33 0 00-2.51-3.19l-2.36 3.55c2 2.09 4 4.55 2 7.51-1.39 2.15-4.27 2.94-7.78 1.2zm4-10.64l2-3c-1.27-.48-2.12-.17-2.57.53s-.12 1.57.6 2.47zm.26 7.44c.49-.74.14-1.54-.55-2.45l-1.91 2.89c1.23.5 2.05.22 2.49-.44z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M455.83 92a21.19 21.19 0 11-22.94-19.22A21.17 21.17 0 01455.83 92z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M450.81 92.44a16.15 16.15 0 11-17.49-14.65 16.13 16.13 0 0117.49 14.65z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M450.76 91.94a9.43 9.43 0 01-.6-1.9 17.16 17.16 0 00-2.28-4.77 15.93 15.93 0 00-5.78-5.12 16 16 0 00-2.06-.89 14.91 14.91 0 00-2.25-.62 15.74 15.74 0 00-2.4-.28 16.06 16.06 0 00-2.49.1 16.35 16.35 0 00-2.45.49 15.74 15.74 0 00-2.27.83 16.31 16.31 0 00-3.83 2.48 16.16 16.16 0 00-2.75 3.11 16.82 16.82 0 00-1.67 3.22 17.06 17.06 0 00-1.11 5.17 9.17 9.17 0 01-.13 2 4.81 4.81 0 01-.35-2 15.13 15.13 0 01.76-5.46 16.53 16.53 0 011.64-3.49 16.77 16.77 0 012.85-3.4 16.47 16.47 0 011.89-1.48 16.13 16.13 0 014.64-2.17 17.5 17.5 0 012.63-.53 18.72 18.72 0 012.69-.1 18.33 18.33 0 012.59.32 15.73 15.73 0 012.42.7 16.81 16.81 0 012.19 1 16.22 16.22 0 016 5.64 14.93 14.93 0 012 5.14 4.86 4.86 0 01.12 2.01z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M437.37 103.28l.29 2.79-2.5.26-.28-2.73a11.87 11.87 0 01-6.42-1.36l1.07-3.19a10.08 10.08 0 005 1.28l-.42-4.17c-2.84-.39-6-1-6.4-4.57-.27-2.65 1.43-5.12 5.33-6l-.28-2.77 2.49-.26.28 2.72a11.46 11.46 0 015.23 1l-.94 3.2a10.5 10.5 0 00-3.95-.91l.43 4.25c2.84.37 6 1 6.34 4.5.28 2.68-1.39 5.08-5.27 5.96zm-3.61-10.79l-.37-3.55c-1.29.43-1.74 1.21-1.66 2s.89 1.31 2.03 1.55zm4.9 5.58c-.09-.87-.86-1.27-2-1.54L437 100c1.26-.42 1.75-1.13 1.66-1.93z"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M699.83 281.85a27.62 27.62 0 11-13.62-36.55 27.58 27.58 0 0113.62 36.55z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M693.86 279.11a21 21 0 11-10.39-27.86 21 21 0 0110.39 27.86z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M694.13 278.51a12.52 12.52 0 01.55-2.53 22.4 22.4 0 00.48-6.88 21.59 21.59 0 00-.94-4.64 21 21 0 00-2.32-4.88 19 19 0 00-1.75-2.34 19.94 19.94 0 00-2.16-2.15 19.73 19.73 0 00-2.54-1.86 23.19 23.19 0 00-2.89-1.5 22.21 22.21 0 00-3.08-1 20 20 0 00-3.11-.51 20.85 20.85 0 00-6 .34 20.92 20.92 0 00-9.08 4.33 22.21 22.21 0 00-4.58 5.15c-.89 1.38-1.31 2.21-1.43 2.16a6.49 6.49 0 01.89-2.47 19.32 19.32 0 014.37-5.7 20.93 20.93 0 019.52-4.92 21.81 21.81 0 013.1-.47 21.27 21.27 0 013.29 0 20.76 20.76 0 013.36.53 23.11 23.11 0 013.33 1.09 23.46 23.46 0 013.11 1.62 21.24 21.24 0 012.73 2 21 21 0 012.29 2.34 20.76 20.76 0 011.84 2.55 21.47 21.47 0 012.34 5.29 22 22 0 01.8 4.95 19.52 19.52 0 01-1 7.12 6.06 6.06 0 01-1.12 2.38z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M671.66 282.75l-1.48 3.35-3-1.32 1.44-3.26a15.48 15.48 0 01-6.4-5.69l3.26-2.93a13.1 13.1 0 004.87 4.69l2.2-5c-3-2.27-6.19-5-4.3-9.3 1.4-3.18 4.91-4.88 9.88-3.34l1.46-3.32 3 1.32-1.44 3.26a14.86 14.86 0 015.27 4.51l-3.12 3a13.45 13.45 0 00-3.9-3.57l-2.24 5.09c3 2.24 6.15 5 4.28 9.18-1.36 3.14-4.82 4.83-9.78 3.33zm2.84-14.56l1.88-4.25c-1.75-.34-2.76.25-3.19 1.24s.18 2.01 1.31 3.01zm2 9.49c.47-1.05-.15-2-1.24-3l-1.82 4.13c1.61.31 2.61-.17 3.03-1.13z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M303.8 336.82H330.97V469.66999999999996H303.8z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M647.92 337.13H675.0899999999999V469.98H647.92z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M76.83 317.57H104V469.66999999999996H76.83z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M133.57 250H160.74V469.66999999999996H133.57z"
                ></path>
                <path
                    fill="#ebebeb"
                    d="M190.31 295.95H217.48000000000002V469.66999999999996H190.31z"
                ></path>
                <path fill="#ebebeb" d="M247.05 237.18H274.22V469.68H247.05z"></path>
                <path
                    fill="#ebebeb"
                    d="M360.54 238.31H387.71000000000004V469.67H360.54z"
                ></path>
                <path fill="#ebebeb" d="M417.28 314.3H444.45V469.67H417.28z"></path>
                <path
                    fill="#ebebeb"
                    d="M474.02 248.5H501.19V469.66999999999996H474.02z"
                ></path>
                <path fill="#ebebeb" d="M530.76 198.68H557.93V469.67H530.76z"></path>
                <path fill="#ebebeb" d="M587.5 247.37H614.67V469.67H587.5z"></path>
            </g>
            <path
                fill="#263238"
                d="M700.62 468.9c0 .14-145.79.26-325.6.26S49.38 469 49.38 468.9s145.76-.26 325.64-.26 325.6.11 325.6.26z"
            ></path>
            <g>
                <path fill="#455a64" d="M292.63 268.25H413V468.81H292.63z"></path>
                <path d="M292.63 268.25H413V468.81H292.63z" opacity="0.3"></path>
                <path fill="#455a64" d="M317.61 268.25H437.98V468.81H317.61z"></path>
                <path
                    fill="#455a64"
                    d="M317.24 366.59c.09.12-5.29 4.45-12 9.67s-12.24 9.37-12.33 9.26 5.29-4.44 12-9.67 12.24-9.37 12.33-9.26zM317.24 355.29c.09.11-5.29 4.44-12 9.66s-12.24 9.38-12.33 9.26 5.29-4.44 12-9.67 12.24-9.37 12.33-9.25zM317.24 344c.09.11-5.29 4.44-12 9.67S293 363 292.9 362.91s5.29-4.44 12-9.67 12.25-9.37 12.34-9.24zM317.24 332.67c.09.12-5.29 4.45-12 9.67s-12.24 9.37-12.34 9.26 5.29-4.44 12-9.67 12.25-9.37 12.34-9.26zM317.24 321.37c.09.11-5.29 4.44-12 9.67s-12.24 9.37-12.33 9.25 5.29-4.44 12-9.67 12.24-9.37 12.33-9.25zM317.24 310.06c.09.11-5.29 4.44-12 9.67S293 329.1 292.9 329s5.29-4.44 12-9.67 12.25-9.33 12.34-9.27zM317.24 298.75c.09.12-5.29 4.45-12 9.67s-12.24 9.37-12.33 9.26 5.29-4.44 12-9.67 12.24-9.37 12.33-9.26zM317.24 287.45c.09.11-5.29 4.44-12 9.67s-12.24 9.37-12.33 9.25 5.29-4.44 12-9.67 12.24-9.37 12.33-9.25zM317.24 276.14c.09.11-5.29 4.44-12 9.67s-12.24 9.37-12.33 9.26 5.29-4.44 12-9.67 12.24-9.4 12.33-9.26zM312.3 268.67c.09.12-4.18 3.59-9.54 7.75s-9.77 7.46-9.86 7.34 4.18-3.58 9.54-7.75 9.77-7.45 9.86-7.34zM298 268.46a10.2 10.2 0 01-2.4 2.2 10 10 0 01-2.73 1.79 20.67 20.67 0 015.13-4zM292.84 399.87c-.09-.12 5.29-4.45 12-9.67s12.24-9.38 12.32-9.26-5.28 4.44-12 9.67-12.23 9.39-12.32 9.26zM293.58 414.17c-.08-.11 5.29-4.44 12-9.67s12.24-9.37 12.33-9.26-5.29 4.44-12 9.67-12.24 9.37-12.33 9.26zM292.9 427.3c-.09-.11 5.29-4.44 12-9.67s12.24-9.37 12.33-9.26-5.29 4.45-12 9.67-12.23 9.38-12.33 9.26zM292.9 438.61c-.09-.11 5.29-4.44 12-9.67s12.24-9.37 12.33-9.26-5.29 4.44-12 9.67-12.23 9.37-12.33 9.26zM292.9 449.92c-.09-.12 5.29-4.45 12-9.67s12.24-9.38 12.33-9.26-5.29 4.44-12 9.67S293 450 292.9 449.92zM292.9 461.22c-.09-.11 5.29-4.44 12-9.67s12.24-9.37 12.33-9.26-5.29 4.45-12 9.67-12.23 9.38-12.33 9.26zM297.84 468.69c-.09-.12 4.18-3.59 9.54-7.75s9.77-7.45 9.86-7.34-4.18 3.58-9.54 7.75-9.77 7.45-9.86 7.34zM312.11 468.9a20.2 20.2 0 015.13-4 10.37 10.37 0 01-2.41 2.2 10.79 10.79 0 01-2.72 1.8z"
                ></path>
                <path fill="#455a64" d="M473.44 326.95H579.48V468.77H473.44z"></path>
                <path d="M473.44 326.95H579.48V468.77H473.44z" opacity="0.3"></path>
                <path fill="#455a64" d="M494.7 326.95H600.74V468.77H494.7z"></path>
                <path
                    fill="#455a64"
                    d="M494.7 413.44c.09.11-4.61 3.91-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.92 10.49-8.49 10.72-8.2 10.81-8.08zM494.7 403.54c.09.11-4.61 3.92-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.92 10.49-8.49 10.72-8.19 10.81-8.08zM494.7 393.64c.09.12-4.61 3.92-10.49 8.49s-10.72 8.2-10.81 8.08 4.61-3.91 10.49-8.49 10.72-8.19 10.81-8.08zM494.7 383.75c.09.11-4.61 3.91-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.92 10.49-8.49 10.72-8.2 10.81-8.08zM494.7 373.85c.09.11-4.61 3.92-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.91 10.49-8.49 10.72-8.19 10.81-8.08zM494.7 364c.09.12-4.61 3.92-10.49 8.49s-10.72 8.2-10.81 8.08 4.61-3.91 10.49-8.49 10.72-8.24 10.81-8.08zM494.7 354.06c.09.11-4.61 3.91-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.92 10.49-8.49 10.72-8.2 10.81-8.08zM494.7 344.16c.09.12-4.61 3.92-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.91 10.49-8.49 10.72-8.19 10.81-8.08zM494.7 334.27c.09.11-4.61 3.91-10.49 8.48s-10.72 8.2-10.81 8.08 4.61-3.91 10.49-8.49 10.72-8.19 10.81-8.07zM490.38 327.73c.09.11-3.64 3.16-8.33 6.81s-8.56 6.51-8.65 6.4 3.64-3.16 8.33-6.81 8.56-6.51 8.65-6.4zM477.89 327.55c.09.11-.84 1-2.08 1.95a8.28 8.28 0 01-2.41 1.5c-.09-.11.85-1 2.09-2a7.69 7.69 0 012.4-1.45zM494.7 452.33c.09.11-4.61 3.91-10.49 8.49s-10.72 8.18-10.81 8.08 4.61-3.92 10.49-8.49 10.72-8.19 10.81-8.08zM494.7 442.43c.09.12-4.61 3.92-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.91 10.49-8.49 10.72-8.19 10.81-8.08zM494.7 432.54c.09.11-4.61 3.91-10.49 8.49s-10.72 8.19-10.81 8.07 4.61-3.91 10.49-8.49 10.72-8.19 10.81-8.07zM494.7 422.64c.09.11-4.61 3.91-10.49 8.49s-10.72 8.19-10.81 8.08 4.61-3.92 10.49-8.49 10.72-8.19 10.81-8.08z"
                ></path>
                <path fill="#455a64" d="M132.62 372.33H246.31V468.9H132.62z"></path>
                <path d="M132.62 372.33H246.31V468.9H132.62z" opacity="0.3"></path>
                <path fill="#455a64" d="M153.08 372.33H266.77V468.9H153.08z"></path>
                <path
                    fill="#455a64"
                    d="M152.7 453.28c.08.12-4.34 3.7-9.88 8s-10.11 7.71-10.2 7.6 4.33-3.7 9.88-8 10.11-7.71 10.2-7.6zM152.7 444c.08.12-4.34 3.71-9.88 8s-10.11 7.71-10.2 7.6 4.33-3.7 9.88-8 10.11-7.76 10.2-7.6zM152.7 434.63c.08.11-4.34 3.7-9.88 8s-10.11 7.72-10.2 7.6 4.33-3.7 9.88-8 10.11-7.72 10.2-7.6zM152.7 425.3c.08.11-4.34 3.7-9.88 8s-10.11 7.72-10.2 7.61 4.33-3.71 9.88-8 10.11-7.72 10.2-7.61zM152.7 416c.08.12-4.34 3.7-9.88 8s-10.11 7.71-10.2 7.6 4.33-3.7 9.88-8 10.11-7.74 10.2-7.6zM152.7 406.65c.08.11-4.34 3.7-9.88 8s-10.11 7.71-10.2 7.6 4.33-3.7 9.88-8 10.11-7.72 10.2-7.6zM152.7 397.32c.08.11-4.34 3.7-9.88 8s-10.11 7.72-10.2 7.6 4.33-3.7 9.88-8 10.11-7.72 10.2-7.6zM152.7 388c.08.11-4.34 3.7-9.88 8s-10.11 7.72-10.2 7.61 4.33-3.7 9.88-8 10.11-7.73 10.2-7.61zM152.7 378.66c.08.12-4.34 3.7-9.88 8s-10.11 7.71-10.2 7.6 4.33-3.7 9.88-8 10.11-7.71 10.2-7.6zM148.62 372.5a94.59 94.59 0 01-7.84 6.43 99.38 99.38 0 01-8.16 6 99.62 99.62 0 017.84-6.43c4.42-3.41 8.07-6.11 8.16-6zM136.85 372.33c.09.11-.78.94-1.95 1.85a7 7 0 01-2.28 1.44c-.09-.11.79-.94 2-1.85a7.18 7.18 0 012.23-1.44z"
                ></path>
            </g>
            <g>
                <path
                    d="M418.49 259.75a6 6 0 00-2.93-.16c-30.56 3.39-64.41 5.69-95 9.08v.33a185.62 185.62 0 0060.44 7c1.91-.09 4.09-.11 5.39 1.3s1 3.94.57 6q-6.45 28.05-12.89 56.09c-.67 2.94-1.14 6.55 1.16 8.49 3.18 2.69 7.86-.5 10.54-3.68 18.2-21.59 26.46-49.61 34.25-76.75.82-2.75 1.15-6.6-1.53-7.7z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#0096FF"
                    d="M457.49 250.63a16 16 0 00-11.52-3.8l-7 22.39c5.16.22 10.31 2.89 15.29 1.52a10.9 10.9 0 007.6-9 13 13 0 00-4.37-11.11z"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M446.1 246.31c0-.18.76-.32 2.13-.31a17.31 17.31 0 015.77 1.09 15.62 15.62 0 017 5.45 14.49 14.49 0 012.34 4.85 12.89 12.89 0 01.17 5.87 11.9 11.9 0 01-2.56 5.35 11.1 11.1 0 01-4.54 3.15 16.22 16.22 0 01-8.86.57 27.77 27.77 0 01-5.52-1.71c-1.25-.54-1.9-.93-1.84-1.11s.81-.13 2.13.09 3.2.62 5.5.83a15.66 15.66 0 007.65-1 9 9 0 003.44-2.54 9.49 9.49 0 001.94-4.18A11.52 11.52 0 00459 254a15.15 15.15 0 00-5.74-5.13 25.65 25.65 0 00-5.14-1.79c-1.31-.36-2.04-.58-2.02-.77z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M382.31 328.62l-14.19 39.76 49.39 17.92c1.42-4.83-20.7-23.56-20.7-23.56L407 337.63z"
                ></path>
                <path
                    fill="#fff"
                    d="M382.32 355.06a2.14 2.14 0 00-2.23 1.79 2.07 2.07 0 001.76 2.23 2.26 2.26 0 002.35-1.92c0-1.05-1-2.26-2.09-2.11"
                    opacity="0.6"
                ></path>
                <path
                    fill="#f5f5f5"
                    d="M368.12 368.38l1.43-4 46.91 18.71s1.86 1.76 1.05 3.22z"
                ></path>
                <path
                    fill="#263238"
                    d="M397.7 362.78c-.09.23-1.34-.09-2.8.25s-2.47 1.13-2.66 1 .68-1.43 2.46-1.82 3.14.36 3 .57zM402.11 366.8c0 .25-1.23.28-2.41 1s-1.82 1.72-2.06 1.62.11-1.51 1.6-2.39 2.95-.47 2.87-.23zM402.8 374.06c-.24 0-.14-1.35 1-2.45s2.42-1.21 2.45-1-.93.69-1.81 1.6-1.39 1.92-1.64 1.85zM399.38 356.8c-.18.18-1.17-.55-2.56-1s-2.62-.35-2.67-.61 1.28-.74 2.92-.24 2.5 1.7 2.31 1.85zM400.33 350.51a5 5 0 01-2.47-.91 12.33 12.33 0 01-2.3-1.73 13 13 0 01-1.15-1.27 3.36 3.36 0 01-.53-.85 1.12 1.12 0 01.35-1.33 1.44 1.44 0 011.26-.17 3.78 3.78 0 01.88.47 9.83 9.83 0 011.35 1.08 9.45 9.45 0 011.74 2.33 4.35 4.35 0 01.72 2.53c-.14 0-.42-.91-1.27-2.21a10.14 10.14 0 00-1.75-2 9.81 9.81 0 00-1.27-1c-.47-.32-.91-.49-1.13-.3s-.1.1-.05.29a3.2 3.2 0 00.4.63 14.07 14.07 0 001 1.22 14.74 14.74 0 002.06 1.78 19 19 0 012.16 1.44z"
                ></path>
                <path
                    fill="#263238"
                    d="M399.89 350.74a4.21 4.21 0 01.21-2.62 8.82 8.82 0 011.32-2.59 9.31 9.31 0 011.18-1.27 2.19 2.19 0 012-.68 1.24 1.24 0 01.78 1.07 3.93 3.93 0 01-.07 1 5.86 5.86 0 01-.61 1.66 5.94 5.94 0 01-2 2.17c-1.43.93-2.54.86-2.53.77a21.62 21.62 0 002.16-1.28 5.9 5.9 0 001.62-2 5.19 5.19 0 00.48-1.44c.11-.55.07-1.05-.15-1.07a1.52 1.52 0 00-1.16.5 8.92 8.92 0 00-1.1 1.12 9.29 9.29 0 00-1.37 2.29c-.54 1.39-.65 2.38-.76 2.37zM329.17 215.61c-8.09.19-15.76 1.33-21.33 7.19-7.39 7.78-16.52 18.87-17 26.55-.84 11.49 6.16 14.9 11.16 17.71a14.12 14.12 0 007.35 1.69l93.47-.71 2.94-15.83-60.46-14.9 2.51-22.75z"
                ></path>
                <path
                    fill="#263238"
                    d="M347.82 214.56l29.41-1.36S428 216 438.86 226s9.48 21.22 5.87 32-35.44 80.14-35.44 80.14 2.58 4.41 1.13 8.8a11.63 11.63 0 01-8.3 7.91l-28.27-10.39s-3.84-1.35-1.13-5 4.51-4.28 4.51-4.28V328s9.94-18.06 10.84-20.54 10.42-39.24 10.42-39.24l6.33-10.87s-15.3-7.19-28.55-12.4l-31-7.63z"
                ></path>
                <path
                    fill="#455a64"
                    d="M395.52 343.16c-.06.07-.95-.64-2.48-1.62a33.35 33.35 0 00-13.2-5 29.1 29.1 0 01-2.94-.44 11.06 11.06 0 013 .06 27.94 27.94 0 017 1.68 27.63 27.63 0 016.35 3.4 11.17 11.17 0 012.27 1.92zM409.64 338.2a10.33 10.33 0 01-1.64-1.73c-.95-1.12-2.22-2.71-3.55-4.51s-2.47-3.5-3.25-4.74a9 9 0 01-1.16-2.08 11.68 11.68 0 011.48 1.87l3.37 4.63 3.41 4.59a10.56 10.56 0 011.34 1.97zM420 247.42c0 .09-1 .31-2.67.86a28.8 28.8 0 00-6.09 2.85 29.1 29.1 0 00-5.24 4.22c-1.21 1.23-1.87 2.08-1.94 2a9.59 9.59 0 011.66-2.29 23.81 23.81 0 015.23-4.42 24.23 24.23 0 016.26-2.76 9.65 9.65 0 012.79-.46z"
                ></path>
                <path
                    fill="#455a64"
                    d="M391.09 296.49a2.59 2.59 0 01.11-.61c.12-.45.26-1 .44-1.72l1.69-6.31 5.79-20.75c1.17-4.05 2.18-7.91 3.31-11.41a23.76 23.76 0 015.32-8.12 30.07 30.07 0 015.05-4.16c.66-.42 1.17-.73 1.53-.92a3.19 3.19 0 01.56-.28s-.71.48-2 1.37a34.33 34.33 0 00-4.89 4.26 23.75 23.75 0 00-5.13 8c-1.09 3.44-2.09 7.35-3.26 11.38L393.7 288l-1.86 6.26c-.22.69-.39 1.25-.53 1.69a2.48 2.48 0 01-.22.54zM365.08 243.56c-.15 0-.77-5.49-1.38-12.28s-1-12.32-.84-12.33.77 5.48 1.38 12.28.99 12.31.84 12.33zM316.14 258.23c-.09 0-.54-.85-1.46-2.15a22 22 0 00-4.27-4.49 21.6 21.6 0 00-5.39-3.05c-1.49-.58-2.46-.81-2.44-.9a8.35 8.35 0 012.57.53 18.64 18.64 0 019.85 7.7 8 8 0 011.14 2.36zM334.15 238.27c0 .09-1.3-.71-3.46-1.69a32.62 32.62 0 00-18.07-2.6c-2.35.34-3.77.76-3.79.66a3.39 3.39 0 011-.37 22.43 22.43 0 012.76-.67 29.61 29.61 0 0118.3 2.63 20.31 20.31 0 012.46 1.42 3.69 3.69 0 01.8.62z"
                ></path>
                <path
                    fill="#455a64"
                    d="M404.56 252.2a6.61 6.61 0 01-.73-.12l-2.08-.46-7.62-1.71c-6.43-1.44-15.33-3.37-25.2-5.33s-18.83-3.58-25.33-4.67l-7.7-1.32-2.09-.38a5.82 5.82 0 01-.72-.16 3.42 3.42 0 01.74.06l2.1.27c1.83.25 4.47.64 7.73 1.15 6.52 1 15.5 2.57 25.38 4.52s18.77 3.95 25.18 5.49c3.21.77 5.8 1.41 7.58 1.88l2.06.55a3.27 3.27 0 01.7.23z"
                ></path>
                <path
                    fill="#455a64"
                    d="M345.31 240a29.92 29.92 0 00-1.29-3 13 13 0 00-2.14-2.77 36.84 36.84 0 00-3.27-2.83 49.38 49.38 0 00-6.7-4.16 20.82 20.82 0 01-2.86-1.62 17.57 17.57 0 013 1.28 38.93 38.93 0 016.86 4.07 31.07 31.07 0 013.3 2.91 12.66 12.66 0 012.12 2.94 10.33 10.33 0 01.83 2.28 2.8 2.8 0 01.15.9z"
                ></path>
                <path
                    fill="#263238"
                    d="M449.42 254.13c.12.12-1.44 1.7-2.66 4.1s-1.61 4.58-1.78 4.55a11.93 11.93 0 014.44-8.65zM525.06 118.92a1 1 0 01-.09.14l-.27.4-1.1 1.54-4.24 5.71c-3.68 4.93-8.92 11.91-15.17 20.25l-.13.18-.18-.13-13.23-9.35.35-.06c-3.14 4.25-6.49 8.78-9.95 13.44-3.75 5.05-7.38 10-10.82 14.57l-.13.19-.19-.12-16.25-9.51.28-.06-14 17.5-3.94 4.83-1.05 1.27a4.13 4.13 0 01-.37.42 3.42 3.42 0 01.32-.46l1-1.31 3.84-4.93 13.87-17.62.12-.15.16.1 16.28 9.46-.33.07 10.8-14.59 10-13.43.15-.2.2.15 13.19 9.39h-.31l15.32-20.14 4.33-5.63 1.16-1.47c.12-.16.22-.28.31-.38s.06-.07.07-.07z"
                ></path>
                <path
                    fill="#263238"
                    d="M523.64 133.76a5.53 5.53 0 010-1.17c.06-.86.14-1.92.22-3.17.21-2.68.53-6.38.92-10.45v-.07l.35.27a786.08 786.08 0 01-9.87 3.45l-3 1a4.52 4.52 0 01-1.12.29 5.33 5.33 0 011.06-.48l2.95-1.15c2.5-.94 6-2.22 9.81-3.59l.39-.14V119c-.39 4.07-.77 7.76-1.07 10.43-.15 1.25-.28 2.31-.39 3.16a4.51 4.51 0 01-.25 1.17z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M408.93 177.23c-.46-3.06-9.6-13.18-9.6-13.18s-7.95-19.46-9.32-24.87a82.43 82.43 0 00-3.74-10c-3.2-5.41-4.78-5.51-10.26-8.59l-10.62-4.69-1.3-2.89a12 12 0 00-5.48-5.77L332 93.5l-8.24 14.55s-15.82 7.47-16.74 8.65c-3.25 4.14-1 70.63-1 70.63s-1.59 25.91-3.36 33.18-2.8 12.64-2.8 12.64l7.9-9.08c4.64-5.81 14.81-8.49 22.12-8.38l62.44.27-3.8-18.62 51.81 10.95s8.69-16.94 9.38-17.28-40.78-13.78-40.78-13.78z"
                ></path>
                <path
                    fill="#fff"
                    d="M330.65 113.76c-.44-.44 5 12.52 8.51 26.6 2.22 8.78 2.69 23 2.69 30.9l1.34 22.14v19.37l29.94-2-4.48-32.54s-3.65-21-4.67-29c-.93-7.31-5.38-36.61-5.38-36.61s-15.41 13.91-27.95 1.14z"
                ></path>
                <path
                    fill="#263238"
                    d="M397.09 189.51a2.15 2.15 0 010-.76 11.63 11.63 0 01.39-2 13.74 13.74 0 013.43-5.81 13.55 13.55 0 015.8-3.43 10.92 10.92 0 012-.4h.76c.01 0-1.05.2-2.65.76a14.35 14.35 0 00-9 9c-.52 1.59-.63 2.65-.73 2.64zM398.64 177.65a9.53 9.53 0 014.57-1.32 9.36 9.36 0 014.72.56c0 .16-2.11-.24-4.68 0s-4.56.92-4.61.76z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M307.78 116.1a29.21 29.21 0 00-16.84 20.32c-2.8 11.42-14.81 58.11-12.34 65.09s50.47 13.84 50.47 13.84l5.69-24-24.38-6.67s.31-22.22.62-31.43-3.22-37.15-3.22-37.15z"
                ></path>
                <path
                    fill="#fff"
                    d="M334.13 194.72L342.67 196.96 337.6 215.46 329.2 215.09 334.13 194.72z"
                ></path>
                <path
                    fill="#c78976"
                    d="M392.91 210.76l-7.69-8.17-15.84-4s-14.51 4.35-14.86 4.34-12.63-3.36-12.63-3.36l-4.29 15.73 22.12 1.52 25.26-1s1.31-1.23.53-2.07 4.23 1.42 4.82 1.45 1.73.48 2.51-.47c.53-.65 1.25-2.73.07-3.97z"
                ></path>
                <path
                    fill="#8b5d54"
                    d="M385.51 213.74A13.75 13.75 0 01383 213c-1.54-.49-3.66-1.14-6-1.75s-4.55-1.06-6.13-1.38a13.46 13.46 0 01-2.55-.59 10.87 10.87 0 012.62.2 55 55 0 016.2 1.22 52.69 52.69 0 016 1.92 10.17 10.17 0 012.37 1.12zM392.91 210.76a32.8 32.8 0 01-3.51-1.13c-2.15-.73-5.17-1.62-8.52-2.55s-6.32-2-8.58-2.12a21.32 21.32 0 00-3.65.14 2.86 2.86 0 011-.32 10.53 10.53 0 012.72-.22c2.34 0 5.41 1 8.72 2s6.36 1.89 8.5 2.72c1.06.41 1.92.77 2.5 1a3.38 3.38 0 01.82.48z"
                ></path>
                <path
                    fill="#263238"
                    d="M278.42 199s0 .4.12 1.13a5.31 5.31 0 001.67 2.73c2.09 1.94 6.07 3.56 11.12 4.47 2.31.76 4.94 1.36 7.75 2s5.84 1.28 9 1.93c6.39 1.3 13.46 2.68 20.94 4.08l-.32.21 1.69-7c1.35-5.5 2.66-10.84 3.89-15.89l.19.33-21.66-6.19a21.39 21.39 0 00-9.45-1.65 21 21 0 00-7.13 1.67 23.92 23.92 0 00-4.1 2.27l-1 .73-.35.23s.09-.11.31-.29.52-.45 1-.77a22.43 22.43 0 014.08-2.4 20.81 20.81 0 017.21-1.8 21.76 21.76 0 019.64 1.6l21.69 6.09.26.07-.06.26c-1.24 5.06-2.53 10.39-3.87 15.9-.59 2.39-1.16 4.74-1.7 7l-.07.26h-.26c-7.49-1.42-14.55-2.81-20.94-4.15-3.19-.66-6.21-1.32-9-2s-5.43-1.28-7.78-2.08c-5.1-1-9.1-2.66-11.2-4.7a5.28 5.28 0 01-1.64-2.86 3.27 3.27 0 01-.03-1.18z"
                ></path>
                <path
                    fill="#263238"
                    d="M311.7 186.28c-.07.07-.88-.68-2.35-1.66a22.46 22.46 0 00-13-3.78c-1.76 0-2.85.23-2.87.14a8.68 8.68 0 012.85-.54 19.85 19.85 0 0113.23 3.85 9 9 0 012.14 1.99z"
                ></path>
                <path
                    fill="#263238"
                    d="M314.89 147.18a6.61 6.61 0 010 1.46c0 .94-.1 2.3-.23 4-.27 3.35-.77 8-1.65 13a92.09 92.09 0 01-3 12.81c-.27.79-.55 1.51-.78 2.14l-.7 1.57a6.71 6.71 0 01-.75 1.26 8.26 8.26 0 01.55-1.35c.18-.47.38-1 .6-1.6s.45-1.35.69-2.15a111.24 111.24 0 002.85-12.77c.88-5 1.44-9.65 1.81-13 .16-1.58.3-2.91.41-4a6.82 6.82 0 01.2-1.37zM336.91 135.89s-.11-.28-.36-.77a4.62 4.62 0 00-1.57-1.71 3.9 3.9 0 00-3.39-.5 4.39 4.39 0 00-2.86 3.24l-.14.74-.32-.68a73 73 0 01-3.19-8.05 42.26 42.26 0 01-2.35-14.22c0-.93.13-1.76.18-2.5s.22-1.35.33-1.85a8.52 8.52 0 01.47-1.54 35.43 35.43 0 00-.6 5.9 45.22 45.22 0 002.49 14 79.53 79.53 0 003.12 8l-.47.06a4.79 4.79 0 013.22-3.54 4.2 4.2 0 013.68.65 4.52 4.52 0 011.54 1.88 3.8 3.8 0 01.2.62c.03.19.03.27.02.27zM362.8 138.24a9.87 9.87 0 01-.22-1.24 13.29 13.29 0 01-.21-3.5 7.43 7.43 0 012.38-4.61 4.32 4.32 0 012.86-1.09 3.8 3.8 0 013 1.43l-.43.26-.94-2.49c-1.84-4.91-3.48-9.37-4.64-12.6-.54-1.55-1-2.84-1.35-3.84a8.23 8.23 0 01-.43-1.44 9.63 9.63 0 01.62 1.36c.39 1 .89 2.26 1.5 3.78 1.25 3.2 2.94 7.64 4.78 12.55l.93 2.49.6 1.61-1-1.34a3.38 3.38 0 00-2.63-1.26 4 4 0 00-2.59.95 7.27 7.27 0 00-2.32 4.29 15.92 15.92 0 00.06 3.42 4.91 4.91 0 01.03 1.27zM380.52 140.82c.14 0 2.37 14.58 5 32.6s4.61 32.65 4.47 32.67-2.37-14.57-5-32.6-4.61-32.65-4.47-32.67z"
                ></path>
                <path
                    d="M325.4 128.33a32.85 32.85 0 002.6 11.77c1.13-1.4 2.39-2.88 4.12-3.3 2.15-.51 4.33.82 5.88 2.43a10.61 10.61 0 00-1.88-4.49 5.24 5.24 0 00-4.13-2.16 3.65 3.65 0 00-3.48 2.83l-3-7"
                    opacity="0.3"
                ></path>
                <path
                    fill="#263238"
                    d="M314.81 134.64c-.1 0-.07-1.09-.38-2.79a16.86 16.86 0 00-2.54-6.2 17.43 17.43 0 00-2.38-2.83 15.33 15.33 0 00-2.39-1.86c-1.46-.93-2.49-1.28-2.46-1.38a2.29 2.29 0 01.75.18 6 6 0 01.84.33 9 9 0 011.07.54 14.32 14.32 0 012.53 1.82 16.81 16.81 0 012.5 2.9 15.52 15.52 0 012.46 6.44 10.92 10.92 0 01.1 2.09c-.03.49-.06.76-.1.76z"
                ></path>
                <path
                    d="M381.42 147.31l6.69 47.14.33.75a101 101 0 00-7.02-47.89z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#e0e0e0"
                    d="M418.55 174.4l-21.75 38-53.61 1.17-.13 2.42 51.9-.38 1.65-.06 54.81.11 11.08-19.1 14.15-20.28a2 2 0 00-1.65-3.15l-54.72.21a2 2 0 00-1.73 1.06z"
                ></path>
                <path
                    fill="#c78976"
                    d="M462.5 196.61s2.81 2.58 2.37 4.77-.3 3.83-2.37 4a3.16 3.16 0 01-2.82 3.26s.64 3.44-3.37 4c0 0-1.22 3.68-3.75 2.79a22.19 22.19 0 01-4.15-1.93l-.74-1.34s-3.71-1.39-2.8-3.49c.56-1.27 2.48-1.6 2.48-1.6a3.6 3.6 0 01.24-3.26c.89-1.2 3.79-.21 3.79-.21a4.12 4.12 0 01.91-2.84c1-1.17 5.61 2 5.61 2l2.51-3z"
                ></path>
                <path
                    fill="#8b5d54"
                    d="M456.31 212.65a25.62 25.62 0 01-4.62-2.56 25.1 25.1 0 01-4.34-3 50 50 0 019 5.57zM459.68 208.62a22.43 22.43 0 01-4.29-2.27 22.08 22.08 0 01-4-2.74 22.07 22.07 0 014.29 2.27 21.5 21.5 0 014 2.74zM455.09 201.07c.09-.15 1.81.75 3.81 2a33.11 33.11 0 013.58 2.38c-.08.17-1.87-.64-3.87-1.92a21.12 21.12 0 01-3.52-2.46z"
                ></path>
                <path
                    fill="#263238"
                    d="M476.65 174.62a.82.82 0 01-.26 0h-3.77l-10.88.09-35.91.08h-2.62a2.13 2.13 0 00-2 .94c-.83 1.48-1.64 2.95-2.44 4.39l-4.62 8.34-7.85 14.11c-2.24 4-4.05 7.21-5.33 9.49l-1.48 2.56-.39.66a1.23 1.23 0 01-.16.22 1.52 1.52 0 01.11-.25c.09-.18.2-.4.35-.68.33-.64.8-1.51 1.38-2.62l5.19-9.57c2.21-4 4.83-8.84 7.75-14.16 1.46-2.67 3-5.46 4.59-8.36l2.45-4.43a2.7 2.7 0 012.44-1.19h2.62l35.91.08 10.88.09h3.77z"
                ></path>
                <path
                    fill="#fff"
                    d="M438.08 193.75a2.31 2.31 0 103.46-.4 2.47 2.47 0 00-3.46.4z"
                ></path>
                <path
                    fill="#c78976"
                    d="M359.14 110.62l-.3-8.63s11.46-2.51 10.89-14.09c-.27-5.6-1.78-17.07-3.26-27.43A21.61 21.61 0 00343.75 42l-1.11.07c-12 1.44-17.47 12.44-15.7 24.39l2.81 45.54a14.72 14.72 0 0015.12 13.8 14.73 14.73 0 0014.27-15.18z"
                ></path>
                <path
                    fill="#8b5d54"
                    d="M358.84 102a28 28 0 01-15.34-3.52s3.91 8.2 15.4 6.45z"
                ></path>
                <path
                    fill="#263238"
                    d="M365 71.34a1.66 1.66 0 01-1.49 1.77 1.58 1.58 0 01-1.79-1.39 1.66 1.66 0 011.52-1.72 1.59 1.59 0 011.76 1.34zM364.33 66.28c-.19.23-1.5-.62-3.28-.51s-3 1.09-3.23.88.08-.49.63-.94a4.54 4.54 0 015.2-.3c.57.39.78.77.68.87zM347.69 72.05a1.66 1.66 0 01-1.5 1.76 1.57 1.57 0 01-1.79-1.38 1.66 1.66 0 011.49-1.77 1.6 1.6 0 011.8 1.39zM346.87 67.69c-.19.23-1.5-.62-3.28-.51s-3 1.09-3.23.88.08-.49.63-.94a4.47 4.47 0 012.57-1 4.38 4.38 0 012.62.67c.58.42.82.8.69.9zM354.14 80.48a11.74 11.74 0 012.85-.7c.46-.07.88-.19.94-.5a2.39 2.39 0 00-.39-1.33L356 74.57c-2.17-4.81-3.77-8.77-3.58-8.86s2.11 3.75 4.28 8.55c.52 1.19 1 2.32 1.5 3.4a2.67 2.67 0 01.35 1.77 1.14 1.14 0 01-.71.71 3.14 3.14 0 01-.76.16 12 12 0 01-2.94.18zM349 82c.29 0 .41 1.9 2.15 3.17s3.78.93 3.81 1.2c0 .12-.44.4-1.3.48a4.87 4.87 0 01-3.18-.89 4.24 4.24 0 01-1.68-2.67c-.14-.77.05-1.29.2-1.29zM346.9 60.69c-.14.5-1.93.38-4 .76s-3.73 1-4 .63c-.14-.2.13-.67.78-1.18a6.75 6.75 0 012.94-1.24 6.85 6.85 0 013.18.18c.75.26 1.2.62 1.1.85zM363.36 61.17c-.29.43-1.54.08-3 .15s-2.71.44-3 0c-.14-.2 0-.62.56-1a4.1 4.1 0 012.4-.81 4.15 4.15 0 012.45.66c.52.39.72.83.59 1z"
                ></path>
                <path
                    fill="#263238"
                    d="M365 55.23s-3.5-7.06-7.1-8.54c-4.35-1.79-5.89 3-10 2.09s-8.26-2.59-12.19.78c-4.3 3.69-2 5.95-2 11.1.15 20.89-3.29 27-5 17.27-.19-1.07-3.22-1.74-3.94-6.25-.94-5.85-1.61-13-.55-17.24.89-3.59 2.75-6.14 6.18-9 5.42-4.58 13.22-5.15 18.56-5S365 46.12 365 55.23z"
                ></path>
                <path
                    fill="#c78976"
                    d="M329.49 77.57a3.14 3.14 0 00-3.57-2.82c-2.15.34-4.48 1.71-4.06 6.17.74 7.89 8.52 5.52 8.51 5.29s-.54-5.34-.88-8.64z"
                ></path>
                <path
                    fill="#8b5d54"
                    d="M328.37 83.4s-.13.11-.35.24a1.37 1.37 0 01-1 .12c-.87-.23-1.69-1.49-1.87-2.91a4.53 4.53 0 01.21-2 1.61 1.61 0 01.93-1.16.72.72 0 01.85.3c.12.22.09.38.13.39s.16-.15.07-.47a1 1 0 00-.36-.47 1.07 1.07 0 00-.78-.13 1.93 1.93 0 00-1.32 1.36 4.63 4.63 0 00-.27 2.25c.2 1.58 1.15 3 2.32 3.22a1.49 1.49 0 001.24-.33c.21-.21.23-.4.2-.41z"
                ></path>
                <path
                    fill="#263238"
                    d="M328.71 62.23a7.43 7.43 0 003.44 1.34 2.57 2.57 0 002.75-2c.06-.55-.11-1.19.22-1.63s1.21-.43 1.88-.4a5.06 5.06 0 005-3.94 2.12 2.12 0 002.86.09 11.32 11.32 0 002-2.44 3.25 3.25 0 012.63-1.52 8.16 8.16 0 001.75.27c1-.16 1.46-1.4 1.56-2.44s.14-2.26 1-2.87 1.86-.34 2.83-.25 2.19-.22 2.37-1.17a1.89 1.89 0 00-.44-1.42 5.13 5.13 0 00-6.17-1.56 7 7 0 00-12.84-.56 7.05 7.05 0 00-11.1 3.27 5.12 5.12 0 00-5.3 7.79 4.86 4.86 0 00-2.44 4c.08 1.64 1.77 3.18 3.33 2.65A2.83 2.83 0 00329.1 62"
                ></path>
                <path
                    fill="#455a64"
                    d="M353.13 44.59v.14a3.82 3.82 0 010 .43 5.16 5.16 0 01-.48 1.56 5.68 5.68 0 01-1.64 2 5.35 5.35 0 01-3.19 1.12l.12-.18a2.82 2.82 0 010 2.19 8 8 0 01-1.23 2 6.06 6.06 0 01-.83 1 2.75 2.75 0 01-1.23.55 2.89 2.89 0 01-2.6-.76l.29-.1a6 6 0 01-2.26 4.26 4.33 4.33 0 01-2.55 1 8 8 0 01-2.6-.4l.21-.11a6.27 6.27 0 01-1.72 2.56 3.36 3.36 0 01-2.71.92 4.38 4.38 0 01-3.57-3.21l.19.08a4.08 4.08 0 01-2.7.56 4.35 4.35 0 01-2-.73 3.89 3.89 0 01-.94-.91c-.16-.25-.23-.39-.23-.39a4.92 4.92 0 001.25 1.19 4.34 4.34 0 001.89.65 3.84 3.84 0 002.54-.58l.15-.09v.17a4.11 4.11 0 003.37 3 3 3 0 002.46-.86 5.93 5.93 0 001.61-2.43l.06-.15h.15a7.44 7.44 0 002.48.39 4 4 0 002.35-.94 5.6 5.6 0 002.14-4v-.37l.26.27a2.67 2.67 0 003.39.2 5.82 5.82 0 00.78-.91 7.61 7.61 0 001.21-1.94 2.6 2.6 0 00.05-2l-.07-.18h.19a5.15 5.15 0 003.06-1 5.65 5.65 0 001.63-1.88 6.14 6.14 0 00.72-2.12z"
                ></path>
                <path
                    fill="#263238"
                    d="M357.08 72.74a12.78 12.78 0 01-.52-1.85 7.79 7.79 0 01.82-5 7.63 7.63 0 012.36-2.65 7.78 7.78 0 013.81-1.38 7.53 7.53 0 012.21.14 7.21 7.21 0 012.15.81 7.68 7.68 0 013.8 8.25 7.28 7.28 0 01-.8 2.16 7 7 0 01-1.32 1.76 7.8 7.8 0 01-3.53 2 7.92 7.92 0 01-3.55.06 7.82 7.82 0 01-4.36-2.66 13.57 13.57 0 01-1.07-1.6 3.51 3.51 0 00.2.46 3.38 3.38 0 00.3.53 5.27 5.27 0 00.47.68 7.86 7.86 0 008.07 2.77 8 8 0 003.67-2 7.4 7.4 0 001.38-1.83 7.18 7.18 0 00.83-2.24 8 8 0 00-3.94-8.58 7.78 7.78 0 00-2.24-.83 7.41 7.41 0 00-2.29-.13 7.9 7.9 0 00-6.34 4.21 7.79 7.79 0 00-.75 5.17 5.9 5.9 0 00.21.81 3.3 3.3 0 00.21.57 2.69 2.69 0 00.22.37zM336.67 73.94a18.15 18.15 0 01-.52-1.85 7.86 7.86 0 01.83-5 7.61 7.61 0 012.35-2.65 7.71 7.71 0 013.82-1.44 7.14 7.14 0 012.2.14 7.37 7.37 0 012.16.81 7.65 7.65 0 013.79 8.24 7.6 7.6 0 01-2.12 3.93 7.68 7.68 0 01-7.07 2.06 7.74 7.74 0 01-4.37-2.66 14.13 14.13 0 01-1.07-1.59 3 3 0 00.21.46 3.23 3.23 0 00.29.53 5.44 5.44 0 00.48.68 7.8 7.8 0 004.41 2.8 7.9 7.9 0 007.32-2.08 7.38 7.38 0 001.39-1.82 7.78 7.78 0 00.83-2.25 7.94 7.94 0 00-4-8.57 7.5 7.5 0 00-2.24-.83 7.4 7.4 0 00-2.29-.13 8 8 0 00-3.94 1.45 7.87 7.87 0 00-2.4 2.76 7.77 7.77 0 00-.74 5.17 4.61 4.61 0 00.21.8 3.14 3.14 0 00.21.58 3.46 3.46 0 00.26.46z"
                ></path>
                <path
                    fill="#263238"
                    d="M351.58 69a5.39 5.39 0 014.84-.25s-.2-.23-.64-.43a4 4 0 00-3.61.19c-.42.2-.62.42-.59.49zM328.77 75.48a24.89 24.89 0 003.68-1.73 25.94 25.94 0 003.54-2 26.19 26.19 0 00-3.68 1.73 28.13 28.13 0 00-3.54 2z"
                ></path>
            </g>
            <g>
                <path
                    fill="#0096FF"
                    d="M215.71 393.9a32.58 32.58 0 11-17.85-42.42 32.55 32.55 0 0117.85 42.42z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M208.53 391a24.83 24.83 0 11-13.6-32.33 24.8 24.8 0 0113.6 32.33z"
                ></path>
                <path
                    fill="#263238"
                    d="M208.53 391a2.54 2.54 0 01.11-.39l.37-1.12.27-.82c.09-.31.16-.65.24-1a16.68 16.68 0 00.49-2.56 23.67 23.67 0 00-.08-7.11 24.65 24.65 0 00-23.4-20.87 25.53 25.53 0 00-3.49.08c-.59.07-1.17.18-1.76.27a11.74 11.74 0 00-1.76.38 29.4 29.4 0 00-3.48 1.13 27.25 27.25 0 00-3.3 1.72 23.68 23.68 0 00-5.82 4.92 24.36 24.36 0 00-5.86 14.33 24 24 0 00.69 7.59 23 23 0 002.84 6.79 22.64 22.64 0 004.49 5.44 24.7 24.7 0 0031.32 1.51 23.4 23.4 0 005-5 18.13 18.13 0 001.45-2.16c.2-.33.39-.62.54-.9s.27-.54.38-.78l.53-1.05a2.87 2.87 0 01.19-.36 2.06 2.06 0 01-.14.38l-.48 1.08-.36.79c-.15.29-.34.59-.52.92a18.75 18.75 0 01-1.43 2.2 23.32 23.32 0 01-5 5.13 24.47 24.47 0 01-8.53 4.19 24.84 24.84 0 01-11.51.38 25.23 25.23 0 01-11.77-5.95 27.43 27.43 0 01-2.49-2.56 25.29 25.29 0 01-2.1-3 29.66 29.66 0 01-1.73-3.31 27.35 27.35 0 01-1.18-3.62 24.1 24.1 0 01-.72-7.74 26.75 26.75 0 011.79-7.81 27 27 0 014.2-6.83 24.25 24.25 0 015.94-5 28.42 28.42 0 013.38-1.74 27.18 27.18 0 013.55-1.15 12.15 12.15 0 011.79-.39c.6-.08 1.2-.2 1.8-.26a25.57 25.57 0 0116.13 3.92 25.05 25.05 0 0111.1 17.3 23.85 23.85 0 010 7.19 18 18 0 01-.54 2.57c-.09.37-.17.71-.26 1l-.3.82c-.18.46-.32.83-.42 1.1a2 2 0 01-.16.35z"
                ></path>
                <path
                    d="M208.26 391.13c-.15-.05.17-1.1.52-3A26.24 26.24 0 00209 380a25.8 25.8 0 00-1.3-5.41 24.8 24.8 0 00-2.93-5.62 24.06 24.06 0 00-4.77-5.08 23.71 23.71 0 00-3.06-2.06 23.8 23.8 0 00-7.09-2.64 23 23 0 00-3.66-.44 22.12 22.12 0 00-3.56.11 22.8 22.8 0 00-3.37.6 24.71 24.71 0 00-5.9 2.34 26 26 0 00-4.53 3.24 26.67 26.67 0 00-5.12 6.3c-1 1.67-1.43 2.67-1.57 2.62s.11-1.13.92-3a23.06 23.06 0 014.86-6.94 25.06 25.06 0 014.67-3.62 25.41 25.41 0 016.24-2.67 24.28 24.28 0 013.61-.71 23.1 23.1 0 013.83-.17 24.51 24.51 0 014 .45 25.76 25.76 0 013.94 1.11 26.08 26.08 0 013.7 1.74 24.71 24.71 0 013.29 2.24 25 25 0 012.78 2.64 24.58 24.58 0 012.27 2.91 25 25 0 014.12 11.88 23.2 23.2 0 01-.87 8.43c-.59 1.96-1.14 2.92-1.24 2.88z"
                    opacity="0.3"
                ></path>
                <path
                    d="M182.55 396.33l-1.58 4-3.6-1.41 1.54-3.92c-3.47-1.61-6.45-4.07-7.82-6.39l3.69-3.61a15.65 15.65 0 006 5.29l2.35-6c-3.61-2.54-7.54-5.62-5.53-10.76 1.5-3.81 5.55-6 11.48-4.41l1.57-4 3.59 1.41-1.53 3.92a17.42 17.42 0 016.43 5.05l-3.53 3.72a15.7 15.7 0 00-4.77-4l-2.39 6.11c3.62 2.5 7.48 5.55 5.5 10.62-1.49 3.75-5.49 5.91-11.4 4.38zm2.64-17.3l2-5.1c-2.08-.32-3.24.43-3.71 1.61s.32 2.37 1.71 3.46zm2.81 11.09c.49-1.26-.28-2.35-1.62-3.5l-1.94 5c1.92.28 3.08-.35 3.56-1.5z"
                    opacity="0.3"
                ></path>
                <path fill="#0096FF" d="M245.36 434.68H307.92V446.04H245.36z"></path>
                <path
                    fill="#263238"
                    d="M251.49 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM248.07 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM259 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM255.58 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM266.52 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM263.1 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM274 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM270.61 446.12a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27zM281.55 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM278.13 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM289.07 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM285.65 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM296.59 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM293.16 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM304.1 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM300.68 446.12a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M307.91 434.68c0 .15-14.1.26-31.49.26s-31.5-.11-31.5-.26 14.1-.26 31.5-.26 31.49.12 31.49.26z"
                ></path>
                <path fill="#0096FF" d="M240.45 445.95H303.01V457.31H240.45z"></path>
                <path
                    fill="#263238"
                    d="M246.58 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM243.16 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM254.1 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM250.68 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM261.62 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM258.19 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM269.13 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM265.71 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM276.65 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM273.23 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM284.17 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM280.74 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM291.68 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM288.26 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM299.2 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM295.78 457.39a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M303 446c0 .15-14.07.26-31.42.26s-31.43-.11-31.43-.26 14.07-.26 31.43-.26 31.42.07 31.42.26z"
                ></path>
                <path fill="#0096FF" d="M236.43 457.39H298.99V468.75H236.43z"></path>
                <path
                    fill="#263238"
                    d="M242.56 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM239.14 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM250.08 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM246.66 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM257.6 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM254.18 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM265.11 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM261.69 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM272.63 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM269.21 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM280.15 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM276.72 468.83a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27zM287.66 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM284.24 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM295.18 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM291.76 468.83a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M299 457.39c0 .15-14.07.26-31.42.26s-31.43-.11-31.43-.26 14.07-.26 31.43-.26 31.42.12 31.42.26z"
                ></path>
                <path
                    d="M249 446.07a95.71 95.71 0 0028.9 2.1c9.6-.64 19.29-2.05 28.91-1.93zM237.2 457.54a95.71 95.71 0 0028.9 2.1c9.6-.64 19.29-2.05 28.91-1.93z"
                    opacity="0.3"
                ></path>
                <ellipse
                    cx="246.74"
                    cy="435.36"
                    fill="#fff"
                    rx="0.79"
                    ry="0.26"
                ></ellipse>
                <path
                    fill="#fff"
                    d="M256.21 435.39a24.91 24.91 0 01-7.18 0 24.91 24.91 0 017.18 0zM266.14 435.39a24.91 24.91 0 01-7.18 0 24.91 24.91 0 017.18 0z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M499.25 424.92a32.58 32.58 0 11-41.92-19 32.55 32.55 0 0141.92 19z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M492 427.65a24.83 24.83 0 11-31.95-14.47A24.79 24.79 0 01492 427.65z"
                ></path>
                <path
                    fill="#263238"
                    d="M492 427.65a2.87 2.87 0 01-.19-.36l-.49-1.07-.36-.79c-.14-.29-.33-.58-.51-.91a18.52 18.52 0 00-1.39-2.21 23.59 23.59 0 00-4.9-5.16 24.65 24.65 0 00-31.34.59 25 25 0 00-2.5 2.44c-.39.45-.74.93-1.12 1.39a13.94 13.94 0 00-1 1.48 29 29 0 00-1.78 3.19 28.11 28.11 0 00-1.25 3.51 23.56 23.56 0 00-.92 7.56 24.26 24.26 0 005.44 14.5 23.58 23.58 0 005.67 5.09 28.9 28.9 0 003.25 1.82A28.29 28.29 0 00462 460a27 27 0 003.5.75 27.57 27.57 0 003.5.19 24.81 24.81 0 0012.46-3.53 24.8 24.8 0 0011.54-16.7 23.83 23.83 0 00.29-7.11 19.27 19.27 0 00-.41-2.57c-.08-.37-.13-.72-.21-1s-.18-.58-.25-.83c-.14-.48-.25-.85-.34-1.13a2 2 0 01-.09-.39 2.24 2.24 0 01.14.37c.1.28.23.65.39 1.12l.27.83c.09.31.16.65.24 1a17.38 17.38 0 01.46 2.59 23.3 23.3 0 01-.2 7.18 25 25 0 01-11.6 17 25.4 25.4 0 01-16.24 3.45 26.37 26.37 0 01-3.57-.75 29 29 0 01-3.52-1.26 28.44 28.44 0 01-3.32-1.84 24.15 24.15 0 01-5.79-5.19 26.61 26.61 0 01-4-6.95 26.9 26.9 0 01-1.56-7.86 24.25 24.25 0 01.95-7.72 22.25 22.25 0 013.11-6.83 11.2 11.2 0 011.05-1.5c.38-.48.74-1 1.14-1.42a24.92 24.92 0 012.56-2.48 25.18 25.18 0 0111.94-5.61 25 25 0 0119.9 5.15 23.4 23.4 0 014.87 5.28 16 16 0 011.36 2.24c.18.34.36.64.5.93l.33.81.45 1.09a2.06 2.06 0 01.15.34z"
                ></path>
                <path
                    d="M491.91 428c-.14.06-.62-.93-1.66-2.57a26.71 26.71 0 00-5.33-6.13 26 26 0 00-4.63-3.08 24.64 24.64 0 00-6-2.13 22.15 22.15 0 00-3.39-.49 22.41 22.41 0 00-3.57 0 24.5 24.5 0 00-3.64.56 27 27 0 00-3.61 1.16 26.2 26.2 0 00-3.38 1.73 23.24 23.24 0 00-3 2.16 22.16 22.16 0 00-2.53 2.5 22.91 22.91 0 00-2.07 2.74 24.83 24.83 0 00-2.73 5.72 25.32 25.32 0 00-1.13 5.46 26.85 26.85 0 00.53 8.1c.42 1.9.77 2.93.63 3s-.68-.9-1.34-2.8a23.18 23.18 0 01-1.06-8.43 25.66 25.66 0 011-5.83 25.22 25.22 0 012.77-6.2 23.82 23.82 0 012.16-3 23.24 23.24 0 012.7-2.74 25.42 25.42 0 013.2-2.36 25.08 25.08 0 017.55-3.1 24.53 24.53 0 017.77-.55 25 25 0 0110 3.05 25.8 25.8 0 014.78 3.46 23.17 23.17 0 015.09 6.77c.74 1.84.98 2.92.89 3z"
                    opacity="0.3"
                ></path>
                <path
                    d="M476.59 449.25l1.58 4-3.6 1.41-1.57-3.89c-3.64 1.18-7.49 1.4-10.07.63l.25-5.16a15.61 15.61 0 008-.18l-2.36-6c-4.37.6-9.35 1-11.36-4.13-1.49-3.81 0-8.16 5.42-11l-1.57-4 3.6-1.41 1.53 3.92a17.55 17.55 0 018.16-.67l-.06 5.13a15.89 15.89 0 00-6.24.29l2.4 6.11c4.36-.63 9.26-1 11.25 4.05 1.48 3.7.05 8.01-5.36 10.9zm-9.83-14.48l-2-5.1c-1.73 1.17-2.08 2.51-1.62 3.7s1.86 1.52 3.62 1.4zm9.58 6.24c-.49-1.26-1.8-1.53-3.56-1.47l2 5c1.59-1.13 2.01-2.38 1.56-3.54z"
                    opacity="0.3"
                ></path>
                <path fill="#0096FF" d="M531.19 434.62H593.75V445.98H531.19z"></path>
                <path
                    fill="#263238"
                    d="M537.32 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM533.9 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM544.84 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM541.42 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM552.36 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM548.93 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM559.87 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM556.45 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM567.39 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM564 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM574.91 446.06a58.94 58.94 0 010-11.27 61.19 61.19 0 010 11.27zM571.48 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM582.42 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM579 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM589.94 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM586.52 446.06a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M593.75 434.62c0 .15-14.1.26-31.5.26s-31.5-.11-31.5-.26 14.1-.26 31.5-.26 31.5.12 31.5.26z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M526.29 445.89H588.8499999999999V457.25H526.29z"
                ></path>
                <path
                    fill="#263238"
                    d="M532.42 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM529 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM539.94 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM536.51 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM547.45 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM544 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM555 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM551.55 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM562.48 457.33a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27zM559.06 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM570 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM566.58 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM577.52 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM574.1 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM585 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM581.61 457.33a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M588.84 445.89c0 .15-14.06.26-31.42.26S526 446 526 445.89s14.07-.26 31.42-.26 31.42.12 31.42.26z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M522.27 457.33H584.8299999999999V468.69H522.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M528.4 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM525 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM535.92 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM532.5 468.77a58.94 58.94 0 010-11.27 61.19 61.19 0 010 11.27zM543.43 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM540 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM551 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM547.53 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM558.47 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM555 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM566 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM562.56 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM573.5 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM570.08 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM581 468.77a58.94 58.94 0 010-11.27 61.19 61.19 0 010 11.27zM577.59 468.77a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M584.83 457.33c0 .15-14.07.26-31.43.26s-31.42-.11-31.42-.26 14.07-.26 31.42-.26 31.43.12 31.43.26z"
                ></path>
                <path
                    d="M534.79 446a95.64 95.64 0 0028.89 2.1c9.6-.64 19.3-2.05 28.92-1.93zM523 457.48a95.64 95.64 0 0028.89 2.1c9.6-.64 19.3-2.05 28.92-1.93z"
                    opacity="0.3"
                ></path>
                <ellipse
                    cx="532.58"
                    cy="435.3"
                    fill="#fff"
                    rx="0.79"
                    ry="0.26"
                ></ellipse>
                <path
                    fill="#fff"
                    d="M542.05 435.33a24.91 24.91 0 01-7.18 0 24.91 24.91 0 017.18 0zM552 435.33a24.91 24.91 0 01-7.18 0 24.91 24.91 0 017.18 0z"
                ></path>
                <path fill="#0096FF" d="M153.24 411.88H215.8V423.24H153.24z"></path>
                <path
                    fill="#263238"
                    d="M159.37 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM156 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM166.89 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM163.47 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM174.41 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM171 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM181.92 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM178.5 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM189.44 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM186 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM197 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM193.53 423.32a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27zM204.47 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM201.05 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM212 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM208.57 423.32a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M215.8 411.88c0 .15-14.07.26-31.42.26S153 412 153 411.88s14.07-.26 31.43-.26 31.37.12 31.37.26z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M149.15 423.42H211.71V434.78000000000003H149.15z"
                ></path>
                <path
                    fill="#263238"
                    d="M155.28 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM151.86 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM162.8 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM159.37 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM170.31 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM166.89 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM177.83 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM174.41 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM185.35 434.86a58.94 58.94 0 010-11.27 61.19 61.19 0 010 11.27zM181.92 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM192.86 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM189.44 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM200.38 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM197 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM207.89 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM204.47 434.86a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M211.71 423.42c0 .15-14.07.26-31.43.26s-31.42-.11-31.42-.26 14.07-.26 31.42-.26 31.43.12 31.43.26z"
                ></path>
                <path fill="#0096FF" d="M154.26 434.86H216.82V446.22H154.26z"></path>
                <path
                    fill="#263238"
                    d="M160.39 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM157 446.3a61.19 61.19 0 010-11.3 61.19 61.19 0 010 11.27zM167.91 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM164.49 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM175.42 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM172 446.3a61.19 61.19 0 010-11.3 61.19 61.19 0 010 11.27zM182.94 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM179.52 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM190.46 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM187 446.3a61.19 61.19 0 010-11.3 61.19 61.19 0 010 11.27zM198 446.3a61.19 61.19 0 010-11.3 61.19 61.19 0 010 11.27zM194.55 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM205.49 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM202.07 446.3a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM213 446.3a61.19 61.19 0 010-11.3 61.19 61.19 0 010 11.27zM209.58 446.3a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M216.82 434.86c0 .15-14.11.26-31.5.26s-31.5-.11-31.5-.26 14.1-.26 31.5-.26 31.5.12 31.5.26z"
                ></path>
                <path
                    fill="#0096FF"
                    d="M149.36 446.13H211.92000000000002V457.49H149.36z"
                ></path>
                <path
                    fill="#263238"
                    d="M155.49 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM152.07 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM163 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM159.58 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM170.52 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM167.1 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM178 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM174.62 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM185.55 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM182.13 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM193.07 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM189.65 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM200.59 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM197.16 457.57a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27zM208.1 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM204.68 457.57a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M211.91 446.13c0 .14-14.07.26-31.42.26s-31.42-.12-31.42-.26 14.06-.26 31.42-.26 31.42.13 31.42.26z"
                ></path>
                <path fill="#0096FF" d="M155.63 457.65H218.19V469.01H155.63z"></path>
                <path
                    fill="#263238"
                    d="M161.76 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM158.34 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM169.27 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM165.85 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM176.79 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM173.37 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM184.31 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM180.88 469.09a61.19 61.19 0 010-11.27 58.94 58.94 0 010 11.27zM191.82 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM188.4 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM199.34 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM195.92 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM206.86 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM203.43 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM214.37 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27zM211 469.09a61.19 61.19 0 010-11.27 61.19 61.19 0 010 11.27z"
                ></path>
                <path
                    fill="#263238"
                    d="M218.18 457.65c0 .15-14.07.26-31.42.26s-31.42-.11-31.42-.26 14.06-.26 31.42-.26 31.42.12 31.42.26z"
                ></path>
                <path
                    d="M154.26 434.86a95.39 95.39 0 0028.9 2.1c9.6-.63 19.29-2 28.91-1.93zM154.83 423.55a95.65 95.65 0 0028.89 2.11c9.6-.64 19.3-2.05 28.92-1.94zM157.85 446.24a95.74 95.74 0 0028.9 2.11c9.6-.64 19.29-2.05 28.91-1.94zM155.4 457.59a96 96 0 0028.9 2.1c9.6-.64 19.29-2.05 28.91-1.94z"
                    opacity="0.3"
                ></path>
                <path
                    fill="#fff"
                    d="M154.39 419.65a18.73 18.73 0 010-6.22 18.73 18.73 0 010 6.22zM150.45 431.79a21.52 21.52 0 010-6.67 21.52 21.52 0 010 6.67zM156.06 443.47a16.92 16.92 0 010-5.91 16.92 16.92 0 010 5.91zM150.3 454.25a13.19 13.19 0 010-5.31 13.69 13.69 0 010 5.31zM173.21 424.36c0 .14-4.62.26-10.32.26s-10.32-.12-10.32-.26a100.39 100.39 0 0110.32-.26 100.39 100.39 0 0110.32.26zM179.88 413.43a101.73 101.73 0 01-10.39.26 101.73 101.73 0 01-10.39-.26c0-.14 4.65-.26 10.39-.26s10.39.12 10.39.26zM189.89 413.59a22.49 22.49 0 01-6.82 0 22.49 22.49 0 016.82 0z"
                ></path>
            </g>
        </svg>
    );
}

export default Solution2PageImage;